import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import Logo from '../../components/Logo';
import { useNavigate } from 'react-router-dom';

const ThankYouPageView = () => {
	const navigate = useNavigate();
	const defaultTheme = useTheme();

	return (
		<Box marginRight={3} marginLeft={3}>
			<Logo color={defaultTheme.palette.text.primary} />
			<Grid container spacing={3} height={'100vh'} alignItems={'center'}>
				<Grid item xs={1} />
				<Grid item xs={5}>
					<Stack spacing={'40px'}>
						<Stack spacing={3}>
							<Typography variant={'h4'}>Thank you for registering!</Typography>
							<Typography variant={'body1'}>You will receive email with detailed information.</Typography>
						</Stack>
						<Button size={'large'} variant={'contained'} sx={{ width: 200 }} onClick={() => navigate('/')}>
							Sign In
						</Button>
					</Stack>
				</Grid>
				<Grid item xs={1} />
				<Grid item xs={4}>
					<img src={'/illustrations/dark/heart.svg'} style={{ width: '100%' }} alt="Thank you" />
				</Grid>
			</Grid>
		</Box>
	);
};

export default ThankYouPageView;
