import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal } from '@mui/material';
import { useModalContext } from '../../context';
import InquiryIndividualPage from '../InquiryPageView/components/InquiryIndividualPage';
import CompanyList from '../../components/CompanyList';

const ModalsView = () => {
	const { inquiryModalOpen, companiesModalOpen, current, closeModal } = useModalContext();

	return (
		<>
			<Modal open={inquiryModalOpen} onClose={() => closeModal()} sx={{ maxWidth: '500px', ...modalStyles }}>
				<>
					<InquiryIndividualPage inquiryProps={current} />
				</>
			</Modal>
			<Dialog
				open={companiesModalOpen}
				onClose={closeModal}
				scroll={'paper'}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				maxWidth={'md'}
				fullWidth={true}
				PaperProps={{
					elevation: 1,
					sx: {
						height: 'calc(100% - 64px)',
					},
				}}
			>
				<DialogTitle id="scroll-dialog-title">Browse Companies</DialogTitle>
				<DialogContent dividers={true} sx={{ overflowY: 'scroll' }}>
					<CompanyList
						companies={current?.companies}
						favoriteCompanyIds={current?.favoriteCompanyIds}
						onCompanyClick={current?.onCompanyClick}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModal}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

const modalStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	margin: 'auto',
};

export default ModalsView;
