import * as Yup from 'yup';
import { airtableConstants } from '../../../../utils/airtableConstants';

export const initialValues = {
	action: '',
	marketVisibility: '',
	priceType: 'Share price',
	priceNumber: '',
	priceNumberType: airtableConstants.INQUIRY.NUMERICAL_QUANTITIES[1],
	blockSizeType: 'Size',
	blockSizeSizeMin: '',
	blockSizeSizeMinType: airtableConstants.INQUIRY.NUMERICAL_QUANTITIES[1],
	blockSizeSizeMax: '',
	blockSizeSizeMaxType: airtableConstants.INQUIRY.NUMERICAL_QUANTITIES[1],
	blockSizeSharesMin: '',
	blockSizeSharesMax: '',
	structure: '',
	securityType: '',
};

export const schema = Yup.object({
	action: Yup.string().required('Required'),
	marketVisibility: Yup.string().required('Required'),
	priceType: Yup.string().required('Required'),
	priceNumber: Yup.string().required('Required'),
	blockSizeType: Yup.string().required('Required'),

	blockSizeSizeMin: Yup.string().when('blockSizeType', {
		is: (securities) => securities === 'Size',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	blockSizeSizeMax: Yup.string().when('blockSizeType', {
		is: (securities) => securities === 'Size',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	blockSizeSharesMin: Yup.string().when('blockSizeType', {
		is: (securities) => securities === 'Shares',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	blockSizeSharesMax: Yup.string().when('blockSizeType', {
		is: (securities) => securities === 'Shares',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),

	structure: Yup.array().required('Required'),
	securityType: Yup.array().required('Required'),
});
