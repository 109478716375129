import { createTheme, responsiveFontSizes } from '@mui/material';
import { purple } from '@mui/material/colors';

const themeCommon = {
	components: {
		MuiSkeleton: {
			defaultProps: {
				animation: 'wave',
			},
			styleOverrides: {
				root: {
					borderRadius: '4px',
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					padding: '10px',
					[`@media (max-width:600px)`]: {
						padding: 0,
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '14px',
				},
			},
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					height: 'calc(100vh - 64px - 136px - 32px - 2px)',
				},
			},
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					[`@media (max-width:600px)`]: {
						paddingLeft: '24px',
						paddingRight: '24px',
					},
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					'@media (max-width: 600px)': {
						// flexDirection: 'column-reverse',
						// width: '100%',
						'& button': {
							width: '100%',
							height: 42,
						},
					},
				},
			},
		},
	},
};

export const darkTheme = responsiveFontSizes(
	createTheme({
		palette: {
			mode: 'dark',
			primary: {
				main: purple[200],
				light: purple[50],
				dark: purple[400],
			},
			secondary: {
				main: '#9DD893',
				light: '#C1E7BB',
				dark: '#51AA41',
			},
			greyButton: {
				main: '#EDEDED',
				light: '#FFFFFF',
				dark: '#E1E1E1',
			},
		},
		...themeCommon,
	})
);

export const lightTheme = responsiveFontSizes(
	createTheme({
		palette: {
			mode: 'light',
			primary: {
				main: purple[500],
				light: purple[300],
				dark: purple[700],
			},
		},
		...themeCommon,
	})
);
