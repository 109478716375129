import InquiryIndividualPage from './components/InquiryIndividualPage';
import { usePrivateAppContext, useAuthContext } from '../../context';
import { useParams } from 'react-router-dom';
import { useData } from '../../useData';
import { useMemo } from 'react';
import InquiryQuestionnaireSkeleton from '../../components/skeletons/InquiryQuestionnaireSkeleton';
import InquiryInstitutionalPage from './components/InquiryInstitutionalPage';
import { convertNumberToNumericalQuantity } from '../../utils';
import { airtableConstants } from '../../utils/airtableConstants';
import { initialShareData } from '../CompanyPageView/initialShareData';

const InquiryView = () => {
	const { companyId, inquiryId } = useParams();
	const { data, loading } = useData({ companyId });
	const { current } = usePrivateAppContext();
	const { inquiries, user } = useAuthContext();

	const foundInquiry = useMemo(
		() => inquiryId && inquiries.find((item) => item.id === inquiryId),
		[inquiryId, inquiries]
	);

	const questionnaire = useMemo(() => {
		if (foundInquiry) {
			const json = JSON.parse(foundInquiry?.fields.Questionnaire ?? '{}');

			if (json.priceType === 'Valuation') {
				json.priceNumber = convertNumberToNumericalQuantity(json.priceNumberType, json.priceNumber);
			}
			if (json.blockSizeSizeMin) {
				json.blockSizeSizeMin = convertNumberToNumericalQuantity(json.blockSizeSizeMinType, json.blockSizeSizeMin);
			}

			if (json.blockSizeSizeMax) {
				json.blockSizeSizeMax = convertNumberToNumericalQuantity(json.blockSizeSizeMaxType, json.blockSizeSizeMax);
			}

			return json;
		}

		if (current?.selectedInquiries && current?.selectedInquiries.length >= 1) {
			return {
				action: current?.selectedInquiries[0].fields.Action === 'buy' ? 'sell' : 'buy',
			};
		}

		return {};
	}, [foundInquiry]);

	if (loading && (!current || !foundInquiry)) {
		return <InquiryQuestionnaireSkeleton />;
	}

	if (user?.instie) {
		return (
			<InquiryInstitutionalPage
				selectedInquiries={current?.selectedInquiries ?? []}
				questionnaire={questionnaire}
				companyData={
					inquiryId
						? {
								companyId: foundInquiry?.fields['Company'][0],
								companyName: foundInquiry?.fields['Company Name'][0],
						  }
						: {
								companyId: current?.companyId ?? companyId,
								companyName: current?.companyName ?? data.overviewData?.name,
						  }
				}
				isReadOnly={Boolean(inquiryId) && foundInquiry?.fields.Status !== 'Onboarding'}
			/>
		);
	}

	return (
		<InquiryIndividualPage
			questionnaire={questionnaire}
			calculatorData={
				inquiryId
					? {
							action: foundInquiry?.fields.Action,
							blockSize: foundInquiry?.fields['Block Size'],
							sharePrice: foundInquiry?.fields.PPS,
							shareNumber: foundInquiry?.fields.Shares,
							sharesIssued: foundInquiry?.fields.sharesIssued ?? data.overviewData?.sharesIssued,
					  }
					: {
							action: current?.action ?? initialShareData.action,
							blockSize: current?.blockSize ?? initialShareData.blockSize,
							sharePrice: current?.sharePrice ?? initialShareData.sharePrice,
							shareNumber: current?.numberOfShares ?? initialShareData.blockSize / initialShareData.sharePrice,
							sharesIssued: current?.sharesIssued ?? data.overviewData?.sharesIssued,
					  }
			}
			companyData={
				inquiryId
					? {
							companyId: foundInquiry?.fields['Company'][0],
							companyName: foundInquiry?.fields['Company Name'][0],
					  }
					: {
							companyId: current?.companyId ?? companyId,
							companyName: current?.companyName ?? data.overviewData?.name,
					  }
			}
			isReadOnly={
				Boolean(inquiryId) &&
				Boolean(foundInquiry) &&
				airtableConstants.INQUIRY.STATUS_MAP[foundInquiry.fields.InternalStatus] !== 'Onboarding'
			}
		/>
	);
};

export default InquiryView;
