import { Avatar, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { dateFormatter } from '../../utils';
import { Circle } from '@mui/icons-material';
import FilePreview from '../FilePreview';

const ActivityItem = ({ fromUser = false, dateTime, message, action = <></>, files }) => {
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	return (
		<Stack direction={'row'} spacing={isMobile ? '12px' : '18px'}>
			<Avatar sx={{ bgcolor: fromUser ? 'primary.main' : 'secondary.main', width: 32, height: 32 }}>
				{fromUser ? 'Y' : 'S'}
			</Avatar>
			<Stack direction={'column'} spacing={isMobile ? '2px' : 1}>
				<Stack direction={'row'} spacing={1} alignItems={'center'}>
					<Typography variant={'body1'}>{fromUser ? 'You' : 'SW'}</Typography>
					<Circle sx={{ color: defaultTheme.palette.grey['500'], fontSize: 4 }} />
					<Typography variant={'body2'} color="text.secondary">
						{dateFormatter(new Date(dateTime))}
					</Typography>
				</Stack>
				<Stack direction={'column'} spacing={'12px'} width={'fit-content'}>
					{Boolean(message) && (
						<Typography variant={'body2'} style={{ whiteSpace: 'pre-line' }}>
							{message}
						</Typography>
					)}
					{Boolean(files) && Boolean(files.length) && (
						<a href={files[0].url} target={'_blank'}>
							<FilePreview fileName={files[0].filename} url={files[0].url} />
						</a>
					)}
					{action}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default ActivityItem;
