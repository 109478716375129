import React, { useEffect, useMemo, useState } from 'react';
import { DropdownLink } from '../../styles';
import {
	Box,
	Checkbox,
	Divider,
	FormHelperText,
	InputAdornment,
	ListItem,
	Menu,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { AddOutlined, KeyboardArrowDownOutlined, Search } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { usePrivateAppContext } from '../../../../context';

const CustomizedCompaniesDropdown = (props) => {
	const { fieldName, isMultiple, error } = props;
	const { values, setFieldValue } = useFormikContext();
	const showedNumber = 5;

	const { companies } = usePrivateAppContext();
	const [dropdownCompanies, setDropdownCompanies] = useState([]);
	const [filteredDropdownCompanies, setFilteredDropdownCompanies] = useState([]);
	const selectedCompanies = useMemo(() => {
		if (dropdownCompanies && dropdownCompanies.length > 0) {
			return values[fieldName]?.map((id) => dropdownCompanies.find((obj) => obj['Record Id'] === id)) ?? [];
		} else {
			return [];
		}
	}, [values, dropdownCompanies]);

	const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
	const dropdownMenuItems = useMemo(() => {
		const selectedItems = filteredDropdownCompanies.filter((company) =>
			selectedCompanies.some((selected) => selected?.['Record Id'] === company?.['Record Id'])
		);

		const nonSelected = filteredDropdownCompanies.filter(
			(company) => !selectedCompanies.some((selected) => selected?.['Record Id'] === company?.['Record Id'])
		);

		return selectedItems.concat(nonSelected);
	}, [selectedCompanies, filteredDropdownCompanies]);

	useEffect(() => {
		setDropdownCompanies(companies);
		setFilteredDropdownCompanies(companies);
	}, [companies]);

	const onDropdownClick = (e) => {
		setDropdownAnchorEl(e.currentTarget);
	};

	const renderDropdownContent = () => {
		const value =
			selectedCompanies && selectedCompanies.length > 0
				? selectedCompanies
						.filter((item) => item?.['Legal Name'])
						.map((item) => item?.['Legal Name'] || '')
						.join(', ')
				: false;

		if (value) {
			return (
				<Stack alignItems={'center'} direction={'row'} spacing={'2px'}>
					{value}
					<KeyboardArrowDownOutlined sx={{ fontSize: 16 }} />
				</Stack>
			);
		} else {
			return (
				<Stack direction={'row'} spacing={'4px'}>
					<AddOutlined sx={{ fontSize: 20 }} color={Boolean(error) ? 'error' : 'primary'} />
					{Boolean(error) && (
						<Box>
							<FormHelperText error={Boolean(error)}>{error}</FormHelperText>
						</Box>
					)}
				</Stack>
			);
		}
	};

	const onClose = () => {
		setDropdownAnchorEl(null);
		setFilteredDropdownCompanies(dropdownCompanies);
	};

	return (
		<>
			<DropdownLink onClick={(e) => onDropdownClick(e)}>{renderDropdownContent()}</DropdownLink>
			<Menu
				anchorEl={dropdownAnchorEl}
				MenuListProps={{
					dense: isMultiple,
				}}
				open={Boolean(dropdownAnchorEl)}
				onClose={onClose}
			>
				<Stack spacing={1}>
					<ListItem>
						<TextField
							fullWidth
							variant={'outlined'}
							onKeyDown={(e) => e.stopPropagation()}
							onChange={(event) => {
								setFilteredDropdownCompanies(
									dropdownCompanies.filter((item) =>
										item['Legal Name'].toLowerCase().includes(event.target.value.trim().toLowerCase())
									)
								);
							}}
							label={'Name'}
							size={'small'}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Search color="disabled" fontSize="small" />
									</InputAdornment>
								),
							}}
						/>
					</ListItem>
					<Divider variant={'fullWidth'} />
					<Stack>
						{dropdownMenuItems.slice(0, showedNumber).map((item) => {
							const selected = Boolean(values[fieldName]?.find((val) => val === item['Record Id']));
							return (
								<MenuItem
									key={item['Record Id']}
									onClick={() => {
										if (isMultiple) {
											const oldValue = values[fieldName] ?? [];
											const newValue = oldValue.find((val) => val === item['Record Id'])
												? oldValue.filter((val) => val !== item['Record Id'])
												: [...oldValue, item['Record Id']];
											setFieldValue([fieldName], newValue);
										} else {
											setFieldValue([fieldName], [item['Record Id']]);
											onClose();
										}
									}}
									selected={selected}
								>
									{isMultiple && (
										<Checkbox checked={selected} size={'small'} sx={{ marginLeft: '-12px', marginRight: '2px' }} />
									)}
									{item['Legal Name']}
								</MenuItem>
							);
						})}
					</Stack>
					{filteredDropdownCompanies.length > showedNumber && (
						<ListItem>
							<Typography variant={'caption'} color={'text.secondary'}>
								To get more companies, start searching by name
							</Typography>
						</ListItem>
					)}
				</Stack>
			</Menu>
		</>
	);
};

export default CustomizedCompaniesDropdown;
