import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { apiClient, endpoints, getDefaultErrorMessage } from '../../../../utils';
import { toast } from 'react-toastify';
import { strings } from '../../../../utils/strings';

const ImpersonateDialog = ({ id, handleClose, open }) => {
	const handleConfirm = async (id) => {
		try {
			await apiClient.get(endpoints.impersonateUsers.replace('{userId}', id));
			toast.success(strings.successMessages.user.impersonated);
		} catch (err) {
			getDefaultErrorMessage(err);
		} finally {
			handleClose();
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Confirm User Impersonation</DialogTitle>
			<DialogContent>
				<DialogContentText>
					By confirming this action, you allow user to access the application with their specific permissions and
					privileges. Please use this feature responsibly and only for legitimate support or administrative purposes.
					Remember that any actions taken while impersonating the user will be logged and attributed to their account.
					Confirm if you wish to proceed with user impersonation.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color={'inherit'}>
					Cancel
				</Button>
				<Button onClick={() => handleConfirm(id)} autoFocus color={'primary'}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImpersonateDialog;
