import { useEffect, useState } from 'react';
import {
	Box,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { initialValues, schema } from './inquiryForm.constants';
import { toast } from 'react-toastify';
import { apiClient, endpoints } from '../../../../utils';
import PageTitle from '../../../../components/PageTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import ThankYouBlock from '../../../../components/ThankYouBlock';
import InquiryPreview from '../../../../components/InquiryPreview';
import InputField from '../../../../components/FormComponents/InputField';
import { HelpOutline } from '@mui/icons-material';
import * as d3 from 'd3-latest';
import { useAuthContext } from '../../../../context';
import { useNavigate, useParams } from 'react-router-dom';
import ExistingInquiryAlert from '../ExistingInquiryAlert';
import { strings } from '../../../../utils/strings';
import Calculator from '../../../CompanyPageView/TradingData/Calculator';

const InquiryIndividualPage = (props) => {
	const { questionnaire, calculatorData, companyData, isReadOnly = false } = props;

	const { inquiryId } = useParams();
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { inquiries, setInquiries, user } = useAuthContext();

	const [isButtonLoading, setButtonLoading] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	const [calculatorState, setCalculatorState] = useState(calculatorData);
	const [sameInquiries, setSameInquiries] = useState([]);

	useEffect(() => {
		if (window.location.pathname.includes('success') && !isSuccess) {
			navigate(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
		}
	}, []);

	useEffect(() => {
		setSameInquiries(inquiries.filter((item) => item.fields.Company[0] === companyData.companyId));
	}, [inquiries, companyData, inquiryId]);

	const handleSubmit = async (values) => {
		setButtonLoading(true);
		const questionnaireParams = { ...values };
		const questionnairePayload = JSON.stringify(questionnaireParams);

		const payload = {
			action: calculatorState.action,
			blockSize: Math.round(calculatorState.blockSize * 1e2) / 1e2,
			companyName: companyData.companyName,
			companyId: companyData.companyId,
			sharePrice: Math.round(calculatorState.sharePrice * 1e2) / 1e2,
			numberOfShares: calculatorState.shareNumber,
			sharesIssued: calculatorState.sharesIssued,
			questionnaire: questionnairePayload,

			referrer: window.location.origin,
			message: `to _${calculatorState.action}_ ${d3.format(',.0f')(calculatorState.shareNumber)} shares of \`${
				companyData.companyName
			}\`, block size is \`$${d3.format(',.0f')(calculatorState.blockSize)}\` at share price of \`$${d3.format(',.2f')(
				calculatorState.sharePrice
			)}\``,
			inquiryId: inquiryId,
			shadow: user.shadow ? true : false,
		};

		try {
			let res;

			if (inquiryId) {
				res = await apiClient.post(endpoints.updateInquiry, payload);
			} else {
				res = await apiClient.post(endpoints.logInterest, payload);
			}

			if (res.status === 200) {
				setSuccess(true);

				if (!inquiryId) {
					setInquiries([res.data, ...inquiries]);
				}

				if (!window.location.pathname.includes('success')) {
					navigate(window.location.pathname + '/success');
				}
			}
		} catch (err) {
			toast.error(strings.errorMessages.form.submit);
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<>
			<PageTitle
				isOnlyBreadcrumbs={true}
				breadcrumbs={
					isReadOnly
						? [
								{
									title: 'Home',
									link: '/home',
								},
								{
									title: 'Inquiry',
								},
						  ]
						: [
								{
									title: 'Companies',
									link: '/companies',
								},
								{
									title: companyData.companyName,
									link: `/company/${companyData.companyId}`,
								},
								{
									title: `${inquiryId ? 'Update' : 'Create'} Inquiry`,
								},
						  ]
				}
			/>
			<Formik
				enableReinitialize={true}
				initialValues={{
					...initialValues,
					securities: questionnaire?.securities ?? '',
					securitiesOptions: questionnaire?.securitiesOptions ?? '',
					securitiesMixSpecify: questionnaire?.securitiesMixSpecify ?? '',
					// securitiesExercisedOption: questionnaire?.securitiesExercisedOption ?? '',
					securitiesUnexercisedOption: questionnaire?.securitiesUnexercisedOption ?? '',
					transferability: questionnaire?.transferability ?? '',
					transactionTimeline: questionnaire?.transactionTimeline ?? '',
				}}
				onSubmit={handleSubmit}
				validationSchema={schema}
			>
				{({ setFieldValue, isSubmitting, values, errors, touched }) => {
					const inquiryPreview = (
						<>
							{isSuccess || isReadOnly ? (
								<InquiryPreview
									companyName={companyData.companyName}
									pps={calculatorState.sharePrice}
									actionValue={calculatorState.action}
									shareNum={calculatorState.shareNumber}
								/>
							) : (
								<Calculator
									title={companyData?.companyName}
									action={calculatorState.action}
									setAction={(value) => setCalculatorState((prevState) => ({ ...prevState, action: value }))}
									blockSize={calculatorState.blockSize}
									setBlockSize={(value) => setCalculatorState((prevState) => ({ ...prevState, blockSize: value }))}
									sharePrice={calculatorState.sharePrice}
									setSharePrice={(value) => setCalculatorState((prevState) => ({ ...prevState, sharePrice: value }))}
									shareNumber={calculatorState.shareNumber}
									setShareNumber={(value) =>
										setCalculatorState((prevState) => ({
											...prevState,
											shareNumber: value,
										}))
									}
									sharesIssued={calculatorState.sharesIssued}
								/>
							)}
						</>
					);

					return (
						<Grid container spacing={3}>
							{!isMobile && (
								<Grid item xs={5}>
									{inquiryPreview}
								</Grid>
							)}
							{!isMobile && (
								<Grid item xs={1}>
									<Divider orientation={'vertical'} sx={{ width: 'calc(100% / 2)' }} />
								</Grid>
							)}
							{isSuccess ? (
								<Grid item xs={isMobile ? 12 : 6} marginTop={'60px'} marginBottom={'60px'}>
									<ThankYouBlock
										title={'Thank you for your inquiry!'}
										description={'You will receive email with detailed information.'}
										buttonText={'home'}
										buttonLink={'/'}
										dataCy={'inquiry-success-home'}
									/>
								</Grid>
							) : (
								<Grid item xs={isMobile ? 12 : 6}>
									<ExistingInquiryAlert
										inquiries={sameInquiries}
										inquiryId={inquiryId}
										companyName={companyData?.companyName}
										onClose={() => setSameInquiries([])}
									/>
									<Stack spacing={isMobile ? 3 : 6}>
										{!isReadOnly && (
											<Stack spacing={'6px'}>
												<Typography variant="h5">{`${inquiryId ? 'Update' : 'Create'} Inquiry`}</Typography>
												<Typography variant="body2" color={'text.secondary'}>
													Let us know a few more details and we will take it from there.
												</Typography>
											</Stack>
										)}
										{isMobile && inquiryPreview}
										<Form>
											<Stack spacing={isMobile ? 3 : 6}>
												<Box>
													<Grid container spacing={[3, '20px']}>
														<Grid item xs={12}>
															<FormControl
																component="fieldset"
																error={touched.securities && Boolean(errors.securities)}
															>
																<FormLabel component="legend">Type of securities</FormLabel>
																<RadioGroup
																	required
																	name={'securities'}
																	value={values.securities}
																	onChange={(event) => {
																		setFieldValue('securities', event.currentTarget.value);

																		//clear other fields
																		setFieldValue('securitiesOptions', '');
																		setFieldValue('securitiesMixSpecify', '');
																		setFieldValue('securitiesExercisedOption', '');
																		setFieldValue('securitiesUnexercisedOption', '');
																	}}
																>
																	{['Common', 'Preferred', 'Options', 'I don’t know', 'Other'].map((item) => (
																		<FormControlLabel
																			key={item}
																			value={item}
																			label={item}
																			control={<Radio disabled={isSubmitting || isReadOnly} />}
																			disabled={isSubmitting || isReadOnly}
																		/>
																	))}
																	<FormControlLabel
																		value="RSU's"
																		label={
																			<Stack spacing={1} direction={'row'} alignItems={'center'}>
																				<Typography>RSU's</Typography>
																				<Tooltip
																					placement="top"
																					title="RSU’s (Restricted Stock Units) are not traded by our firm in the secondary market."
																				>
																					<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
																				</Tooltip>
																			</Stack>
																		}
																		control={<Radio disabled={true} />}
																		disabled={true}
																	/>
																</RadioGroup>
																{touched.securities && errors.securities && (
																	<FormHelperText>{errors.securities}</FormHelperText>
																)}
															</FormControl>
														</Grid>
														{values.securities === 'Options' && (
															<Grid item xs={12}>
																<FormControl
																	component="fieldset"
																	error={touched.securitiesOptions && Boolean(errors.securitiesOptions)}
																>
																	<FormLabel component="legend">Specify type of options</FormLabel>
																	<RadioGroup
																		required
																		name={'securitiesOptions'}
																		value={values.securitiesOptions}
																		onChange={(event) => {
																			setFieldValue('securitiesOptions', event.currentTarget.value);
																		}}
																	>
																		{['Exercised options', 'Unexercised option', 'A mix', 'I don’t know'].map(
																			(item) => (
																				<FormControlLabel
																					key={item}
																					value={item}
																					label={item}
																					control={<Radio disabled={isSubmitting || isReadOnly} />}
																					disabled={isSubmitting || isReadOnly}
																				/>
																			)
																		)}
																	</RadioGroup>
																	{touched.securitiesOptions && errors.securitiesOptions && (
																		<FormHelperText>{errors.securitiesOptions}</FormHelperText>
																	)}
																</FormControl>
															</Grid>
														)}
														{values.securities === 'Options' && values.securitiesOptions === 'A mix' && (
															<Grid item xs={12}>
																<InputField
																	label="Specify types of securities"
																	multiline
																	fullWidth
																	rows={4}
																	name="securitiesMixSpecify"
																	disabled={isSubmitting || isReadOnly}
																	required
																	showAsterisk={false}
																/>
															</Grid>
														)}
														{values.securities === 'Options' && values.securitiesOptions === 'Unexercised option' && (
															<Grid item xs={12}>
																<FormControl
																	component="fieldset"
																	error={
																		touched.securitiesUnexercisedOption && Boolean(errors.securitiesUnexercisedOption)
																	}
																>
																	<FormLabel>
																		Would you be able to exercise and cover the cost exercising the options?
																	</FormLabel>
																	<RadioGroup
																		required
																		name={'securitiesUnexercisedOption'}
																		value={values.securitiesUnexercisedOption}
																		onChange={(event) => {
																			setFieldValue('securitiesUnexercisedOption', event.currentTarget.value);
																		}}
																	>
																		{['Yes, in the event of transaction exercise on my own', 'No', 'I don’t know'].map(
																			(item) => (
																				<FormControlLabel
																					key={item}
																					value={item}
																					label={item}
																					control={<Radio disabled={isSubmitting || isReadOnly} />}
																					disabled={isSubmitting || isReadOnly}
																				/>
																			)
																		)}
																	</RadioGroup>
																	{touched.securitiesUnexercisedOption && errors.securitiesUnexercisedOption && (
																		<FormHelperText>{errors.securitiesUnexercisedOption}</FormHelperText>
																	)}
																</FormControl>
															</Grid>
														)}

														<Grid item xs={12}>
															<FormControl
																component="fieldset"
																error={touched.transferability && Boolean(errors.transferability)}
															>
																<FormLabel>Transferability</FormLabel>
																<RadioGroup
																	required
																	name={'transferability'}
																	value={values.transferability}
																	onChange={(event) => {
																		setFieldValue('transferability', event.currentTarget.value);
																	}}
																>
																	<FormControlLabel
																		value="Direct transfer"
																		label="Direct transfer"
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																	<FormControlLabel
																		value="Structured (forward contract)"
																		label="Structured (forward contract)"
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																	<FormControlLabel
																		value="I don’t know / I have a mix"
																		label="I don’t know / I have a mix"
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																</RadioGroup>
																{touched.transferability && errors.transferability && (
																	<FormHelperText>{errors.transferability}</FormHelperText>
																)}
															</FormControl>
														</Grid>

														<Grid item xs={12}>
															<FormControl
																component="fieldset"
																error={touched.transactionTimeline && Boolean(errors.transactionTimeline)}
															>
																<FormLabel>What is your timeline for the transaction?</FormLabel>
																<RadioGroup
																	required
																	name={'transactionTimeline'}
																	value={values.transactionTimeline}
																	onChange={(event) => {
																		setFieldValue('transactionTimeline', event.currentTarget.value);
																	}}
																>
																	<FormControlLabel
																		value="Immediate"
																		label="Immediate"
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																	<FormControlLabel
																		value="60 days"
																		label="60 days"
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																	<FormControlLabel
																		value="90 days"
																		label="90 days"
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																	<FormControlLabel
																		value="No timeline"
																		label="I have no timeline"
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																</RadioGroup>
																{touched.transactionTimeline && errors.transactionTimeline && (
																	<FormHelperText>{errors.transactionTimeline}</FormHelperText>
																)}
															</FormControl>
														</Grid>
													</Grid>
												</Box>

												{!isReadOnly && (
													<LoadingButton
														variant="contained"
														type="submit"
														sx={{ width: isMobile ? '100%' : '50%' }}
														size={'large'}
														loading={isButtonLoading}
														endIcon={<></>}
														loadingPosition={'end'}
														data-cy={'inquiry-submit-or-edit'}
													>
														{`${inquiryId ? 'Update' : 'Send'} Inquiry`}
													</LoadingButton>
												)}
											</Stack>
										</Form>
									</Stack>
								</Grid>
							)}
						</Grid>
					);
				}}
			</Formik>
		</>
	);
};

export default InquiryIndividualPage;
