import * as Yup from 'yup';

export const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	companyName: '',
	follows: [],
	reasons: [],
	userType: '',
	residency: 'United States of America',
	firmHeadquarters: 'United States of America',
	contactMethod: '',
	accountType: '',
	typicalInvestmentRange: '',
	nda: false,
	netWorth: false,
	certifications: false,
	certificationSpecification: '',
	totalAum: '',
	newsletter: true,
	Report: true,
	termsOfService: '',
	fullName: '',
	phoneNumber: '',
	date: new Date().toLocaleDateString(),
};

const PHONE_REGEX = /^[0-9+()]+$/;

export const schema = Yup.object({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	companyName: Yup.string().optional(),
	userType: Yup.string().required('Required'),
	residency: Yup.string().required('Required'),
	firmHeadquarters: Yup.string().when('userType', {
		is: (user) => user !== 'An Individual Shareholder',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	contactMethod: Yup.string().required('Required'),
	termsOfService: Yup.boolean().required('Required'),
	fullName: Yup.string().when('userType', {
		is: (user) => user === 'An Individual Shareholder',
		then: (schema) => schema.required('Please enter your Full Name as provided on signup to accept the NDA'),
		otherwise: (schema) => schema.optional(),
	}),
	date: Yup.string().when('userType', {
		is: (user) => user === 'An Individual Shareholder',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	phoneNumber: Yup.string().matches(PHONE_REGEX, 'Phone number must contain digits, + or () '),
	accountType: Yup.string().when('userType', {
		is: (user) => user === 'An Accredited Investor' || user === 'An Institutional Firm',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),

	/*	// Investor & Firm shared
	typicalInvestmentRange: Yup.string().when('userType', {
		is: (user) => user === 'An Accredited Investor' || user === 'An Institutional Firm',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	nda: Yup.boolean().when('userType', {
		is: (user) => user === 'An Accredited Investor' || user === 'An Institutional Firm',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),

	//	Accredited Investor
	netWorth: Yup.boolean().when('userType', {
		is: (user) => user === 'An Accredited Investor',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	certifications: Yup.boolean().when('userType', {
		is: (user) => user === 'An Accredited Investor',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	certificationSpecification: Yup.string().when('certifications', {
		is: (cert) => cert === true,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),

	//	Institutional Firm
	totalAum: Yup.string().when('userType', {
		is: (user) => user === 'An Institutional Firm',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	accountTypeSpecification: Yup.string().when('accountType', {
		is: (accountType) => accountType === 'Other',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),*/
});
