import styled from 'styled-components';
import { Link } from '@mui/material';

export const DropdownLink = styled(Link).attrs((props) => ({
	variant: 'body2',
	underline: 'none',
}))`
	display: inline-flex;
	min-height: 31px;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
`;
