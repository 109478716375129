import { Grid, useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Logo from '../Logo';
import Carousel from 'react-material-ui-carousel';
import { CenterWrapper, LeftSide, MainContainer } from './loginPage.styles';
import LoginForm from '../LoginForm';
import { useLocation } from 'react-router-dom';
import RegisterForm from '../RegisterForm';
import { lightTheme } from '../../styles/theme';
import { getSlides } from '../../views/LandingPageView/slides';

const LandingPage = () => {
	const defaultTheme = useTheme();
	const location = useLocation();
	const isSignup = location.pathname.toLowerCase().includes('signup');
	const isSmall = window.innerHeight < 800;

	const transitionGridStyle = {
		transition: defaultTheme.transitions.create('all', {
			easing: defaultTheme.transitions.easing.sharp,
			duration: defaultTheme.transitions.duration.leavingScreen,
		}),
	};

	return (
		<MainContainer container>
			<LeftSide
				item
				xs={isSignup ? 4 : 7}
				style={transitionGridStyle}
				sx={{
					paddingLeft: isSignup ? '0px' : '24px',
					paddingRight: isSignup ? '0px' : '24px',
				}}
			>
				<Logo color={defaultTheme.palette.text.primary} />
				<Carousel
					animation={'slide'}
					navButtonsAlwaysVisible={!isSignup}
					navButtonsAlwaysInvisible={isSignup}
					sx={{ flex: 1 }}
					indicatorContainerProps={{ style: { position: 'absolute', bottom: 0 } }}
					navButtonsProps={{
						style: {
							backgroundColor: defaultTheme.palette.grey['800'],
						},
					}}
					indicatorIconButtonProps={{ style: { color: defaultTheme.palette.grey['800'] } }}
					activeIndicatorIconButtonProps={{ style: { color: defaultTheme.palette.grey['400'] } }}
					height={'100%'}
				>
					{getSlides(isSignup, isSmall).map((slide, index) => (
						<CenterWrapper container key={index}>
							<Grid item xs={8}>
								{slide}
							</Grid>
						</CenterWrapper>
					))}
				</Carousel>
			</LeftSide>
			<ThemeProvider theme={lightTheme}>
				<Grid
					item
					xs={isSignup ? 8 : 5}
					style={transitionGridStyle}
					sx={{ backgroundColor: 'background.default', height: '100%', overflowY: 'auto', colorScheme: 'light' }}
				>
					{isSignup ? (
						<RegisterForm />
					) : (
						<CenterWrapper container>
							<Grid item xs={8} sm={7}>
								<LoginForm />
							</Grid>
						</CenterWrapper>
					)}
				</Grid>
			</ThemeProvider>
		</MainContainer>
	);
};

export default LandingPage;
