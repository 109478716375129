import { initialValues, schema } from '../../userDrawer.constants';
import { Form, Formik } from 'formik';
import {
	Box,
	Button,
	Checkbox,
	Grid,
	Link,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { apiClient, dateFormatter, endpoints, getDefaultErrorMessage, onlyDateFormatter } from '../../../../utils';
import { StyledInput, StyledTextArea, TableBox, ValueTypography } from '../../styles';
import { DoneAll } from '@mui/icons-material';
import { Label, valueColumns } from '../../../../styles/drawerComponents';
import CustomizedDropdown from '../../../CustomizedDropdown';
import { airtableConstants } from '../../../../utils/airtableConstants';
import { Link as RouterLink, useParams } from 'react-router-dom';
import * as d3 from 'd3-latest';
import React, { useEffect, useState } from 'react';
import { TabPanel } from '@mui/lab';
import { toast } from 'react-toastify';
import { strings } from '../../../../utils/strings';

const InfoTab = ({ user, setDraft }) => {
	const { id: userId } = useParams();
	const [inquiries, setInquiries] = useState([]);
	const requiredFields = Object.keys(schema.fields);

	useEffect(() => {
		if (user) {
			getInquiries(user);
		}
	}, [user]);

	const getInquiries = async (user) => {
		if (user.fields.Inquiries && user.fields.Inquiries.length > 0) {
			const res = await apiClient.post(endpoints.getInquiries, {
				userIds: [userId],
			});
			setInquiries(res.data);
		}
	};

	const handleSubmit = async (values) => {
		if (!userId) return toast.error(strings.errorMessages.user.load);
		try {
			await apiClient.post(endpoints.updateUserFields, {
				id: userId,
				updatedFields: values,
			});
			toast.success(strings.successMessages.user.updated);
			setDraft(true);
		} catch (err) {
			getDefaultErrorMessage(err);
		}
	};

	return (
		<TabPanel value="1" sx={{ padding: 0, height: '100%', overflow: 'auto' }}>
			{user && (
				<Formik
					enableReinitialize={true}
					initialValues={{ ...initialValues, ...(user ? user.fields : {}) }}
					validationSchema={schema}
					validateOnBlur={true}
					onSubmit={handleSubmit}
				>
					{({ setFieldValue, dirty, resetForm, errors, touched, values }) => {
						const getErrorMessage = (fieldName) => {
							if (touched && errors && Boolean(touched[fieldName] && errors[fieldName])) {
								return errors[fieldName];
							}

							return undefined;
						};
						return (
							<Form autoComplete="off" style={{ height: '100%' }}>
								<Stack height={'100%'}>
									<Box overflow={'auto'} p={3} flex={1}>
										<Grid container spacing={[3, '4px']}>
											{/* if the Shadow field is set and true, display a Label  */}
											{user.fields.Shadow && (
												<>
													<Label>Shadow</Label>
													<Grid item xs={valueColumns}>
														<ValueTypography>{user.fields.Shadow ? 'Yes' : 'No'}</ValueTypography>
													</Grid>
												</>
											)}

											<Label>First name</Label>
											<Grid item xs={valueColumns}>
												<StyledInput
													fullWidth
													name="First Name"
													onChange={(e) => {
														setFieldValue('First Name', e.currentTarget.value);
													}}
												/>
											</Grid>

											<Label>Last name</Label>
											<Grid item xs={valueColumns}>
												<StyledInput
													fullWidth
													name="Last Name"
													onChange={(e) => {
														setFieldValue('Last Name', e.currentTarget.value);
													}}
												/>
											</Grid>

											<Label>Type</Label>
											<Grid item xs={valueColumns}>
												<CustomizedDropdown fieldName={'Type'} options={airtableConstants.USER.TYPE} />
											</Grid>

											<Label>Status</Label>
											<Grid item xs={valueColumns}>
												<CustomizedDropdown fieldName={'Status'} options={airtableConstants.USER.STATUS} />
												{/* <ValueTypography>{user.fields['Status']}</ValueTypography> */}
											</Grid>

											<Label>{'Created at'}</Label>
											<Grid item xs={valueColumns}>
												<ValueTypography>{dateFormatter(new Date(user.fields['Created At']))}</ValueTypography>
											</Grid>

											<Label required={requiredFields.includes('UserStage')}>UserStage</Label>
											<Grid item xs={valueColumns}>
												<CustomizedDropdown
													fieldName={'UserStage'}
													options={airtableConstants.USER.USER_STAGE}
													error={getErrorMessage('UserStage')}
												/>
											</Grid>

											{/* add Triad dropdown multiselect*/}
											<Label>Triad</Label>
											<Grid item xs={valueColumns}>
												<CustomizedDropdown
													fieldName={'Triad'}
													isMultiple={true}
													options={airtableConstants.USER.TRIAD}
												/>
											</Grid>

											{/* add VIP checkbox  */}
											<Label>VIP</Label>
											<Grid item xs={valueColumns}>
												<Checkbox
													checked={values['VIP']}
													onChange={(e) => {
														setFieldValue('VIP', e.currentTarget.checked);
													}}
													inputProps={{ 'aria-label': 'controlled' }}
												/>
											</Grid>

											{/* add Structured Deals checkbox */}
											{values.Type !== 'An Individual Shareholder' && (
												<>
													<Label>Structured Deals</Label>
													<Grid item xs={valueColumns}>
														<Checkbox
															checked={values['Structured Deals']}
															onChange={(e) => {
																setFieldValue('Structured Deals', e.currentTarget.checked);
															}}
															inputProps={{ 'aria-label': 'controlled' }}
														/>
													</Grid>
												</>
											)}

											<Label>Company</Label>
											<Grid item xs={valueColumns}>
												<StyledInput
													fullWidth
													name="Company"
													onChange={(e) => {
														setFieldValue('Company', e.currentTarget.value);
													}}
												/>
											</Grid>

											<Label>Notes</Label>
											<Grid item xs={valueColumns}>
												<StyledTextArea name={'Notes'} rows={4} />
											</Grid>

											<Label>Follows</Label>
											<Grid item xs={valueColumns}>
												<CustomizedDropdown fieldName={'Follows'} isCompanies={true} isMultiple={true} />
											</Grid>

											<Label>Portfolio</Label>
											<Grid item xs={valueColumns}>
												<CustomizedDropdown fieldName={'Portfolio'} isCompanies={true} isMultiple={true} />
											</Grid>

											{values.Type !== 'An Individual Shareholder' && (
												<>
													<Label>BoD</Label>
													<Grid item xs={valueColumns}>
														<StyledInput
															fullWidth
															name="BoD"
															onChange={(e) => {
																setFieldValue('BoD', e.currentTarget.value);
															}}
														/>
													</Grid>
												</>
											)}

											{values.Type !== 'An Individual Shareholder' && (
												<>
													<Label>Investment Type</Label>
													<Grid item xs={valueColumns}>
														<CustomizedDropdown
															fieldName={'Investor Type'}
															options={airtableConstants.USER.INVESTOR_TYPE}
														/>
													</Grid>
												</>
											)}

											{values.Type !== 'An Individual Shareholder' && (
												<>
													<Label required={requiredFields.includes('Tier')}>Tier</Label>
													<Grid item xs={valueColumns}>
														<CustomizedDropdown
															fieldName={'Tier'}
															options={airtableConstants.USER.TIER}
															error={getErrorMessage('Tier')}
														/>
													</Grid>
												</>
											)}

											{values.Type !== 'An Individual Shareholder' && (
												<>
													<Label required={requiredFields.includes('Compliance Required')}>Compliance Required</Label>
													<Grid item xs={valueColumns}>
														<CustomizedDropdown
															fieldName={'Compliance Required'}
															options={airtableConstants.USER.COMPLIANCE}
															error={getErrorMessage('Compliance Required')}
														/>
													</Grid>
													<Label>Compliance Approved</Label>
													{/* Add a checkbox that is tied to the "Compliance Approved" field (boolean value) */}
													<Grid item xs={valueColumns}>
														<Checkbox
															checked={values['Compliance Approved']}
															onChange={(e) => {
																setFieldValue('Compliance Approved', e.currentTarget.checked);
															}}
															inputProps={{ 'aria-label': 'controlled' }}
														/>
													</Grid>
												</>
											)}
											{values.Type !== 'An Individual Shareholder' && (
												<>
													<Label>Accreditation</Label>
													<Grid item xs={valueColumns}>
														<ValueTypography>{user.fields['Accreditation'] ? 'Done' : 'Pending'}</ValueTypography>
													</Grid>
												</>
											)}
											{values.Type !== 'An Individual Shareholder' && (
												<>
													<Label>Team Members</Label>
													<Grid item xs={valueColumns}>
														{user.fields['Team Members']?.length ? (
															<div>
																{user.fields['Team Members'].map((member, index) => (
																	<div key={index} style={{ fontSize: '0.9em' }}>
																		{member.fields['Full Name']}, {member.fields['Role']}
																	</div>
																))}
															</div>
														) : (
															<ValueTypography>None</ValueTypography>
														)}
													</Grid>
												</>
											)}

											{values.Type === 'An Individual Shareholder' && (
												<>
													<Label>Inquiries</Label>
													<Grid item xs={valueColumns}>
														{user.fields.Inquiries && user.fields.Inquiries.length && inquiries.length > 0 ? (
															<TableBox>
																<Table size="small" sx={{ tableLayout: 'fixed' }}>
																	<TableBody>
																		{inquiries.map((item) => (
																			<TableRow
																				key={item.id}
																				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																			>
																				<TableCell padding={'none'}>
																					<Link
																						underline={'always'}
																						target={'_blank'}
																						variant={'body2'}
																						component={RouterLink}
																						to={'/admin/inquiries/' + item.id}
																					>
																						<Typography variant={'body2'}>{item.fields['Company Name'][0]}</Typography>
																					</Link>
																				</TableCell>
																				<TableCell>{onlyDateFormatter(new Date(item.fields['Created At']))}</TableCell>
																				<TableCell padding={'none'}>
																					{item.fields['Block Size']
																						? d3.format('$,.2f')(item.fields['Block Size'])
																						: ''}
																				</TableCell>
																			</TableRow>
																		))}
																	</TableBody>
																</Table>
															</TableBox>
														) : (
															'-'
														)}
													</Grid>
												</>
											)}
										</Grid>
									</Box>

									<Stack
										sx={{ borderTop: 1, borderColor: 'divider' }}
										justifyContent={'flex-end'}
										p={2}
										direction={'row'}
										spacing={2}
									>
										<Button
											size={'small'}
											variant={'contained'}
											startIcon={<DoneAll />}
											type={'submit'}
											disabled={!dirty}
										>
											Save changes
										</Button>
									</Stack>
								</Stack>
							</Form>
						);
					}}
				</Formik>
			)}
		</TabPanel>
	);
};

export default InfoTab;
