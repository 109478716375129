import { Box, Checkbox, FormHelperText, Menu, MenuItem, Stack } from '@mui/material';
import { AddOutlined, KeyboardArrowDownOutlined } from '@mui/icons-material';
import React, { useMemo, useState } from 'react';
import { DropdownLink } from './styles';
import CustomizedCompaniesDropdown from './components/CustomizedCompaniesDropdown';
import { useFormikContext } from 'formik';
import CustomizedUsersDropdown from './components/CustomizedUsersDropdown';
import { getNestedFormValue } from '../../utils';

const CustomizedDropdown = (props) => {
	const { isMultiple = false, isCompanies = false, isUsers = false, fieldName, options, error, renderOption } = props;
	const { values, setFieldValue } = useFormikContext();
	const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
	const [dropdownItems, setDropdownItems] = useState([]);

	const value = useMemo(() => {
		if (fieldName.includes('.')) {
			return getNestedFormValue(values, fieldName);
		} else {
			return values[fieldName];
		}
	}, [values, fieldName]);

	const onDropdownClick = (e) => {
		setDropdownItems(
			options.map((item) => ({
				title: renderOption ? renderOption(item) : item,
				isMultiple: isMultiple,
				selected: value && isMultiple ? Boolean(value.find((val) => val === item)) : value === item,
				onClick: () => {
					if (isMultiple) {
						const oldValue = Array.isArray(value) ? value : [];

						const newValue = oldValue.find((val) => val === item)
							? oldValue.filter((val) => val !== item)
							: [...oldValue, item];
						setFieldValue(fieldName, newValue);
					} else {
						setFieldValue(fieldName, item);
					}
					setDropdownAnchorEl(null);
				},
			}))
		);
		//todo
		setDropdownAnchorEl(e.currentTarget);
	};

	const renderDropdownContent = () => {
		const parsedValue = isMultiple && value ? value.join(', ') : value;

		if (parsedValue) {
			return (
				<Stack alignItems={'center'} direction={'row'} spacing={'2px'}>
					{parsedValue}
					<KeyboardArrowDownOutlined sx={{ fontSize: 16 }} />
				</Stack>
			);
		} else {
			return (
				<Stack direction={'row'} spacing={'4px'}>
					<AddOutlined sx={{ fontSize: 20 }} color={Boolean(error) ? 'error' : 'primary'} />
					{Boolean(error) && (
						<Box>
							<FormHelperText error={Boolean(error)}>{error}</FormHelperText>
						</Box>
					)}
				</Stack>
			);
		}
	};

	if (isCompanies) {
		return <CustomizedCompaniesDropdown {...props} />;
	}

	if (isUsers) {
		return <CustomizedUsersDropdown {...props} />;
	}

	return (
		<>
			<DropdownLink onClick={(e) => onDropdownClick(e)}>{renderDropdownContent()}</DropdownLink>
			<Menu
				anchorEl={dropdownAnchorEl}
				MenuListProps={{
					dense: true,
				}}
				open={Boolean(dropdownAnchorEl)}
				onClose={() => {
					setDropdownAnchorEl(null);
					setDropdownItems([]);
				}}
			>
				{dropdownItems.map((item) => {
					return (
						<MenuItem key={item.title} onClick={item.onClick} selected={item.selected}>
							{item.isMultiple && (
								<Checkbox checked={item.selected} size={'small'} sx={{ marginLeft: '-12px', marginRight: '2px' }} />
							)}
							{item.title}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default CustomizedDropdown;
