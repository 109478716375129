import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3-latest';
import { useResizeObserver } from '../../../../hooks';
import { Box, Card, CardContent, CardHeader, Stack, Typography, useTheme } from '@mui/material';
import { Circle } from '@mui/icons-material';

const transDuration = 500;
const format = d3.format(',');
const formatPrice = d3.format(',');
const dateFormatDay = d3.timeFormat('%d %b %Y');
const relaxFactor = 1.2;

function BlockBubbleChart({ bubbleData, dimensions }) {
	const defaultTheme = useTheme();
	const svgRef = useRef();
	const wrapperRef = useRef();
	const wrapDim = useResizeObserver(wrapperRef);

	let { height, margin = {} } = dimensions;

	const max = d3.max(bubbleData, (d) => d.price);

	// will be called initially and on every data change
	useEffect(() => {
		const svg = d3.select(svgRef.current);
		if (!wrapDim) return;

		let width = wrapDim.width;

		svg.selectAll('*').remove();

		svg.attr('height', height).attr('width', width).style('overflow', 'overlay');

		const axisGroup = svg.append('g').attr('class', 'axis');
		const circlesGroup = svg.append('g').attr('class', 'circles');
		const dateExtent = d3.extent(bubbleData, (d) => d.date);

		var startDate = new Date(dateExtent[0].getTime());
		startDate.setDate(startDate.getDate() - 15);

		var endDate = new Date(dateExtent[1].getTime());
		endDate.setDate(endDate.getDate() + 15);

		const relaxedWidth = width * 0.9;

		const x = d3
			.scaleUtc()
			.domain([startDate, endDate])
			.range([margin.left, relaxedWidth - margin.right])
			.clamp(true);

		const xAxis = (g) =>
			g
				.attr('transform', `translate(0,${height - margin.bottom})`)
				.call(d3.axisBottom(x).tickFormat(dateFormatDay).tickSizeOuter(0).ticks(5));

		axisGroup.append('g').call(xAxis);

		const y = d3
			.scaleLinear()
			.domain([0, max * relaxFactor])
			.range([height - margin.bottom, margin.top]);

		function formatTick(d) {
			const s = d.toFixed(0);
			return this.parentNode.nextSibling ? `\xa0${s}` : `$${s}`;
		}

		const yAxis = (g) =>
			g
				.attr('transform', `translate(${margin.left},0)`)
				.call(d3.axisRight(y).tickSize(relaxedWidth).ticks(5).tickFormat(formatTick))
				.call((g) => g.select('.domain').remove())
				.call((g) =>
					g.selectAll('.tick:not(:first-of-type) line').attr('stroke-opacity', 0.25).attr('stroke-dasharray', '3,3')
				)
				.call((g) => g.selectAll('.tick text').attr('x', 4).attr('dy', -5));

		axisGroup.append('g').call(yAxis);

		const rScale = d3
			.scaleSqrt()
			.domain([0, d3.max(bubbleData, (d) => d.quantity)])
			.range([0, 40]);

		const hoveredTransaction = circlesGroup
			.append('text')
			.attr('x', width / 2)
			.attr('dy', 5)
			.attr('y', 10)
			.attr('opacity', 0.8)
			.attr('font-size', '1.2em')
			// .attr('font-weight', 700)
			.attr('text-anchor', 'middle')
			.attr('fill', 'white');

		const circleSelection = circlesGroup
			.selectAll('circle')
			.data(bubbleData)
			.enter()
			.append('circle')
			.attr('cx', (d) => x(d.date))
			.attr('cy', (d) => y(d.price))
			.attr('fill', defaultTheme.palette.primary.main)
			.attr('stroke', 'white')
			.attr('opacity', 0.7)
			.attr('r', 0)
			.on('mouseover', function (event, d) {
				d3.select(this).attr('stroke-width', 2);
				hoveredTransaction.text(
					`${format(d.quantity)} shares were sold at $${formatPrice(d.price)} on ${dateFormatDay(d.date)}`
				);
			})
			.on('mouseout', function (event, d) {
				d3.select(this).attr('stroke-width', 1);
				hoveredTransaction.text('');
			});

		circleSelection
			.transition()
			.delay((d, i) => i * 50)
			.duration(transDuration * 2)
			.attr('r', (d) => rScale(d.quantity));
	}, [wrapDim, bubbleData, height, margin, defaultTheme.palette.primary.main, max]);

	return (
		<Card>
			<CardHeader title="Block Size Trading" />
			<CardContent>
				<Stack spacing={3} direction={'column'} alignItems={'center'}>
					<Box width={'100%'} ref={wrapperRef}>
						<svg ref={svgRef}></svg>
					</Box>

					<Stack direction={'row'} spacing={6}>
						<Stack direction={'row'} spacing={1} alignItems={'center'}>
							<Circle sx={{ color: 'primary.main', fontSize: '14px' }} />
							<Typography variant={'body1'} color={'text.secondary'}>
								Each circle represents a transaction. Circle size corresponds to block size, vertical position to price
								per share.
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
}

export default BlockBubbleChart;
