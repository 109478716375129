import { Avatar, Box, Chip, Link, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { dayMonthFormatter, hoursAgo } from '../../../../utils';
import { getUserAvatarURL } from '../../../../utils/getUserAvatar';
import * as d3 from 'd3-latest';
import { Link as RouterLink } from 'react-router-dom';

const BidItem = ({ bid }) => {
	const { 'Created At': createdAt, Answer, Shares, Block, Commission, 'Last Modified': lastModified } = bid.fields;
	const {
		UserId,
		'User Email': userEmail,
		'User Firstname': userFirstname,
		'User Lastname': userLastname,
	} = bid.fields;

	const renderAnswer = () => {
		switch (Answer) {
			case 'Not Interested':
				return <Chip size={'small'} color={'default'} label={'Not interested'} />;
			case 'Interested':
				return <Chip size={'small'} color={'warning'} label={'Interested'} />;
			case 'Applied':
				return <Chip size={'small'} color={'success'} label={'Applied'} />;
			default:
				return <Chip size={'small'} color={'primary'} label={'Waiting for answer...'} variant={'outlined'} />;
		}
	};

	return (
		<Paper sx={{ boxShadow: 'none', borderRadius: '6px' }} elevation={5}>
			<Stack sx={{ padding: '14px 12px' }} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
				<Stack direction={'row'} spacing={'40px'} width={'80%'}>
					<Stack direction={'row'} spacing={'12px'} alignItems={'center'} width={'40%'}>
						<Avatar alt={`${userFirstname?.[0] ?? ''} ${userLastname?.[0] ?? ''}`} src={getUserAvatarURL(userEmail[0])}>
							{`${userFirstname ? userFirstname[0][0] : ''}${userLastname ? userLastname[0][0] : ''}`}
						</Avatar>
						<Stack direction={'column'} spacing={'4px'} flex={1}>
							<Link
								component={RouterLink}
								underline={'hover'}
								variant="body2"
								state={{ preventScrollReset: true }}
								to={`/admin/bids/user/${UserId}`}
								style={{ width: '100%' }}
							>
								{`${userFirstname?.[0] ?? ''} ${userLastname?.[0] ?? ''}`}
							</Link>
							<Typography
								variant={'body2'}
								color={'text.secondary'}
								style={{
									whiteSpace: 'pre-wrap',
									wordBreak: 'break-all',
									width: '100%',
								}}
							>
								{userEmail[0]}
							</Typography>
						</Stack>
					</Stack>
					<Stack direction={'column'} spacing={'4px'} width={'10%'}>
						<Typography variant={'body2'} color={'text.secondary'}>
							Invited
						</Typography>
						<Typography variant={'body2'}>{dayMonthFormatter(new Date(createdAt))}</Typography>
					</Stack>
					<Stack direction={'column'} spacing={'4px'} width={'10%'}>
						<Typography variant={'body2'} color={'text.secondary'}>
							Modified
						</Typography>
						<Typography variant={'body2'}>
							{
								// using the lastModified, I want to show a number of hours ago this was edited
								// I will use the Date object to get the current time and subtract the lastModified time

								lastModified ? hoursAgo(new Date(lastModified)) : '-'
							}
						</Typography>
					</Stack>
					<Stack direction={'column'} spacing={'4px'} width={'15%'}>
						<Typography variant={'body2'} color={'text.secondary'}>
							Shares
						</Typography>
						<Typography variant={'body2'}>{Shares ? d3.format(',')(Shares) : '-'}</Typography>
					</Stack>
					<Stack direction={'column'} spacing={'4px'} width={'15%'}>
						<Typography variant={'body2'} color={'text.secondary'}>
							Block
						</Typography>
						<Typography variant={'body2'}>{Block ? d3.format('$,.2f')(Block) : '-'}</Typography>
					</Stack>
					<Stack direction={'column'} spacing={'4px'} width={'15%'}>
						<Typography variant={'body2'} color={'text.secondary'}>
							Commission
						</Typography>
						<Typography variant={'body2'}>{Commission ? d3.format('$,.2f')(Commission) : '-'}</Typography>
					</Stack>
				</Stack>
				<Box flexShrink={0}>{renderAnswer()}</Box>
			</Stack>
		</Paper>
	);
};

export default BidItem;
