import {
	Button,
	Card,
	Grid,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { apiClient, endpoints } from '../../../../utils';
import { toast } from 'react-toastify';
import { NotFoundContainer } from '../../../../components/CompanyList/companyList.styles';
import EntityDrawer from './components/EntityDrawer';
import DeleteDialog from './components/DeleteDialog';
import { strings } from '../../../../utils/strings';

const EntitiesTab = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultTheme = useTheme();

	const [isReadyToLoad, setReadyToLoad] = useState(false);
	const [isFirstLoading, setFirstLoading] = useState(true);

	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const [nameFilter, setNameFilter] = useState('');

	const [selectedObj, setSelectedObj] = useState(null);
	const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

	const [isDeleteOpen, setDeleteOpen] = useState(false);

	useEffect(() => {
		// firstly data
		loadData().then((res) => {
			setFilteredData(res);
			setData(res);
			isFirstLoading && setFirstLoading(false);
		});
	}, []);

	useEffect(() => {
		// secondly load parameters
		const nameParam = searchParams.get('name');
		if (nameParam && nameParam.trim()) {
			setNameFilter(nameParam.trim());
		} else {
			setNameFilter('');
		}

		!isReadyToLoad &&
			setTimeout(() => {
				setReadyToLoad(true);
			}, 300);
	}, [searchParams]);

	useEffect(() => {
		//thirdly filter data
		if (isReadyToLoad) {
			if (nameFilter) {
				setFilteredData(
					data.filter((item) => {
						return item.fields && item.fields.Name && item.fields.Name.toLowerCase().includes(nameFilter.toLowerCase());
					})
				);
			} else {
				setFilteredData(data);
			}
		}
	}, [setFilteredData, isReadyToLoad, data, nameFilter]);

	useEffect(() => {
		//deletion
		if (location?.state?.deleteId) {
			setData((prevState) => prevState.filter((item) => item.id !== location?.state?.deleteId));
			setFilteredData((prevState) => prevState.filter((item) => item.id !== location?.state?.deleteId));
		}
	}, [location.pathname, location?.state?.deleteId]);

	useEffect(() => {
		//update
		const updateObjectId = location?.state?.updateObjectId;
		if (
			updateObjectId &&
			(updateObjectId === 'new' || filteredData.find((obj) => obj.id === location?.state?.updateObjectId))
		) {
			loadData().then((res) => {
				const found = res.find((resItem) => resItem.id === location?.state?.updateObjectId);
				if (found) {
					setData((prevState) =>
						prevState.map((item) => {
							if (item.id === location?.state?.updateObjectId) {
								return found ?? item;
							} else {
								return item;
							}
						})
					);

					setFilteredData((prevState) =>
						prevState.map((item) => {
							if (item.id === location?.state?.updateObjectId) {
								return found ?? item;
							} else {
								return item;
							}
						})
					);
				} else {
					setData(res);
					setFilteredData(res);
				}
			});
		}
	}, [location.pathname, location?.state?.updateObjectId]);

	const loadData = async () => {
		try {
			const res = await apiClient.get(endpoints.entities);
			return res.data ?? [];
		} catch (err) {
			toast.error(strings.errorMessages.entity.loadMany);
			return [];
		}
	};

	const handleActionsClick = (event, obj) => {
		setSelectedObj(obj);
		setMenuAnchorEl(event.currentTarget);
	};

	const handleViewDetails = () => {
		navigate('/settings/entities/' + selectedObj.id + window.location.search, {
			state: { preventScrollReset: true },
		});
		setMenuAnchorEl(null);
	};

	const addNew = () => {
		navigate('/settings/entities/new' + window.location.search, {
			state: { preventScrollReset: true },
		});
	};

	return (
		<Grid container rowSpacing={2} columnSpacing={3}>
			{isFirstLoading && (
				<Grid item xs={12}>
					<Skeleton variant="rectangular" height={300} />
				</Grid>
			)}
			{!isFirstLoading && !data.length && (
				<Grid item xs={12}>
					<NotFoundContainer>
						<Stack spacing={3} alignItems={'center'}>
							<img src={'/illustrations/dark/userPlus.svg'} style={{ height: 200 }} alt="Not found" />
							<Stack spacing={3} alignItems={'center'}>
								<Typography variant={'h5'}>No entities found</Typography>
								<Button variant="contained" size={'medium'} onClick={addNew}>
									Add new entity
								</Button>
							</Stack>
						</Stack>
					</NotFoundContainer>
				</Grid>
			)}
			{!isFirstLoading && data.length > 0 && (
				<>
					<Grid item xs={6}>
						<TextField
							fullWidth
							variant={'outlined'}
							value={nameFilter}
							onChange={(e) => {
								const value = e.target.value;

								setSearchParams(
									value
										? {
												name: value,
										  }
										: {}
								);
							}}
							label={'Name'}
						/>
					</Grid>
					<Grid item xs={6}>
						<Stack alignItems={'flex-end'} justifyContent={'center'} height={'100%'}>
							<Button variant="contained" size={'medium'} onClick={addNew}>
								Add new entity
							</Button>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<TableContainer component={Card}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell sx={{ color: 'text.secondary' }}>Name</TableCell>
										<TableCell sx={{ color: 'text.secondary' }}>Address</TableCell>
										<TableCell sx={{ color: 'text.secondary' }}>Signatory name</TableCell>
										<TableCell sx={{ color: 'text.secondary' }}>Signatory email</TableCell>
										<TableCell sx={{ color: 'text.secondary' }}>Signatory title</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredData.map(({ id, fields }) => {
										return (
											<TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell>
													<Stack spacing={0} direction={'column'}>
														<Link
															underline={'hover'}
															variant={'body1'}
															component={RouterLink}
															to={'/settings/entities/' + id + window.location.search}
															state={{ preventScrollReset: true }}
														>
															<Typography variant={'body2'}>{fields['Name']}</Typography>
														</Link>
													</Stack>
												</TableCell>

												<TableCell>{fields.Address}</TableCell>
												<TableCell>{fields['Signatory Name']}</TableCell>
												<TableCell>{fields['Signatory Email']}</TableCell>
												<TableCell>{fields['Signatory Title']}</TableCell>
												<TableCell style={{ width: 70 }}>
													<IconButton onClick={(e) => handleActionsClick(e, { id, fields })}>
														<MoreVert sx={{ color: 'text.secondary' }} />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Menu
							anchorEl={menuAnchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							open={Boolean(menuAnchorEl)}
							onClose={() => {
								setMenuAnchorEl(null);
								selectedObj(null);
							}}
						>
							<MenuItem onClick={handleViewDetails}>View details</MenuItem>
							<MenuItem
								sx={{ color: defaultTheme.palette.error.main }}
								onClick={() => {
									setDeleteOpen(true);
									setMenuAnchorEl(null);
								}}
							>
								Delete
							</MenuItem>
						</Menu>
						<DeleteDialog
							object={selectedObj}
							onSuccess={() => {
								setFilteredData((prevState) => prevState.filter((item) => item.id !== selectedObj.id));
								setData((prevState) => prevState.filter((item) => item.id !== selectedObj.id));
								setSelectedObj(null);
							}}
							open={isDeleteOpen}
							handleClose={() => {
								setDeleteOpen(false);
								setMenuAnchorEl(null);
								setSelectedObj(null);
							}}
						/>
					</Grid>
				</>
			)}
			<EntityDrawer entities={data} />
		</Grid>
	);
};

export default EntitiesTab;
