import { ChatBoxWrapper } from './styles';
import ChatBox from '../../../ChatWidget/components/ChatTab/ChatBox';
import { Box, LinearProgress, Stack } from '@mui/material';
import ChatControls from '../../../ChatWidget/components/ChatTab/ChatControls';
import { TabPanel } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiClient, endpoints } from '../../../../utils';
import { toast } from 'react-toastify';
import { strings } from '../../../../utils/strings';
import { useAudioContext } from '../../../../context';

const ChatTab = ({ setDraft }) => {
	const { id: userId } = useParams();
	const { playSendSound: playSendMessageSound } = useAudioContext();
	const [chat, setChat] = useState(null);

	const [isLoading, setLoading] = useState(false);
	const [isFileLoading, setFileLoading] = useState(false);

	const loadChat = async () => {
		try {
			const res = await apiClient.get(endpoints.getChatById.replace('{userId}', userId));
			setChat(res.data);
		} catch (err) {
			toast.error(strings.errorMessages.chat.load);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (userId) {
			setLoading(true);
			loadChat();

			//then, set interval to fetch new messages every 30 seconds
			const intervalId = setInterval(loadChat, 30000);

			// Clean up the interval when the component unmounts
			return () => clearInterval(intervalId);
		} else {
			setChat(null);
		}
	}, [userId]);

	const uploadFiles = async (files) => {
		const formData = new FormData();

		for (const file of files) {
			if (file instanceof Blob) {
				formData.append('files', file);
				formData.append('userId', userId);
			} else {
				const blob = await fetch(file.url).then((r) => r.blob());
				const parts = [blob];
				const newFile = new File(parts, file.filename, { url: file.url, ...file });
				formData.append('files', newFile);
			}
		}

		return await apiClient.post(endpoints.sendChatAttachment, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	const handleSendMessage = async (newMessage, files) => {
		if (newMessage) {
			playSendMessageSound();

			const res = await apiClient.post(endpoints.replyToChat, {
				message: newMessage,
				userId: userId,
			});

			if (res.data) {
				setChat((prev) => {
					return {
						...prev,
						chats: [...prev.chats, ...res.data],
					};
				});
				setDraft(true);
			}
		}

		if (files) {
			setFileLoading(true);
			const res = await uploadFiles(files);

			if (res.data) {
				playSendMessageSound();
				setChat((prev) => {
					return {
						...prev,
						chats: [...prev.chats, ...res.data],
					};
				});
				setDraft(true);
				setFileLoading(false);
			}
		}
	};
	return (
		<TabPanel value="2" sx={{ padding: 0, height: '100%', overflow: 'auto' }}>
			{isLoading && <LinearProgress />}
			{!isLoading && chat && (
				<Box display={'flex'} flexDirection={'column'} height={'100%'}>
					<ChatBoxWrapper>
						<ChatBox
							messages={chat ? chat.chats : []}
							isAdmin={true}
							id="admin-chat-box"
							username={chat ? chat.name : undefined}
						/>
					</ChatBoxWrapper>
					<LinearProgress sx={{ visibility: isFileLoading ? 'visible' : 'hidden' }} />
					<Stack sx={{ borderTop: 1, borderColor: 'divider' }} p={2}>
						<ChatControls handleSendMessage={handleSendMessage} />
					</Stack>
				</Box>
			)}
		</TabPanel>
	);
};

export default ChatTab;
