import PageTitle from '../../components/PageTitle';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	// InputAdornment,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import InfoBlock from '../../components/InfoBlock';
import CompanyUploader from '../../components/CompanyUploader';
import { useEffect, useState } from 'react';
import { apiClient, endpoints } from '../../utils';
import { toast } from 'react-toastify';
import InquiryRecordItem from '../../components/InquiryRecordItem';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { strings } from '../../utils/strings';

const MessageView = () => {
	const [message, setMessage] = useState('');
	const [link, setLink] = useState('');
	const [selectedCompany, setSelectedCompany] = useState();
	const [areInquiriesLoading, setInquiriesLoading] = useState(false);
	const [isSendingLoading, setSendingLoading] = useState(false);
	const [inquiries, setInquiries] = useState([]);
	const [selectedInquiries, setSelectedInquiries] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const loadInquiries = async () => {
			setInquiriesLoading(true);
			try {
				const res = await apiClient.post(endpoints.getInquiries, {
					companyIds: selectedCompany ? [selectedCompany['Record Id']] : [],
					priority: selectedCompany && selectedCompany['Record Id'] ? false : true,
				});
				return res.data ?? [];
			} catch (err) {
				toast.error(strings.errorMessages.inquiry.loadMany);
				return [];
			} finally {
				setInquiriesLoading(false);
			}
		};

		loadInquiries().then((res) => {
			setInquiries(res);
		});
	}, [selectedCompany]);

	const onSelectAll = (event) => {
		setSelectedInquiries(event.target.checked ? inquiries : []);
	};

	const onSelectOne = (event, inquiry) => {
		if (event.target.checked) {
			setSelectedInquiries((prevState) => [...prevState, inquiry]);
		} else {
			setSelectedInquiries((prevState) => prevState.filter((item) => item.fields.RecId !== inquiry.fields.RecId));
		}
	};

	const sendMessage = async () => {
		setSendingLoading(true);
		const body = {
			message: message.trim(),
			link: link.trim() ? link.trim() : undefined,
			inquiriesUsers: selectedInquiries.map((item) => ({
				inquiryId: item.fields.RecId,
				userId: item.fields.UserId[0],
				userEmail: item.fields['User Email'],
			})),
		};

		try {
			const res = await apiClient.post(endpoints.sendMessage, body);

			if (res) {
				navigate('/admin/message/success');
			}
		} catch {
			toast.error(strings.errorMessages.message.send);
		} finally {
			setSendingLoading(false);
		}
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Admin',
						link: '/admin',
					},
					{
						title: 'Send email',
					},
				]}
				title={'Send email to users with inquiries'}
			/>
			<Stack spacing={4}>
				<InfoBlock title={'1. Enter your message'}>
					<Grid item xs={8}>
						<TextField
							label="Enter your message"
							autoComplete="off"
							rows={3}
							multiline={true}
							fullWidth
							helperText="Maximum length: 600 characters"
							inputProps={{ maxLength: 600 }}
							value={message}
							onChange={(event) => {
								setMessage(event.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						<TextField
							label="Add link (optional)"
							autoComplete="off"
							fullWidth
							value={link}
							onChange={(event) => {
								setLink(event.target.value);
							}}
						/>
					</Grid>
				</InfoBlock>
				<InfoBlock title={'2. Select a company'}>
					<Grid item xs={12}>
						<CompanyUploader selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} />
					</Grid>
				</InfoBlock>
				<InfoBlock title={`Inquiries (${inquiries.length})`}>
					<Grid item xs={12}>
						{areInquiriesLoading && (
							<Stack spacing={'12px'}>
								{Array.from(Array(4).keys()).map((_, index) => (
									<Skeleton variant="rectangular" height={76} key={index} />
								))}
							</Stack>
						)}
						{!areInquiriesLoading && inquiries.length === 0 && (
							<Typography variant={'body2'} color={'text.secondary'}>
								No inquiries founds for selected company.
							</Typography>
						)}
						{!areInquiriesLoading && inquiries.length > 0 && (
							<Stack spacing={'8px'}>
								<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'} pl={'28px'}>
									<FormControlLabel
										control={
											<Checkbox checked={selectedInquiries.length === inquiries.length} onChange={onSelectAll} />
										}
										label="Select all"
									/>
									<Typography>{`Selected: ${selectedInquiries.length}`}</Typography>
								</Stack>
								<Stack spacing={'12px'}>
									{inquiries.map((inquiry) => (
										<InquiryRecordItem
											key={inquiry.fields.RecId}
											inquiry={inquiry}
											isSelected={Boolean(selectedInquiries.find((item) => item.fields.RecId === inquiry.fields.RecId))}
											handleCheck={(ev) => onSelectOne(ev, inquiry)}
										/>
									))}
								</Stack>
							</Stack>
						)}
					</Grid>
				</InfoBlock>
				<Stack alignItems={'end'}>
					<LoadingButton
						variant={'contained'}
						disabled={message.trim() === '' || selectedInquiries.length === 0}
						onClick={sendMessage}
						loading={isSendingLoading}
						endIcon={<></>}
						loadingPosition={'end'}
						sx={{ width: 180 }}
					>
						Send email
					</LoadingButton>
				</Stack>
			</Stack>
		</>
	);
};

export default MessageView;
