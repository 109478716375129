import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import BidSnackbar from '../components/ActivityFeed/components/BidSnackbar';

const ActivityFeedContext = createContext({
	isOpen: true,
	setOpen: () => {},
	feed: [],
	lastNewFeed: null,
	updatedFeed: () => {},
	clearLastNewFeed: () => {},
});

export const ActivityFeedProvider = ({ children }) => {
	const [isOpen, setOpen] = useState(false);
	const [feed, setFeed] = useState([]);
	const [lastNewFeed, setLastNewFeed] = useState(null);
	const { bids } = useAuthContext();

	useEffect(() => {
		setFeed(bids);
		setLastNewFeed(bids.find((bid) => !Boolean(bid.fields.Answer)));
	}, [bids]);

	const updateFeed = (newFeed) => {
		setFeed((prev) => prev.map((item) => (item.id === newFeed.id ? newFeed : item)));
	};

	const clearLastNewFeed = () => [setLastNewFeed(null)];

	return (
		<ActivityFeedContext.Provider
			value={{
				feed,
				lastNewFeed,
				isOpen,
				setOpen,
				updateFeed,
				clearLastNewFeed,
			}}
		>
			{children}
			<BidSnackbar />
		</ActivityFeedContext.Provider>
	);
};

export const useActivityFeedContext = () => useContext(ActivityFeedContext);
