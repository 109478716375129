import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	Grid,
	IconButton,
	Link,
	Stack,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DeleteOutline, Edit, ExpandLess, ExpandMore, Mail } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { onlyDateFormatter } from '../../../../utils';
import * as d3 from 'd3-latest';
import { BidsCollapseHeader } from './style';
import BidItem from '../BidItem';
import DeleteDialog from '../DeleteDialog';
import { airtableConstants } from '../../../../utils/airtableConstants';
import { useDebouncedCallback } from 'use-debounce';

const MasterBidCard = ({ masterbid, users }) => {
	const defaultTheme = useTheme();
	const { id, fields } = masterbid;
	const buyer = useMemo(() => users.find((user) => user.id === fields['Buyer']?.[0]), [fields, users]);
	const [isCollapsed, setCollapsed] = useState(false);
	const [isDeleteOpen, setDeleteOpen] = useState(false);

	const [userFilter, setUserFilter] = useState(null);
	const [statusFilter, setStatusFilter] = useState([]);

	const filteredBids = useMemo(() => {
		let filteredBids = fields.Bids;

		filteredBids = filteredBids.filter((bid) => bid.fields['User']); //make sure that the user is not null

		if (userFilter) {
			filteredBids = filteredBids.filter((bid) => {
				return (
					bid.fields['User Email'][0].toLowerCase().includes(userFilter.toLowerCase()) ||
					bid.fields['User Firstname'][0].toLowerCase().includes(userFilter.toLowerCase()) ||
					bid.fields['User Lastname'][0].toLowerCase().includes(userFilter.toLowerCase())
				);
			});
		}

		if (statusFilter.length) {
			filteredBids = filteredBids.filter((bid) => {
				return (
					statusFilter.includes(bid.fields.Answer) ||
					(statusFilter.includes('Waiting for answer') && !bid.fields.Answer)
				);
			});
		}

		return filteredBids;
	}, [fields.Bids, userFilter, statusFilter]);

	const getSellersBlockSumm = () => {
		let sum = 0;
		(fields?.Bids ?? []).forEach((obj) => {
			sum += obj.fields.Block;
		});
		return sum;
	};

	const getProgressPercentage = () => {
		return Math.round((getSellersBlockSumm() * 100) / fields['Block Min']);
	};

	const onUserFilterChange = useDebouncedCallback(
		(e) => {
			setUserFilter(e.target.value);
		},
		[1000]
	);

	const onStatusFilterChange = (_, value) => {
		setStatusFilter(value);
	};

	return (
		<Card>
			<CardHeader
				title={fields.Name}
				subheader={buyer?.fields.Email}
				action={
					<Stack direction={'row'} alignItems={'center'} sx={{ color: 'text.secondary' }}>
						<Box mr={'6px'}>
							<Button
								variant={'contained'}
								size={'small'}
								startIcon={<Mail />}
								component={RouterLink}
								to={`${id}/invite`}
							>
								invite sellers
							</Button>
						</Box>
						<IconButton color="inherit" size="small" component={RouterLink} to={id + window.location.search}>
							<Edit />
						</IconButton>
						<IconButton color="error" size="small" onClick={() => setDeleteOpen(true)}>
							<DeleteOutline />
						</IconButton>
					</Stack>
				}
			/>
			<CardContent>
				<Stack direction={'column'} spacing={4}>
					<Stack spacing={'50px'} direction={'row'}>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Progress
							</Typography>
							<Stack direction={'row'} alignItems={'center'} spacing={1}>
								<LinearProgress
									color={getProgressPercentage() < 100 ? 'primary' : 'success'}
									variant={'determinate'}
									value={getProgressPercentage()}
									sx={{ width: 120, flexShrink: 0 }}
								/>
								<Typography variant={'body2'} sx={{ flexShrink: 0 }}>
									{`${getProgressPercentage()}% (${d3.format('$.0s')(getSellersBlockSumm())} / ${d3.format('$,.0s')(
										fields['Block Min']
									)})`}
								</Typography>
							</Stack>
						</Stack>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Company
							</Typography>
							<Link
								variant={'body2'}
								underline={'hover'}
								component={RouterLink}
								to={'/company/' + fields['Company'][0]}
								target={'_blank'}
							>
								{fields['Company Name'][0]}
							</Link>
						</Stack>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Valid to
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
								{onlyDateFormatter(new Date(fields['Valid To']))}
							</Typography>
						</Stack>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Gross Price
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
								{d3.format('$,.2f')(fields['Gross Price'])}
							</Typography>
						</Stack>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Block Min
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
								{d3.format('$,.2f')(fields['Block Min'])}
							</Typography>
						</Stack>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Block Max
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
								{d3.format('$,.2f')(fields['Block Max'])}
							</Typography>
						</Stack>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Visibility
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
								{fields['Market Visibility']}
							</Typography>
						</Stack>
					</Stack>
					<Stack direction={'column'} spacing={'18px'}>
						<BidsCollapseHeader onClick={() => setCollapsed((prevState) => !prevState)}>
							<Stack direction={'row'} spacing={4} alignItems={'center'}>
								<Typography variant={'h6'}>Bids</Typography>
								<Stack direction={'row'} spacing={'20px'} pt={'4px'} alignItems={'center'}>
									<Stack direction={'row'} spacing={'6px'} alignItems={'center'}>
										<Typography variant={'body2'} color={'text.secondary'}>
											Total sent:
										</Typography>
										<Typography variant={'body2'} color={'text.primary'}>
											{fields?.Bids?.length ?? 0}
										</Typography>
									</Stack>
									<Stack direction={'row'} spacing={'6px'} alignItems={'center'}>
										<Typography variant={'body2'} color={'text.secondary'}>
											No reply:
										</Typography>
										<Typography variant={'body2'} color={'text.primary'}>
											{fields?.Bids?.filter((bid) => !Boolean(bid.fields.Answer)).length ?? 0}
										</Typography>
									</Stack>
									<Stack direction={'row'} spacing={'6px'} alignItems={'center'}>
										<Typography variant={'body2'} color={'text.secondary'}>
											Not interested:
										</Typography>
										<Typography variant={'body2'} color={'text.primary'}>
											{fields?.Bids?.filter((bid) => bid.fields.Answer === 'Not Interested').length ?? 0}
										</Typography>
									</Stack>
									<Stack direction={'row'} spacing={'6px'} alignItems={'center'}>
										<Typography variant={'body2'} color={'text.secondary'}>
											Interested:
										</Typography>
										<Typography variant={'body2'} color={'text.primary'}>
											{fields?.Bids?.filter((bid) => bid.fields.Answer === 'Interested').length ?? 0}
										</Typography>
									</Stack>
									<Stack direction={'row'} spacing={'6px'} alignItems={'center'}>
										<Typography variant={'body2'} color={'text.secondary'}>
											Applied:
										</Typography>
										<Typography variant={'body2'} color={'text.primary'}>
											{fields?.Bids?.filter((bid) => bid.fields.Answer === 'Applied').length ?? 0}
										</Typography>
									</Stack>
								</Stack>
							</Stack>
							{isCollapsed ? (
								<ExpandMore sx={{ color: defaultTheme.palette.grey['500'] }} />
							) : (
								<ExpandLess sx={{ color: defaultTheme.palette.grey['500'] }} />
							)}
						</BidsCollapseHeader>
						<Collapse in={!isCollapsed}>
							<Stack direction={'column'} spacing={'18px'}>
								<Box>
									<Grid container spacing={2}>
										<Grid item xs={8}>
											<TextField
												fullWidth
												size={'small'}
												variant={'outlined'}
												onChange={onUserFilterChange}
												placeholder={'Search user by name or email'}
												label={'User'}
											/>
										</Grid>
										<Grid item xs={4}>
											<Autocomplete
												multiple
												size={'small'}
												options={airtableConstants.BID.STATUS}
												getOptionLabel={(option) => option}
												isOptionEqualToValue={(option, value) => option === value}
												onChange={onStatusFilterChange}
												renderInput={(params) => <TextField {...params} label="Status" placeholder="Select status" />}
											/>
										</Grid>
									</Grid>
								</Box>
								<Stack direction={'column'} spacing={'10px'}>
									{filteredBids.map((bid) => (
										<BidItem key={bid.id} bid={bid} />
									))}
								</Stack>
							</Stack>
						</Collapse>
					</Stack>
				</Stack>
			</CardContent>
			<DeleteDialog
				masterbid={masterbid}
				open={isDeleteOpen}
				handleClose={() => {
					setDeleteOpen(false);
				}}
			/>
		</Card>
	);
};

export default MasterBidCard;
