import { Box, Card, CardContent, Chip, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import * as d3 from 'd3-latest';
import { dateFormatter } from '../../../../utils';
import { useMemo } from 'react';

const BidCard = ({ bid }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const shares = useMemo(() => (bid ? bid.fields.Shares ?? 0 : 0), [bid]);
	const commission = useMemo(() => {
		if (bid) {
			if (bid.fields['Commission per Share']?.[0] && bid.fields['Commission per Share'] > 0) {
				return bid.fields['Commission per Share'][0] * shares;
			} else {
				if (!shares) return 0;
				return bid.fields['Commission Pct']?.[0] * bid.fields['Gross Price']?.[0] * shares;
			}
		}
	}, [bid, shares]);
	const priceNet = useMemo(() => {
		if (bid) {
			if (shares > 0) {
				return shares * bid.fields['Gross Price'] - commission;
			} else {
				return 0;
			}
		}
	}, [bid, shares]);
	const blockSizeGross = useMemo(() => {
		if (bid) {
			return shares * bid.fields['Gross Price']?.[0];
		}
	}, [bid, shares]);
	const netToYou = useMemo(() => {
		if (bid) {
			if (priceNet > 0) {
				return priceNet - bid.fields['Admin Fee'];
			} else {
				return 0;
			}
		}
	}, [bid, priceNet]);

	return (
		<Card key={bid.id}>
			<CardContent>
				<Stack spacing={isMobile ? '18px' : 3} direction={'column'}>
					<Stack direction={'row'} alignItems={'center'}>
						<Stack spacing={'10px'} alignItems={'center'} direction={'row'} flex={1}>
							<Link
								component={RouterLink}
								color={'text.primary'}
								underline={'hover'}
								variant="h5"
								to={`/company/${bid.fields.Company[0]}`}
							>
								{bid.fields['Company Name'][0]}
							</Link>
							<Chip label="Bid" variant="outlined" color={'primary'} size={'small'} />
						</Stack>
						{!isMobile && <Chip label="Applied" variant="contained" color={'success'} size={'small'} />}
					</Stack>
					<Stack spacing={isMobile ? '6px' : '40px'} direction={!isMobile ? 'row' : 'column'}>
						{isMobile && (
							<Stack alignItems={'center'} direction={'row'}>
								<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
									Status
								</Typography>
								<Box flex={isMobile ? 1 : undefined}>
									<Chip label="Applied" variant="contained" color={'success'} size={'small'} />
								</Box>
							</Stack>
						)}

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Applied at
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{dateFormatter(new Date(bid.fields['Last Modified']))}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Price Per Share (Gross)
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{d3.format('$,.2f')(bid.fields['Gross Price']?.[0])}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Shares/Options
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{d3.format(',')(bid.fields.Shares)}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Block Size (Gross)
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{d3.format('$,.2f')(blockSizeGross)}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Net Calculation
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{d3.format('$,.2f')(netToYou)}
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default BidCard;
