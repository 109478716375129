import PageTitle from '../../components/PageTitle';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Autocomplete, Button, Grid, MenuItem, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { EmptyContainer } from '../DealsPageView/dealsPageView.styles';
import { apiClient, endpoints } from '../../utils';
import MasterBidCard from './components/MasterBidCard';
import BidsDrawerWrapper from './components/BidsDrawerWrapper';
import { usePrivateAppContext } from '../../context';
import Select from '../../components/inputs/Select';

const AdminBidsView = () => {
	const newMasterbidLink = '/admin/bids/new' + window.location.search;
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const [isLoading, setLoading] = useState(true);
	const [isReadyToFilter, setReadyToFilter] = useState(false);

	const [masterbids, setMasterbids] = useState([]);
	const [filteredMasterbids, setFilteredMasterbids] = useState([]);

	//filters
	const { companies } = usePrivateAppContext();
	const [users, setUsers] = useState([]);
	const [selectedCompanies, setSelectedCompanies] = useState([]);
	const [validUpDate, setValidUpDate] = useState('');
	const [status, setStatus] = useState('Active');

	useEffect(() => {
		// firstly load users
		apiClient.get(endpoints.getUsers).then((res) => {
			setUsers(res.data);
		});
	}, []);

	useEffect(() => {
		// secondly load parameters
		if (companies && companies.length) {
			const companyParam = searchParams.get('company');
			const validUpParam = searchParams.get('validUp');
			const statusParam = searchParams.get('status');

			const isCompanyParamExist = Boolean(companyParam && companyParam.trim());
			const isValidUpParamExist = Boolean(validUpParam && validUpParam.trim());
			const isStatusParamExist = Boolean(statusParam && statusParam.trim());

			if (isCompanyParamExist) {
				setSelectedCompanies(
					companyParam
						.trim()
						.split(',')
						.map((item) => companies.find((found) => found['Legal Name'] === item))
				);
			} else {
				setSelectedCompanies([]);
			}

			if (isValidUpParamExist) {
				setValidUpDate(validUpParam.trim());
			} else {
				setValidUpDate('');
			}

			if (isStatusParamExist) {
				setStatus(statusParam.trim());
			} else {
				setStatus('Active');
			}

			!isReadyToFilter && setReadyToFilter(true);
		}
	}, [searchParams, isReadyToFilter, companies]);

	useEffect(() => {
		//thirdly load masterbids
		if (isReadyToFilter) {
			apiClient
				.get(endpoints.getMasterBids, {
					params: {
						status: status,
					},
				})
				.then((res) => {
					setMasterbids(res.data);
				});
		}
	}, [isReadyToFilter, status]);

	useEffect(() => {
		//fourthly filter masterbids
		if (isReadyToFilter && masterbids.length) {
			let filteredItems = masterbids;

			if (selectedCompanies.length) {
				filteredItems = filteredItems.filter((item) => {
					return Boolean(selectedCompanies.find((company) => company['Record Id'] === item.fields.Company?.[0]));
				});
			}

			if (validUpDate) {
				filteredItems = filteredItems.filter((item) => {
					return new Date(item.fields['Valid To']) <= new Date(validUpDate);
				});
			}

			setFilteredMasterbids(filteredItems);
		}

		if (isLoading) {
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		}
	}, [selectedCompanies, validUpDate, isReadyToFilter, companies, masterbids]);

	useEffect(() => {
		//update or create
		const updateObjectId = location?.state?.updateObjectId;

		if (updateObjectId) {
			if (updateObjectId === 'new') {
				apiClient.get(endpoints.getMasterBids).then((res) => {
					setMasterbids(res?.data ?? []);
				});
			} else if (masterbids.find((item) => item.id === updateObjectId)) {
				apiClient.get(endpoints.getMasterBidById.replace('{id}', updateObjectId)).then((res) => {
					setMasterbids((prevState) =>
						prevState.map((item) => {
							if (item.id === updateObjectId) {
								return res.data;
							} else {
								return item;
							}
						})
					);
				});
			}
		}
	}, [location.pathname, location?.state?.updateObjectId]);

	useEffect(() => {
		//delete
		if (location?.state?.deleteId) {
			setMasterbids((prevState) => prevState.filter((item) => item.id !== location?.state?.deleteId));
		}
	}, [location.pathname, location?.state?.deleteId]);

	const onCompanyChange = (_, value) => {
		if (value?.length) {
			searchParams.set(
				'company',
				value.map((item) => item['Legal Name'])
			);
		} else {
			searchParams.delete('company');
		}

		setSearchParams(searchParams);
	};

	const onValidUpChange = (e) => {
		if (e.target.value) {
			searchParams.set('validUp', e.target.value);
		} else {
			searchParams.delete('validUp');
		}

		setSearchParams(searchParams);
	};

	const onStatusChange = (e) => {
		if (e.target.value) {
			searchParams.set('status', e.target.value);
		} else {
			searchParams.delete('status');
		}

		setSearchParams(searchParams);
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Admin',
						link: '/admin',
					},
					{
						title: 'Manage bids',
					},
				]}
				title={'Bids'}
				extraButton={{
					label: 'create master bid',
					onClick: () => {
						navigate(newMasterbidLink);
					},
				}}
			/>

			<Grid container spacing={2} marginBottom={3}>
				<Grid item xs={6}>
					<Autocomplete
						multiple
						options={companies}
						getOptionLabel={(option) => option?.['Legal Name']}
						isOptionEqualToValue={(option, value) => option?.['Record Id'] === value?.['Record Id']}
						value={selectedCompanies}
						onChange={onCompanyChange}
						renderInput={(params) => <TextField {...params} label="Company" />}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						type={'date'}
						label={'Valid up to'}
						variant={'outlined'}
						value={validUpDate}
						fullWidth
						onChange={onValidUpChange}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<Select value={status} label="Status" fullWidth onChange={onStatusChange}>
						{['Active', 'Inactive', 'All'].map((item) => (
							<MenuItem key={item} value={item}>
								{item}
							</MenuItem>
						))}
					</Select>
				</Grid>
			</Grid>

			{isLoading && (
				<Stack spacing={4} width={'100%'}>
					<Skeleton variant="rectangular" height={310} />
					<Skeleton variant="rectangular" height={310} />
				</Stack>
			)}

			{!isLoading && filteredMasterbids.length === 0 && (
				<EmptyContainer>
					<Stack spacing={3} alignItems={'center'}>
						<img src={'/illustrations/dark/notFound.svg'} style={{ height: 252 }} alt="No bids" />
						<Stack spacing={1} alignItems={'center'}>
							<Typography variant={'h5'}>No bids found</Typography>
							<Typography variant={'body2'} textAlign={'center'}>
								We couldn't find what you searched for. Change your search criteria.
							</Typography>
						</Stack>
						<Button variant={'contained'} component={RouterLink} to={newMasterbidLink}>
							create master bid
						</Button>
					</Stack>
				</EmptyContainer>
			)}

			{!isLoading && filteredMasterbids.length > 0 && (
				<Stack spacing={4}>
					{filteredMasterbids.map((masterbid) => (
						<MasterBidCard key={masterbid.id} masterbid={masterbid} users={users} />
					))}
				</Stack>
			)}

			<BidsDrawerWrapper />
		</>
	);
};

export default AdminBidsView;
