import { Checkbox, Link, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ItemContainer, PaperContainer } from './styles';
import { onlyDateFormatter } from '../../../../utils';
import React from 'react';
import * as d3 from 'd3-format';
import { TableBox } from '../../../../components/UserDrawer/styles';
import { Link as RouterLink } from 'react-router-dom';

const BidSellerItem = (props) => {
	const { user, isSelected, handleCheck } = props;

	return (
		<PaperContainer $selected={isSelected}>
			<ItemContainer>
				<Checkbox checked={isSelected} onChange={handleCheck} />
				<Stack spacing={'4px'} direction={'column'} flexShrink={0} flex={1}>
					<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
						User
					</Typography>
					<Stack direction={'column'} spacing={'2px'}>
						<Link
							variant={'body2'}
							underline={'hover'}
							component={RouterLink}
							to={'/admin/users/' + user.id}
							target={'_blank'}
						>
							{user.fields['First Name']} {user.fields['Last Name']}
						</Link>
						<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
							{user.fields['Email']}
						</Typography>
					</Stack>
				</Stack>
				<Stack spacing={'4px'} direction={'column'} flex={2}>
					<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
						Inquiries
					</Typography>
					{user.fields?.['inquiries']?.length > 0 ? (
						<TableBox>
							<Table size="small" sx={{ tableLayout: 'fixed' }}>
								<TableHead>
									<TableRow>
										<TableCell padding={'none'}>Internal Status</TableCell>
										<TableCell>Block Size Ticket</TableCell>
										<TableCell padding={'none'} sx={{ width: '18%' }}>
											QTY
										</TableCell>
										<TableCell sx={{ width: '20%' }}>Price</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{user.fields?.['inquiries'].map((inquiry) => (
										<TableRow key={inquiry.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell padding={'none'}>{inquiry.fields['InternalStatus']}</TableCell>
											<TableCell>{d3.format('$,.0f')(inquiry.fields['Block Size Ticket'])}</TableCell>
											<TableCell padding={'none'}>{d3.format(',')(inquiry.fields['QTY'])}</TableCell>
											<TableCell>{d3.format(',')(inquiry.fields['Price'])}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableBox>
					) : (
						'-'
					)}
				</Stack>
				<Stack spacing={'4px'} direction={'column'} width={100} flexShrink={0}>
					<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
						Last Outreach
					</Typography>
					<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
						{user.fields.WSOutreach ? onlyDateFormatter(new Date(user.fields.WSOutreach)) : '-'}
					</Typography>
				</Stack>
				<Stack spacing={'4px'} direction={'column'} width={100} flexShrink={0}>
					<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
						Last Chat
					</Typography>
					<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
						{user.fields.LastChatDate ? onlyDateFormatter(new Date(user.fields.LastChatDate)) : '-'}
					</Typography>
				</Stack>
			</ItemContainer>
		</PaperContainer>
	);
};

export default BidSellerItem;
