import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, Grid, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getData } from 'country-list';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Formik } from 'formik';
import { initialValues, schema } from './onboarding-constants';
import { apiClient, endpoints } from '../../utils';
import FormFields from './FormFields';
import PageTitle from '../PageTitle';
import ThankYouBlock from '../ThankYouBlock';
import LoadingButton from '@mui/lab/LoadingButton';
import InquiryPreview from '../InquiryPreview';
import FormikErrorFocus from 'formik-error-focus';

const Onboarding = ({ defaultValues, locked, editing, inquiryData }) => {
	const { recId } = useParams();
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [isButtonLoading, setButtonLoading] = useState(false);
	const [isSuccess, setSuccess] = useState(false);

	useEffect(() => {
		if (window.location.pathname.includes('success') && !isSuccess) {
			navigate(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
		}
	}, []);

	const handleSubmit = async (values) => {
		setButtonLoading(true);

		const { uploadDocuments } = values;
		const newFiles = [...uploadDocuments].filter((file) => !file.id);
		const uploadedFiles = [...uploadDocuments].filter((file) => file.id);

		for (const uploadedFile of uploadedFiles) {
			const blob = await fetch(uploadedFile.url).then((r) => r.blob());
			const parts = [blob];
			const file = new File(parts, uploadedFile.filename, { ...uploadedFile });
			newFiles.push(file);
		}

		const formData = new FormData();
		newFiles.forEach((file) => {
			formData.append('files', file);
		});

		const payload = JSON.stringify({ id: recId, questionnaire: JSON.stringify(values) });

		try {
			await apiClient.post(endpoints.uploadTransactionFiles.replace('{recordId}', recId), formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			const resQuestionnaire = await apiClient.post(endpoints.submitQuestionnaireForm, payload, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (resQuestionnaire.status === 200) {
				setSuccess(true);

				if (!window.location.pathname.includes('success')) {
					navigate(window.location.pathname + '/success');
				}
			}
		} catch (err) {
			console.log('Onboarding.err', err);
		} finally {
			setButtonLoading(false);
		}
	};

	const countries = useMemo(() => getData().map((country) => ({ label: country.name, code: country.code })), []);

	return (
		<>
			<PageTitle
				isOnlyBreadcrumbs={true}
				breadcrumbs={[
					{
						title: 'Home',
						link: '/home',
					},
					{
						title: locked ? 'Shareholder Profile' : editing ? 'Edit Shareholder Profile' : 'Shareholder Profile',
					},
				]}
			/>
			<Formik
				enableReinitialize={true}
				initialValues={{ ...initialValues, ...defaultValues }}
				onSubmit={handleSubmit}
				validationSchema={schema}
				validateOnBlur={true}
			>
				{(formikProps) => {
					const { isSubmitting, setFieldValue, values, handleChange, errors, touched } = formikProps;
					const inquiryPreview = <InquiryPreview {...inquiryData} />;

					return (
						<Grid container spacing={3}>
							{!isMobile && (
								<Grid item xs={5}>
									{inquiryPreview}
								</Grid>
							)}
							{!isMobile && (
								<Grid item xs={1}>
									<Divider orientation={'vertical'} sx={{ width: 'calc(100% / 2)' }} />
								</Grid>
							)}
							{isSuccess ? (
								<Grid item xs={isMobile ? 12 : 6} marginTop={'60px'} marginBottom={'60px'}>
									<ThankYouBlock
										title={'Thank you for your response!'}
										description={''}
										buttonText={'home'}
										buttonLink={'/'}
									/>
								</Grid>
							) : (
								<Grid item xs={isMobile ? 12 : 6}>
									<Stack spacing={isMobile ? 3 : 6}>
										{!locked && (
											<Stack spacing={'10px'}>
												<Typography variant="h5">Shareholder Profile</Typography>
												<Typography variant="body2" color={'text.secondary'}>
													ShareWell | SaxonWeber and its designated agents and representatives shall maintain all
													information received from this authorization in a confidential manner according to Privacy of{' '}
													<Link
														underline={'hover'}
														href={'https://www.sec.gov/rules/final/34-42974.htm'}
														target={'_blank'}
													>
														Consumer Financial Information (Regulation S-P)
													</Link>
													.
												</Typography>
												<Typography variant="body2" color={'text.secondary'}>
													I hereby authorize ShareWell | SaxonWeber and its designated agents and representatives to
													share and retain any information, records and data received in connection with this
													authorization as required or advisable to comply with any applicable legal and regulatory
													obligations. I acknowledge that I have read this authorization and release, fully understand
													it, and voluntarily agree to its provisions.
												</Typography>
												<Typography variant="body2" color={'text.secondary'}>
													Please do your best to complete the the following fields accurately; which allows us to better
													understand your potential transactional preferences. You will have a chance to connect with
													your Relationship Manager to discuss pricing, process and market conditions once this
													information (and materials) have been collected.
												</Typography>
											</Stack>
										)}
										{isMobile && inquiryPreview}
										<Form autoComplete="off">
											<Stack spacing={isMobile ? 3 : 6}>
												<Box>
													<Grid container spacing={[3, '20px']}>
														<FormFields
															values={values}
															countries={countries}
															handleChange={handleChange}
															isSubmitting={isSubmitting}
															setFieldValue={setFieldValue}
															locked={locked}
															errors={errors}
															touched={touched}
														/>
														<FormikErrorFocus offset={0} align={'top'} focusDelay={0} formik={formikProps} />
													</Grid>
												</Box>
												{!locked && (
													<LoadingButton
														variant="contained"
														type="submit"
														sx={{ width: isMobile ? '100%' : '50%' }}
														size={'large'}
														loading={isButtonLoading}
														endIcon={<></>}
														loadingPosition={'end'}
													>
														Submit
													</LoadingButton>
												)}
											</Stack>
										</Form>
									</Stack>
								</Grid>
							)}
						</Grid>
					);
				}}
			</Formik>
		</>
	);
};

export default Onboarding;
