import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const EmptyContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-top: 100px;
	margin-bottom: 100px;

	@media (max-width: 600px) {
		margin-top: 60px;
		margin-bottom: 60px;
	}
`;
