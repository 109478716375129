import { Box, Grid, Stack, Typography } from '@mui/material';

const InfoBlock = ({ title, children, gridSpacing, ...props }) => {
	return (
		<Stack spacing={'20px'} sx={{ paddingBottom: 4, borderBottom: '1px solid', borderColor: 'divider' }} {...props}>
			<Typography variant={'h6'} color={'text.primary'}>
				{title}
			</Typography>
			<Box>
				<Grid container spacing={[3, gridSpacing ?? '20px']}>
					{children}
				</Grid>
			</Box>
		</Stack>
	);
};

export default InfoBlock;
