import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { apiClient, endpoints } from '../../../../utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { strings } from '../../../../utils/strings';

const DeleteDialog = (props) => {
	const { masterbid } = props;
	const navigate = useNavigate();
	const { open, handleClose } = props;

	const onDelete = async () => {
		if (masterbid.id) {
			try {
				await apiClient.delete(endpoints.getMasterBidById.replace('{id}', masterbid.id));
				toast.success(strings.successMessages.masterBid.deleted);

				const state = { preventScrollReset: true };
				state.deleteId = masterbid.id;

				navigate('/admin/bids' + window.location.search, {
					state: state,
				});
			} catch (err) {
				toast.error(strings.errorMessages.masterBid.delete);
			} finally {
				handleClose();
			}
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{'Delete master bid?'}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to delete the <b>{masterbid.fields.Name}</b> bid? This action cannot be undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color={'inherit'}>
					Cancel
				</Button>
				<Button onClick={onDelete} autoFocus color={'error'}>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;
