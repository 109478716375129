import {
	Box,
	Button,
	Drawer,
	Grid,
	IconButton,
	InputAdornment,
	LinearProgress,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { apiClient, endpoints } from '../../../../utils';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { initialValues, schema } from './inquiryForm.constants';
import { Label, valueColumns } from '../../../../styles/drawerComponents';
import CustomizedDropdown from '../../../../components/CustomizedDropdown';
import * as d3 from 'd3-latest';
import { StyledInput } from '../../../../styles/common.styles';
import { CurrencyBitcoin, DoneAll, MoreVert } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteDialog from '../DeleteDialog';
import { strings } from '../../../../utils/strings';
import { airtableConstants } from '../../../../utils/airtableConstants';

const MasterBidDrawer = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const defaultTheme = useTheme();

	const isNew = useMemo(() => id === 'new', [id]);
	const requiredFields = Object.keys(schema.fields);
	const [masterBid, setMasterBid] = useState(null);

	const [isLoading, setLoading] = useState(true);
	const [isSaving, setSaving] = useState(false);

	const [isDeleteOpen, setDeleteOpen] = useState(false);
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);

	useEffect(() => {
		if (id && !isNew) {
			loadBid();
		} else {
			setMasterBid(null);
			setLoading(false);
		}
	}, [id, isNew]);

	const loadBid = async () => {
		try {
			setLoading(true);

			const res = await apiClient.get(endpoints.getMasterBidById.replace('{id}', id));

			//check if "Commission Pct" is set and multiply by 100
			if (res.data.fields['Commission Pct']) {
				res.data.fields['Commission Pct'] = res.data.fields['Commission Pct'] * 100;
			}

			if (res.data) {
				setMasterBid(res.data);
			}
		} catch (err) {
			toast.error(strings.errorMessages.masterBid.load);
			onClose();
		} finally {
			setLoading(false);
		}
	};

	const onClose = useCallback(
		(id) => {
			const state = { preventScrollReset: true };

			if (typeof id === 'string') {
				state.updateObjectId = id;
			}

			navigate('/admin/bids' + window.location.search, {
				state: state,
			});

			setMasterBid(null);
			setSaving(false);
		},
		[navigate]
	);

	const handleCreate = async (values) => {
		try {
			setSaving(true);

			//check if "Commission Pct" is set and divide by 100
			await apiClient.post(endpoints.getMasterBids, {
				...values,
				'Commission Pct': values['Commission Pct'] ? values['Commission Pct'] / 100 : null,
			});
			toast.success(strings.successMessages.masterBid.saved);
			onClose('new');
		} catch (err) {
			toast.error(strings.errorMessages.masterBid.save);
		} finally {
			setSaving(false);
		}
	};

	const handleUpdate = async (values) => {
		if (!id) return toast.error(strings.errorMessages.masterBid.load);
		try {
			setSaving(true);

			//check if "Commission Pct" is set and divide by 100
			await apiClient.put(endpoints.getMasterBidById.replace('{id}', id), {
				...values,
				'Commission Pct': values['Commission Pct'] ? values['Commission Pct'] / 100 : null,
			});
			toast.success(strings.successMessages.masterBid.saved);
			onClose(id);
		} catch (err) {
			toast.error(strings.errorMessages.masterBid.update);
		} finally {
			setSaving(false);
		}
	};

	return (
		<Drawer anchor={'right'} open={Boolean(id)} elevation={1} onClose={onClose}>
			<Box width={600} height={'100%'}>
				{isLoading && <LinearProgress />}
				{!isLoading && (
					<Formik
						enableReinitialize={true}
						initialValues={{ ...initialValues, ...(masterBid ? masterBid.fields : {}) }}
						validationSchema={schema}
						validateOnBlur={true}
						onSubmit={isNew ? handleCreate : handleUpdate}
					>
						{({ setFieldValue, values, dirty, resetForm, touched, errors }) => {
							const getErrorMessage = (fieldName) => {
								if (touched && errors && Boolean(touched[fieldName] && errors[fieldName])) {
									return errors[fieldName];
								}

								return undefined;
							};

							return (
								<Form autoComplete="off" style={{ height: '100%' }}>
									<Stack height={'100%'}>
										<Stack
											direction={'row'}
											spacing={2}
											p={3}
											pb={2}
											alignItems={'center'}
											sx={{ borderBottom: 1, borderColor: 'divider' }}
										>
											<CurrencyBitcoin fontSize="large" color="primary" />
											<Typography variant={'h6'} sx={{ flex: 1, wordBreak: 'break-word' }}>
												{isNew ? 'New Master Bid' : values.Name}
											</Typography>
											{!isNew && (
												<IconButton
													size="small"
													onClick={(e) => {
														setMenuAnchorEl(e.currentTarget);
													}}
												>
													<MoreVert sx={{ color: 'text.secondary' }} />
												</IconButton>
											)}
										</Stack>
										<Stack overflow={'auto'} flex={1} p={3} spacing={3}>
											<Stack spacing={2} sx={{ paddingBottom: 3, borderBottom: '1px solid', borderColor: 'divider' }}>
												<Typography fontWeight={500}>Internal data</Typography>
												<Box>
													<Grid container spacing={[3, '4px']}>
														<Label required={requiredFields.includes('Name')}>Master Bid Name</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																name="Name"
																onChange={(e) => {
																	setFieldValue('Name', e.currentTarget.value);
																}}
																fullWidth
															/>
														</Grid>

														<Label>Internal Notes</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																name="Notes"
																multiline
																rows={6}
																onChange={(e) => {
																	setFieldValue('Notes', e.currentTarget.value);
																}}
																fullWidth
															/>
														</Grid>

														<Label required={requiredFields.includes('Buyer')}>Buyer</Label>
														<Grid item xs={valueColumns}>
															<CustomizedDropdown
																fieldName={'Buyer'}
																isUsers={true}
																isMultiple={false}
																error={getErrorMessage('Buyer')}
															/>
														</Grid>

														<Label required={requiredFields.includes('Block Min')}>Block Size (min)</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																name="Block Min"
																type={'number'}
																onChange={(e) => {
																	setFieldValue(
																		'Block Min',
																		Boolean(e.currentTarget.value) ? Number(e.currentTarget.value) : ''
																	);
																}}
																fullWidth
															/>
														</Grid>

														<Label required={requiredFields.includes('Block Max')}>Block size (max)</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																name="Block Max"
																type={'number'}
																onChange={(e) => {
																	setFieldValue(
																		'Block Max',
																		Boolean(e.currentTarget.value) ? Number(e.currentTarget.value) : ''
																	);
																}}
																fullWidth
															/>
														</Grid>

														<Label required={requiredFields.includes('Market Visibility')}>Market Visibility</Label>
														<Grid item xs={valueColumns}>
															<CustomizedDropdown
																fieldName={'Market Visibility'}
																options={airtableConstants.BID.MARKET_VISIBILITY}
																error={getErrorMessage('Market Visibility')}
															/>
														</Grid>
													</Grid>
												</Box>
											</Stack>
											<Stack spacing={2}>
												<Typography fontWeight={500}>User-facing data</Typography>
												<Box>
													<Grid container spacing={[3, '4px']}>
														<Label required={requiredFields.includes('Company')}>Company</Label>
														<Grid item xs={valueColumns}>
															<CustomizedDropdown
																fieldName={'Company'}
																isCompanies={true}
																isMultiple={false}
																error={getErrorMessage('Company')}
															/>
														</Grid>

														<Label required={requiredFields.includes('Valid To')}>Valid To</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																fullWidth
																value={
																	values['Valid To']
																		? typeof values['Valid To'] === 'object'
																			? d3.timeFormat('%Y-%m-%d')(values['Valid To'])
																			: new Date(values['Valid To']).toISOString().slice(0, 10)
																		: ''
																}
																name="Valid To"
																type="date"
																InputProps={{
																	inputProps: { min: isNew ? new Date().toISOString().split('T')[0] : undefined },
																}}
															/>
														</Grid>

														<Label required={requiredFields.includes('Gross Price')}>Gross Price</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																name="Gross Price"
																type={'number'}
																onChange={(e) => {
																	setFieldValue(
																		'Gross Price',
																		Boolean(e.currentTarget.value) ? Number(e.currentTarget.value) : ''
																	);
																}}
																fullWidth
															/>
														</Grid>

														<Label required={requiredFields.includes('Commission Pct')}>Commission %</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																fullWidth
																name="Commission Pct"
																type={'number'}
																onChange={(e) => {
																	const value = Boolean(e.currentTarget.value) ? Number(e.currentTarget.value) : '';

																	setFieldValue('Commission Pct', value);
																	setFieldValue('Commission per Share', 0);
																}}
																InputProps={{
																	startAdornment: <InputAdornment position="start">%</InputAdornment>,
																}}
															/>
														</Grid>

														<Label required={requiredFields.includes('Commission per Share')}>
															Commission per Share
														</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																fullWidth
																name="Commission per Share"
																type={'number'}
																onChange={(e) => {
																	const value = Boolean(e.currentTarget.value) ? Number(e.currentTarget.value) : '';

																	setFieldValue('Commission per Share', value);
																	setFieldValue('Commission Pct', 0);
																}}
																InputProps={{
																	startAdornment: <InputAdornment position="start">$</InputAdornment>,
																}}
															/>
														</Grid>

														<Label required={requiredFields.includes('Admin Fee')}>Admin Fee</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																name="Admin Fee"
																type={'number'}
																onChange={(e) => {
																	setFieldValue(
																		'Admin Fee',
																		Boolean(e.currentTarget.value) ? Number(e.currentTarget.value) : ''
																	);
																}}
																fullWidth
															/>
														</Grid>

														<Label>Buyer Exercise</Label>
														<Grid item xs={valueColumns}>
															<CustomizedDropdown
																fieldName={'Buyer Exercise'}
																options={airtableConstants.BID.BUYER_EXERCISE}
															/>
														</Grid>

														<Label>Bid Type</Label>
														<Grid item xs={valueColumns}>
															<CustomizedDropdown fieldName={'Bid Type'} options={airtableConstants.BID.TYPE} />
														</Grid>

														<Label required={requiredFields.includes('Structure')}>Structure</Label>
														<Grid item xs={valueColumns}>
															<CustomizedDropdown
																error={getErrorMessage('Structure')}
																fieldName={'Structure'}
																isMultiple={true}
																options={airtableConstants.INQUIRY.STRUCTURE}
															/>
														</Grid>

														<Label>Notes To Seller</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																name="Notes To Seller"
																multiline
																rows={6}
																onChange={(e) => {
																	setFieldValue('Notes To Seller', e.currentTarget.value);
																}}
																fullWidth
															/>
														</Grid>

														<Label required={requiredFields.includes('Min Bid Size')}>Min Bid Size</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																name="Min Bid Size"
																type={'number'}
																onChange={(e) => {
																	setFieldValue(
																		'Min Bid Size',
																		Boolean(e.currentTarget.value) ? Number(e.currentTarget.value) : ''
																	);
																}}
																fullWidth
															/>
														</Grid>

														<Label required={requiredFields.includes('Max Bid Size')}>Max Bid Size</Label>
														<Grid item xs={valueColumns}>
															<StyledInput
																name="Max Bid Size"
																type={'number'}
																onChange={(e) => {
																	setFieldValue(
																		'Max Bid Size',
																		Boolean(e.currentTarget.value) ? Number(e.currentTarget.value) : ''
																	);
																}}
																fullWidth
															/>
														</Grid>
													</Grid>
												</Box>
											</Stack>
										</Stack>

										<Stack
											sx={{ borderTop: 1, borderColor: 'divider' }}
											justifyContent={'flex-end'}
											p={2}
											direction={'row'}
											spacing={2}
										>
											<Button
												size={'small'}
												variant={'text'}
												color="inherit"
												onClick={() => {
													resetForm();
													onClose();
												}}
											>
												Cancel
											</Button>
											<LoadingButton
												size={'small'}
												variant={'contained'}
												startIcon={<DoneAll />}
												type={'submit'}
												disabled={!dirty}
												loading={isSaving}
												loadingPosition={'start'}
											>
												{isNew ? 'Create Master bid' : 'Save changes'}
											</LoadingButton>
										</Stack>
									</Stack>
								</Form>
							);
						}}
					</Formik>
				)}
			</Box>
			<Menu
				anchorEl={menuAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={Boolean(menuAnchorEl)}
				onClose={() => {
					setMenuAnchorEl(null);
				}}
			>
				<MenuItem
					sx={{ color: defaultTheme.palette.error.main }}
					onClick={() => {
						setDeleteOpen(true);
						setMenuAnchorEl(null);
					}}
				>
					Delete
				</MenuItem>
			</Menu>
			{masterBid && (
				<DeleteDialog
					masterbid={masterBid}
					open={isDeleteOpen}
					handleClose={() => {
						setDeleteOpen(false);
						setMasterBid(null);
						setSaving(false);
					}}
				/>
			)}
		</Drawer>
	);
};

export default MasterBidDrawer;
