import PageTitle from '../../components/PageTitle';
import { Button, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EmptyContainer } from './dealsPageView.styles';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthContext, useLoaderContext } from '../../context';
import { useEffect } from 'react';
import DealCard from '../../components/DealCard';
import BidCard from './components/BidCard';

const DealsPageView = () => {
	const { user, inquiries, reloadUserData, bids } = useAuthContext();
	const { isLoading } = useLoaderContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		if (!isLoading) {
			reloadUserData();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Home',
					},
				]}
				title={'Dashboard'}
			/>

			{isLoading && (
				<Stack spacing={isMobile ? 2 : 4} width={'100%'}>
					<Skeleton variant="rectangular" height={isMobile ? 240 : 310} />
					<Skeleton variant="rectangular" height={isMobile ? 240 : 310} />
					<Skeleton variant="rectangular" height={isMobile ? 240 : 310} />
				</Stack>
			)}

			{!isLoading && inquiries.length === 0 && (
				<EmptyContainer>
					<Stack spacing={isMobile ? 3 : 4} alignItems={'center'}>
						<img src={'/illustrations/dark/notFound.svg'} style={{ height: isMobile ? 160 : 252 }} alt="No activity" />
						<Stack spacing={2} alignItems={'center'}>
							<Typography variant={'h6'}>You have no activity.</Typography>
							<Button
								size={isMobile ? 'large' : 'medium'}
								variant={'contained'}
								component={RouterLink}
								to={'/companies'}
							>
								browse companies
							</Button>
						</Stack>
					</Stack>
				</EmptyContainer>
			)}

			{!isLoading && inquiries.length > 0 && (
				<Stack spacing={isMobile ? 2 : 4}>
					{[...bids.filter((bid) => bid.fields.Answer === 'Applied'), ...inquiries]
						.sort((a, b) => new Date(b.fields['Created At']) - new Date(a.fields['Created At']))
						.map((item, i) => {
							if ('Valid To' in item.fields) {
								return <BidCard key={i} bid={item} />;
							} else if ('Action' in item.fields) {
								return <DealCard key={i} inquiry={item} userContext={user} />;
							}

							return <></>;
						})}
				</Stack>
			)}
		</>
	);
};

export default DealsPageView;
