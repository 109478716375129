import PageTitle from '../../components/PageTitle';
import {
	Autocomplete,
	Box,
	Card,
	Checkbox,
	Chip,
	Grid,
	Link,
	Pagination,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { apiClient, endpoints, isValidNumber } from '../../utils';
import { toast } from 'react-toastify';
import { NotFoundContainer } from '../../components/CompanyList/companyList.styles';
import { usePrivateAppContext } from '../../context';
import * as d3 from 'd3-latest';
import LoadingButton from '@mui/lab/LoadingButton';
import { SelectedToolbar } from './style';
import { strings } from '../../utils/strings';
import { airtableConstants } from '../../utils/airtableConstants';
import { visuallyHidden } from '@mui/utils';

const blockSizes = [
	{ valueFrom: 0, valueTo: 500000, label: '< $500K' },
	{ valueFrom: 500000, valueTo: 1000000, label: '$500k - $1m' },
	{ valueFrom: 1000000, valueTo: 5000000, label: '$1m - $5m' },
	{ valueFrom: 5000000, valueTo: null, label: '> $5m' },
];

const MarketPageView = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isBuyingLoading, setBuyingLoading] = useState(false);
	const navigate = useNavigate();
	const { setCurrent } = usePrivateAppContext();

	const [isReadyToLoadInquiries, setReadyToLoadInquiries] = useState(false);
	const [isFirstLoading, setFirstLoading] = useState(true);

	const { companies } = usePrivateAppContext();
	const [selectedCompanies, setSelectedCompanies] = useState([]);
	const [selectedBlockSize, setSelectedBlockSize] = useState(null);
	const [selectedBlockType, setSelectedBlockType] = useState(null);

	const [page, setPage] = useState(1);
	const rowsPerPage = 50;
	const [totalDataLength, setTotalDataLength] = useState(0);
	const [sortBy, setSortBy] = useState('Created At');
	const [sortDirection, setSortDirection] = useState('desc');

	const [inquiries, setInquiries] = useState([]);
	const [selectedInquiries, setSelectedInquiries] = useState([]);

	useEffect(() => {
		// firstly load parameters
		const companyParam = searchParams.get('company');
		const blockSizeParam = searchParams.get('blockSize');
		const blockTypeParam = searchParams.get('blockType');

		const pageParam = searchParams.get('page');
		const sortByParam = searchParams.get('sortBy');
		const sortDirectionParam = searchParams.get('sortDirection');

		const isPageParamExist = Boolean(pageParam && pageParam.trim());
		const isSortByParamExist = Boolean(sortByParam && sortByParam.trim());
		const isSortDirectionParamExist = Boolean(sortDirectionParam && sortDirectionParam.trim());

		if (companies.length) {
			if (companyParam && companyParam.trim()) {
				const companyNames = companyParam.trim().split(',');

				const foundCompanies = companyNames.reduce((accumulator, currentValue) => {
					const found = companies.find((company) => company['Legal Name'] === currentValue);
					if (found) {
						return [...accumulator, found];
					}

					return accumulator;
				}, []);

				setSelectedCompanies(foundCompanies);
			} else {
				setSelectedCompanies([]);
			}
		}

		if (blockSizeParam && blockSizeParam.trim()) {
			const trimmedParams = blockSizeParam.trim();
			setSelectedBlockSize(blockSizes.find((item) => trimmedParams.includes(item.label)));
		} else {
			setSelectedBlockSize(null);
		}

		if (blockTypeParam && blockTypeParam.trim()) {
			const trimmedParams = blockTypeParam.trim();
			setSelectedBlockType(trimmedParams);
		} else {
			setSelectedBlockType(null);
		}

		if (isPageParamExist) {
			setPage(Number(pageParam));
		} else {
			setPage(1);
		}

		if (isSortByParamExist) {
			setSortBy(sortByParam.trim());
		} else {
			setSortBy('Created At');
		}

		if (isSortDirectionParamExist) {
			setSortDirection(sortDirectionParam.trim());
		} else {
			setSortDirection('desc');
		}

		!isReadyToLoadInquiries &&
			setTimeout(() => {
				setReadyToLoadInquiries(true);
			}, 300);
	}, [searchParams, companies]);

	useEffect(() => {
		//secondly load inquiries
		const loadInquiries = async () => {
			try {
				const res = await apiClient.post(endpoints.getAnonymousInquiries, {
					companyIds: selectedCompanies.map((company) => company['Record Id']),
					blockType: selectedBlockType,
					blockSize: selectedBlockSize,
					page: page,
					pageSize: rowsPerPage,
					sort: sortBy,
					sortDirection: sortDirection,
				});
				return res.data ?? [];
			} catch (err) {
				toast.error(strings.errorMessages.inquiry.loadMany);
				return [];
			} finally {
				isFirstLoading && setFirstLoading(false);
			}
		};

		if (isReadyToLoadInquiries) {
			loadInquiries().then((res) => {
				setInquiries(res?.data);
				setTotalDataLength(res?.total ?? 0);
			});
		}
	}, [selectedCompanies, selectedBlockSize, isReadyToLoadInquiries]);

	const onSelectOne = (event, inquiryObj) => {
		if (event.target.checked) {
			setSelectedInquiries((prevState) => [...prevState, inquiryObj]);
		} else {
			setSelectedInquiries((prevState) => prevState.filter((item) => item.id !== inquiryObj.id));
		}
	};

	const isSelected = (inquiryObj) => Boolean(selectedInquiries.find((selected) => selected.id === inquiryObj.id));

	const handleLogInterest = async (selectedInquiries = []) => {
		if (selectedInquiries.length) {
			setCurrent({ selectedInquiries: selectedInquiries });
			navigate(`/company/${selectedInquiries[0].fields['Company Id'][0]}/send-inquiry`);
		}
	};

	const buyInquiries = () => {
		setBuyingLoading(true);
		handleLogInterest(selectedInquiries);
		setSelectedInquiries([]);
		setBuyingLoading(false);
	};

	const onPageChange = (event, newPage) => {
		if (newPage < 1) {
			searchParams.set('page', 1);
		} else {
			searchParams.set('page', newPage);
		}
		setSearchParams(searchParams);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = sortBy === property && sortDirection === 'asc';
		searchParams.set('sortDirection', isAsc ? 'desc' : 'asc');
		searchParams.set('sortBy', property);
		searchParams.set('page', 1);
		setSearchParams(searchParams);
	};

	const renderHeadCell = (name, label, sx = {}, align) => {
		return (
			<TableCell
				sx={{ color: 'text.secondary', ...sx }}
				sortDirection={sortBy === name ? sortDirection : false}
				align={align}
			>
				<TableSortLabel
					active={sortBy === name}
					direction={sortBy === name ? sortDirection : 'asc'}
					onClick={(e) => handleRequestSort(e, name)}
				>
					{label}
					{sortBy === name ? (
						<Box component="span" sx={visuallyHidden}>
							{sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
						</Box>
					) : null}
				</TableSortLabel>
			</TableCell>
		);
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Market',
					},
				]}
				title={'All inquiries'}
			/>
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Autocomplete
							multiple
							options={companies}
							getOptionLabel={(option) => option['Legal Name']}
							isOptionEqualToValue={(option, value) => {
								return option['Record Id'] === value['Record Id'];
							}}
							value={selectedCompanies}
							onChange={(_, value) => {
								if (value.length) {
									searchParams.set('company', value.map((value) => value['Legal Name']).join(','));
								} else {
									searchParams.delete('company');
								}
								searchParams.set('page', 1);
								setSearchParams(searchParams);
							}}
							renderInput={(params) => <TextField {...params} label="Company" placeholder="Select companies" />}
						/>
					</Grid>
					<Grid item xs={5}>
						<Autocomplete
							options={blockSizes}
							getOptionLabel={(option) => option.label}
							isOptionEqualToValue={(option, value) => option.label === value.label}
							value={selectedBlockSize}
							onChange={(_, value) => {
								if (value) {
									searchParams.set('blockSize', value.label);
								} else {
									searchParams.delete('blockSize');
								}
								searchParams.set('page', 1);
								setSearchParams(searchParams);
							}}
							renderInput={(params) => <TextField {...params} label="Block size" placeholder="Select Block size" />}
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							options={airtableConstants.USER.USER_STAGE_GLOBAL}
							value={selectedBlockType}
							onChange={(_, value) => {
								if (value) {
									searchParams.set('blockType', value);
								} else {
									searchParams.delete('blockType');
								}
								searchParams.set('page', 1);
								setSearchParams(searchParams);
							}}
							renderInput={(params) => <TextField {...params} label="Block type" placeholder="Select block type" />}
						/>
					</Grid>
					<Grid item xs={12}>
						{isFirstLoading && <Skeleton variant="rectangular" height={400} />}
						{!isFirstLoading && !inquiries && (
							<NotFoundContainer>
								<Stack spacing={3} alignItems={'center'}>
									<img src={'/illustrations/dark/notFound.svg'} style={{ height: 200 }} alt="Not found" />
									<Stack spacing={1} alignItems={'center'}>
										<Typography variant={'h5'}>No inquiries found</Typography>
										<Typography variant={'body2'} textAlign={'center'}>
											We couldn't find what you searched for. Change your search criteria.
										</Typography>
									</Stack>
								</Stack>
							</NotFoundContainer>
						)}
						{!isFirstLoading && inquiries?.length > 0 && (
							<Stack spacing={0}>
								<TableContainer component={Card}>
									<SelectedToolbar>
										{selectedInquiries.length ? (
											<Typography variant={'body2'}>Selected {selectedInquiries.length} inquiries</Typography>
										) : (
											<Typography variant={'body2'}>Select inquiries to express interest</Typography>
										)}
										<LoadingButton
											variant={'contained'}
											size={'medium'}
											disabled={selectedInquiries.length === 0}
											onClick={buyInquiries}
											loading={isBuyingLoading}
											endIcon={<></>}
											loadingPosition={'end'}
										>
											{'Express interest'}
										</LoadingButton>
									</SelectedToolbar>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell style={{ width: 74 }} />
												{renderHeadCell('Company Name', 'Company')}
												{renderHeadCell('blockType', 'Block type')}
												{renderHeadCell('Action', 'Bid/Ask')}
												{renderHeadCell('Public Status', 'Status')}
												{renderHeadCell('Type', 'Share Type')}
												{renderHeadCell('Transaction type', 'Transaction Type')}
												{renderHeadCell('Price', 'Price', null, 'right')}
												{renderHeadCell('QTY', 'QTY', null, 'right')}
												{renderHeadCell('Block Size Ticket', 'Block size', null, 'right')}
											</TableRow>
										</TableHead>
										<TableBody>
											{inquiries.map((inquiry) => {
												const {
													fields: {
														Action,
														'Block Size Ticket': blockSize,
														QTY,
														Price,
														'Company Name': companyName,
														'Company Id': companyId,
														'Public Status': status,
														Type: shareType,
														'Transaction type': trxType,
														isInst,
													},
													id,
												} = inquiry;
												const isItemSelected = isSelected(inquiry);
												const isItemDisabled = selectedInquiries.length
													? selectedInquiries?.[0].fields['Company Id']?.[0] !== inquiry?.fields['Company Id'][0]
													: false;

												return (
													<TableRow
														key={id}
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														onClick={(event) => onSelectOne(event, inquiry)}
														role="checkbox"
														aria-checked={isItemSelected}
														tabIndex={-1}
														selected={isItemSelected}
													>
														<TableCell style={{ width: 50 }} component="td" scope="row">
															{isItemDisabled ? (
																<Tooltip
																	title="You can't choose inquirires of multiple companies"
																	placement="right"
																	arrow={true}
																>
																	<span>
																		<Checkbox disabled />
																	</span>
																</Tooltip>
															) : (
																<Checkbox color="primary" checked={isItemSelected} />
															)}
														</TableCell>
														<TableCell component="td" scope="row">
															<Link
																underline={'hover'}
																component={RouterLink}
																target={'_blank'}
																to={'/company/' + companyId}
															>
																{companyName}
															</Link>
														</TableCell>
														<TableCell component="td" scope="row">
															{isInst ? (
																<Chip label="Institutional" color={'secondary'} size="small" />
															) : (
																<Chip label="Retail" color={'info'} size="small" />
															)}
														</TableCell>
														<TableCell component="td" scope="row">
															{Action === 'sell' ? (
																<Chip label="Ask" color={'warning'} size="small" />
															) : (
																<Chip label="Bid" color={'success'} size="small" />
															)}
														</TableCell>
														<TableCell component="td" scope="row">
															{status}
														</TableCell>
														<TableCell component="td" scope="row">
															{shareType}
														</TableCell>
														<TableCell component="td" scope="row">
															{trxType}
														</TableCell>
														<TableCell component="td" scope="row" style={{ textAlign: 'right' }}>
															{isValidNumber(Price) ? d3.format('$,.2f')(Price) : ''}
														</TableCell>
														<TableCell component="td" scope="row" style={{ textAlign: 'right' }}>
															{isValidNumber(QTY) ? d3.format(',.0f')(QTY) : ''}
														</TableCell>
														<TableCell component="td" scope="row" style={{ textAlign: 'right' }}>
															{isValidNumber(blockSize) ? d3.format('$,.2f')(blockSize) : ''}
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
								<Stack alignItems={'center'} pt={1}>
									<Pagination count={Math.ceil(totalDataLength / rowsPerPage)} page={page} onChange={onPageChange} />
								</Stack>
							</Stack>
						)}
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default MarketPageView;
