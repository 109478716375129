import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3-latest';
import { useResizeObserver } from '../../../../hooks';
import { Box, Card, CardContent, CardHeader, Stack, useMediaQuery, useTheme } from '@mui/material';
import { formatValuation } from '../../../../utils';
import { useAuthContext } from '../../../../context';
import CustomToggleButton from '../../../../components/CustomToggleButton';

const baselineOpacity = 0.5;
const dateFormat = d3.timeFormat('%b-%y');
const dateFormatDay = d3.timeFormat('%b %d %Y');
const curve = d3.curveCatmullRom;

function PriceChart({ priceData, overviewData, dimensions }) {
	const svgRef = useRef();
	const wrapperRef = useRef();
	const wrapDim = useResizeObserver(wrapperRef);
	const { user } = useAuthContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { height, margin = {} } = dimensions;

	const transDuration = 500;
	const locked = useRef(null);

	const [max, setMax] = useState(null);
	const relaxFactor = 1.2;
	const [state, setState] = useState({ showValuation: false });

	const toggleValuation = () => {
		setState((state) => ({ ...state, showValuation: !state.showValuation }));
	};

	//on user change set max
	useEffect(() => {
		if (!user || !priceData) return;
		const max = user.instie ? d3.max(priceData, (d) => d.high) : d3.max(priceData, (d) => d.retail);
		setMax(max);
	}, [user, priceData]);

	// will be called initially and on every data change
	useEffect(() => {
		const svg = d3.select(svgRef.current);
		if (!wrapDim || !user) return;

		let width = wrapDim.width;
		// const mobileView = width > 768 ? false : true;

		svg.attr('height', height).attr('width', width).style('overflow', 'overlay');

		const axisGroup = svg.select('.axis');
		const lineGroup = svg.select('.lines');
		const annotationsGroup = svg.select('.annotations');
		const hoverGroup = svg.select('.hover-group');

		//latest price data
		const lph = priceData[priceData.length - 1];
		const sharesIssued = lph.sharesIssued;

		const dateExtent = d3.extent(priceData, (d) => d.date);
		let selectedIndex = Math.floor(priceData.length / 2);

		var startDate = new Date(dateExtent[0].getTime());
		startDate.setDate(startDate.getDate() - 15);

		var endDate = new Date(dateExtent[1].getTime());
		endDate.setDate(endDate.getDate() + 15);

		const x = d3
			.scaleUtc()
			.domain([startDate, endDate])
			.range([margin.left, width - margin.right])
			.clamp(true);

		const xAxis = (g) =>
			g
				.attr('transform', `translate(0,${height - margin.bottom})`)
				.call(d3.axisBottom(x).tickFormat(dateFormat).tickSizeOuter(0));

		axisGroup.select('.x-axis').call(xAxis);

		const y = d3
			.scaleLinear()
			.domain([0, max * relaxFactor])
			.range([height - margin.bottom, margin.top]);

		if (state.showValuation) {
			y.domain(y.domain().map((d) => d * sharesIssued));
		}

		function formatTick(d) {
			// const s = d3.format('.2f')(d);
			const s = state.showValuation ? formatValuation(d) : d3.format('.2f')(d);
			return this.parentNode.nextSibling ? `\xa0${s}` : `$${s}`;
		}

		const yAxis = (g) =>
			g
				.attr('transform', `translate(${margin.left - 20},0)`)
				.call(
					d3
						.axisRight(y)
						.tickSize(width - margin.right - margin.left)
						.tickFormat(formatTick)
						.ticks(6)
				)
				.call((g) => g.select('.domain').remove())
				// .call((g) => g.selectAll('text').attr('text-anchor', 'end').attr('dx', 20).attr('dy', -40))
				.call((g) =>
					g.selectAll('.tick:not(:first-of-type) line').attr('stroke-opacity', 0.3).attr('stroke-dasharray', '3,3')
				)
				.call((g) => g.selectAll('text').attr('text-anchor', 'end').attr('x', 4).attr('dx', 16).attr('dy', -6));

		axisGroup.select('.y-axis').call(yAxis);

		const yAxisRight = (g) =>
			g
				.attr('transform', `translate(${width - margin.right + 20},0)`)
				.call(d3.axisRight(y).tickSize(0).tickFormat(formatTick).ticks(6))
				.call((g) => g.select('.domain').remove())
				.call((g) => g.selectAll('.tick text').attr('text-anchor', 'end').attr('x', 12).attr('dy', -5));

		axisGroup.select('.y-axis-right').call(yAxisRight);

		const line = d3
			.line()
			.defined((d) => Number.isFinite(d.value) && d.date !== null)
			.x((d) => x(d.date))
			.y((d) => y(state.showValuation ? d.value * sharesIssued : d.value))
			.curve(curve);

		const area = d3
			.area()
			.defined((d) => d.ask && d.bid)
			.x((d) => x(d.date))
			.y1((d) => y(state.showValuation ? d.ask * sharesIssued : d.ask))
			.y0((d) => y(state.showValuation ? d.bid * sharesIssued : d.bid))
			.curve(curve);

		const areaInstitutional = d3
			.area()
			.defined((d) => d.high && d.low)
			.x((d) => x(d.date))
			.y1((d) => y(state.showValuation ? d.high * sharesIssued : d.high))
			.y0((d) => y(state.showValuation ? d.low * sharesIssued : d.low))
			.curve(curve);

		const series = user.instie
			? ['high', 'low', 'trading']
			: ['par', 'bid', 'ask', 'market', 'institutional', 'retail'];

		const colors = {
			bid: d3.schemeTableau10[4],
			ask: d3.schemeTableau10[2],
			par: d3.schemeTableau10[9],
			institutional: d3.schemeTableau10[0],
			market: d3.schemeTableau10[1],
			retail: d3.schemeTableau10[6],
			high: d3.schemeTableau10[4],
			low: d3.schemeTableau10[2],
			trading: d3.schemeTableau10[9],
		};

		lineGroup.select('.spread-area').attr('d', () => (user.instie ? areaInstitutional(priceData) : area(priceData)));

		const nonDotted = ['par', 'bid', 'ask', 'high', 'low', 'trading'];

		series
			.filter((d) => d !== 'par')
			.forEach((d) => {
				lineGroup
					.select(`.series-line-${d}`)
					.attr('stroke', colors[d])
					.attr('stroke-width', 2.5)
					.attr('fill', 'none')
					.attr('stroke-dasharray', nonDotted.includes(d) ? null : '7 3')
					.attr('opacity', nonDotted.includes(d) ? 1 : 0.5)
					.attr(
						'd',
						line(
							priceData.map((item) => {
								return { date: item.date, value: item[d] };
							})
						)
					);
			});

		if (!user.instie) {
			lineGroup
				.select('.series-line-par')
				.attr('stroke', colors['par'])
				.attr('stroke-width', 2.5)
				.attr(
					'd',
					line(
						priceData.map((item) => {
							return { date: item.date, value: item['par'] };
						})
					)
				);
		} else {
			lineGroup
				.select('.series-line-currentlyTrading')
				.attr('stroke', colors['currentlyTrading'])
				.attr('stroke-width', 2.5)
				.attr(
					'd',
					line(
						priceData
							.filter((item) => item !== null && item.date !== null && item['currentlyTrading'] !== null)
							.map((item) => {
								return { date: item.date, value: item['currentlyTrading'] };
							})
					)
				);
		}

		function legendHighlight(event) {
			let d = event.target.getAttribute('data-series');
			// console.log('legendHighlight', d, event);
			if (d === locked.current) {
				legendDiv.selectAll('.legend-row').transition().duration(transDuration).style('opacity', 1);
				lineGroup.selectAll('.series-line').transition().duration(transDuration).style('opacity', baselineOpacity);

				lineGroup
					.selectAll('.series-line.series-line-bid,.series-line.series-line-ask,.series-line.series-line-par')
					.transition()
					.duration(transDuration)
					.style('opacity', 1);

				hoverGroup
					.selectAll('.hover-circle')
					.transition()
					.duration(transDuration)
					.style('opacity', 1)
					.attr('stroke-width', 0)
					.attr('r', 3);

				hoverGroup.selectAll('text').transition().duration(transDuration).attr('opacity', 0).remove();

				// locked = null;
				locked.current = null;
				return;
			}
			// locked = d;
			locked.current = d;

			// console.log('legendHighlight called: ', locked);

			legendDiv.selectAll('.legend-row').transition().duration(transDuration).style('opacity', 0.2);
			legendDiv.select(`.legend-row.legend-row-${d}`).transition().duration(transDuration).style('opacity', 1);
			lineGroup.selectAll('.series-line').transition().duration(transDuration).style('opacity', 0.2);
			lineGroup.select(`.series-line.series-line-${d}`).transition().duration(transDuration).style('opacity', 1);
			hoverGroup.selectAll('.hover-circle').transition().duration(transDuration).style('opacity', 0);

			hoverGroup
				.select(`.hover-circle.hover-circle-${d}`)
				.transition()
				.duration(transDuration)
				.style('opacity', 1)
				.attr('stroke-width', 2)
				.attr('r', 5);

			hoverLine.transition().duration(transDuration).attr('y2', margin.top);

			//remove existing hover values, then add new:
			hoverGroup.selectAll('text').remove();
			let text = hoverGroup
				.append('text')
				.attr('font-size', 14)
				.attr('x', x(priceData[selectedIndex].date))
				.attr('y', y(priceData[selectedIndex][locked.current]))
				.attr('dx', 10)
				.attr('dy', -10)
				.attr('fill', 'white')
				.text(
					() =>
						`${d3.format('$.2f')(priceData[selectedIndex][locked.current])} on ${dateFormatDay(
							priceData[selectedIndex].date
						)}`
				);

			text.attr('opacity', 0).transition().duration(transDuration).attr('opacity', 1);

			text
				.clone(true)
				.lower()
				.attr('class', 'backdrop-text')
				.attr('opacity', 0)
				.transition()
				.duration(transDuration)
				.attr('opacity', 1);
		}

		const legendDiv = d3.select('.legend');
		const legendEntry = legendDiv.selectAll('.legend-row');

		legendEntry.on('mousedown', (event) => legendHighlight(event));

		series.forEach((d) => {
			legendDiv
				.select(`.legend-text-${d}`)
				.style('border-bottom', `2px ${nonDotted.includes(d) ? 'solid' : 'dashed'} ${colors[d]}`);
		});

		const hoverLine = annotationsGroup
			.select('line')
			.attr('x1', -100)
			.attr('x2', -100)
			.attr('y1', y(0))
			.attr('y2', y.range()[1])
			.attr('stroke-dasharray', '8,3')
			.attr('stroke-width', 2)
			.attr('opacity', 0.3)
			.attr('stroke', 'white')
			.lower();

		function setSelected(i) {
			hoverGroup
				.selectAll('circle')
				.data(series)
				.enter()
				.append('circle')
				.attr('class', (d) => `hover-circle hover-circle-${d}`)
				.attr('fill', (d) => colors[d])
				.attr('opacity', (d) => (locked.current === null ? 1 : d === locked.current ? 1 : 0))
				.attr('stroke', 'white')
				.attr('stroke-width', (d) => (locked.current === d ? 2 : 0))
				.attr('r', (d) => (d === locked.current ? 5 : 3))
				.attr('cx', x(priceData[i].date))
				.attr('cy', (d) => y(state.showValuation ? priceData[i][d] * sharesIssued : priceData[i][d]))
				.classed('hidden', (d) => priceData[i][d] === null);

			series.forEach((d) => {
				const value = state.showValuation ? priceData[i][d] * sharesIssued : priceData[i][d];
				const formatted = state.showValuation ? `$${formatValuation(value, 3)}` : d3.format('$.2f')(value);

				legendDiv.select(`.legend-value-${d}`).text(formatted);
			});

			const val = locked.current ? priceData[i][locked.current] : priceData[i]['retail'];
			const yVal = state.showValuation ? val * sharesIssued : val;

			hoverLine
				.attr('x1', x(priceData[i].date))
				.attr('x2', x(priceData[i].date))
				// .attr('y2', y(yVal))
				.classed('hidden', yVal === null)
				.lower();

			if (locked.current) {
				let text = hoverGroup
					.append('text')
					.attr('font-size', 14)
					.attr('x', x(priceData[i].date))
					.attr(
						'y',
						y(state.showValuation ? priceData[i][locked.current] * sharesIssued : priceData[i][locked.current])
					)
					.attr('dx', 10)
					.attr('dy', -10)
					.attr('fill', 'white')
					.text(() => `${d3.format('$.2f')(priceData[i][locked.current])} on ${dateFormatDay(priceData[i].date)}`)
					.classed('hidden', priceData[i][locked.current] === null);

				text.clone(true).lower().attr('class', 'backdrop-text'); //.attr('stroke-linejoin', 'round').attr('stroke-width', 5).attr('stroke', 'black');
			}
		}

		annotationsGroup
			.select('rect')
			.attr('x', margin.left)
			.attr('y', margin.top)
			.attr('width', width - margin.right - margin.left)
			.attr('height', height - margin.bottom - margin.top)
			// .attr('fill', 'red')
			.attr('opacity', 0.0)
			.attr('class', 'mouseover')
			.on('mousemove', (event) => {
				// if (!locked) return;
				const dates = priceData.map((d) => d.date);
				var mouse = d3.pointer(event);

				const xm = x.invert(mouse[0]).getTime();
				const i1 = d3.bisectLeft(dates, xm, 1);
				const i0 = i1 - 1;
				const i = xm - dates[i0] > dates[i1] - xm ? i1 : i0;

				if (i >= 0 && i < priceData.length) {
					selectedIndex = i;
					d3.select('.hover-group').selectAll('*').remove();

					setSelected(selectedIndex);
				}
			});

		//set initial:
		setSelected(selectedIndex);
	}, [wrapDim, state, height, margin, max, priceData, overviewData, user]);

	return (
		<Card>
			<CardHeader title="Trading Data" sx={{ textAlign: isMobile ? 'center' : undefined }} />
			<CardContent sx={{ paddingTop: isMobile ? 0 : undefined }}>
				<Stack spacing={4} direction={'column'} alignItems={'center'}>
					<Box width={isMobile ? '100%' : '400px'}>
						<CustomToggleButton
							leftLabel="Price per Share"
							rightLabel="Valuation"
							callback={toggleValuation}
							position={state.showValuation ? 'right' : 'left'}
						/>
					</Box>
					{!user?.instie && (
						<div className="legend">
							<div className="legend-row legend-row-par" data-series="par">
								<span
									className="legend-text legend-text-par"
									data-series="par"
									title="Par share value is based on the last published funding round or an estimation of price based on an estimated valuation

		Click to highlight."
								>
									Par
								</span>
								<span className="legend-value legend-value-par" data-series="par"></span>
							</div>
							<div className="legend-row legend-row-bid" data-series="bid">
								<span
									className="legend-text legend-text-bid"
									data-series="bid"
									title="The highest bid based on available third party market data

		Click to highlight."
								>
									Bid
								</span>
								<span className="legend-value legend-value-bid" data-series="bid"></span>
							</div>
							<div className="legend-row legend-row-ask" data-series="ask">
								<span
									className="legend-text legend-text-ask"
									data-series="ask"
									title="The lowest offer based on available third party market data

		Click to highlight."
								>
									Ask
								</span>
								<span className="legend-value legend-value-ask" data-series="ask"></span>
							</div>
							<div className="legend-row legend-row-market" data-series="market">
								<span
									className="legend-text legend-text-market"
									data-series="market"
									title="The average of the bid and ask

		Click to highlight."
								>
									Market
								</span>
								<span className="legend-value legend-value-market" data-series="market"></span>
							</div>
							<div className="legend-row legend-row-institutional" data-series="institutional">
								<span
									className="legend-text legend-text-institutional"
									data-series="institutional"
									title="A general pricing profile based on combined institutional demand data

		Click to highlight."
								>
									Institutional
								</span>
								<span className="legend-value legend-value-institutional" data-series="institutional"></span>
							</div>
							<div className="legend-row legend-row-retail" data-series="retail">
								<span
									className="legend-text legend-text-retail"
									data-series="retail"
									title="A general pricing profile based on combine retail demand data

		Click to highlight."
								>
									Retail
								</span>
								<span className="legend-value legend-value-retail" data-series="retail"></span>
							</div>
						</div>
					)}
					{user?.instie && (
						<div className="legend">
							<div className="legend-row legend-row-trading" data-series="trading">
								<span
									className="legend-text legend-text-trading"
									data-series="trading"
									title="Description goes here. Click to highlight."
								>
									Currently Trading
								</span>
								<span className="legend-value legend-value-trading" data-series="trading"></span>
							</div>
							<div className="legend-row legend-row-high" data-series="high">
								<span
									className="legend-text legend-text-high"
									data-series="high"
									title="Description goes here. Click to highlight."
								>
									Price Range High
								</span>
								<span className="legend-value legend-value-high" data-series="high"></span>
							</div>
							<div className="legend-row legend-row-low" data-series="low">
								<span
									className="legend-text legend-text-low"
									data-series="low"
									title="Description goes here. Click to highlight."
								>
									Price Range Low
								</span>
								<span className="legend-value legend-value-low" data-series="low"></span>
							</div>
						</div>
					)}
					<Box width={'100%'} ref={wrapperRef}>
						<svg ref={svgRef}>
							<g className="axis">
								<g className="x-axis" />
								<g className="y-axis" />
								<g className="y-axis-right" />
							</g>
							<g className="lines">
								<path className="spread-area" />
								<path className="series-line series-line-market" />
								<path className="series-line series-line-ask" />
								<path className="series-line series-line-bid" />
								<path className="series-line series-line-institutional" />
								<path className="series-line series-line-retail" />
								<path className="series-line series-line-par" />
								<path className="series-line series-line-trading" />
								<path className="series-line series-line-high" />
								<path className="series-line series-line-low" />
							</g>
							<g className="annotations">
								<line className="hover-line" />
								<rect className="mouseover" />
							</g>
							<g className="hover-group" />
						</svg>
					</Box>
				</Stack>
			</CardContent>
		</Card>
	);
}

export default PriceChart;
