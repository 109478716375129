import { useParams } from 'react-router-dom';
import UserDrawer from '../../../../components/UserDrawer';
import MasterBidDrawer from '../MasterBidDrawer';

const BidsDrawerWrapper = () => {
	const { id } = useParams();

	if (id) {
		if (window.location.pathname.includes('user')) {
			return <UserDrawer backlink={'/admin/bids'} />;
		} else {
			return <MasterBidDrawer />;
		}
	}

	return <></>;
};

export default BidsDrawerWrapper;
