import PageTitle from '../../components/PageTitle';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { useEffect, useMemo } from 'react';
import { Box, Tab } from '@mui/material';
import ComplianceTab from './tabs/ComplianceTab';
import TeamMembersTab from './tabs/TeamMembersTab';
import { useLocation, useNavigate } from 'react-router-dom';
import EntitiesTab from './tabs/EntitiesTab';

const SettingsPageView = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const currentTab = useMemo(() => {
		const settingStringPattern = /\/settings\/([^/?]+)\/?/;
		const matchWithParameters = location.pathname.match(settingStringPattern);
		return matchWithParameters ? matchWithParameters[1] : null; // Extracted setting string
	}, [location]);

	const tabs = [
		{
			value: 'compliance',
			label: 'Compliance',
			children: <ComplianceTab />,
			visible: true,
		},
		{
			value: 'team',
			label: 'Team members',
			children: <TeamMembersTab />,
			visible: true,
		},
		{
			value: 'entities',
			label: 'Entities',
			children: <EntitiesTab />,
			visible: true,
		},
	];

	useEffect(() => {
		if (location.pathname === '/settings') {
			navigate(`/settings/${tabs[0].value}`);
		}
	}, [location, navigate]);

	return (
		<>
			<PageTitle title={'Settings'} />
			<TabContext value={currentTab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={(event, value) => navigate(`/settings/${value}`)}>
						{tabs
							.filter((tab) => tab.visible)
							.map((tab, i) => (
								<Tab key={i} label={tab.label} value={tab.value} />
							))}
					</TabList>
				</Box>
				{tabs
					.filter((tab) => tab.visible)
					.map((tab, i) => (
						<TabPanel key={i} sx={{ paddingLeft: 0, paddingRight: 0 }} value={tab.value}>
							{tab.children}
						</TabPanel>
					))}
			</TabContext>
		</>
	);
};

export default SettingsPageView;
