import styled from 'styled-components';
import { Stack, useTheme } from '@mui/material';

export const BidsCollapseHeader = styled(Stack).attrs({
	direction: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
})(() => {
	const defaultTheme = useTheme();

	return {
		paddingBottom: 10,
		borderBottom: `1px solid ${defaultTheme.palette.divider}`,
		transition: 'all 0.1s ease',

		'&:hover': {
			cursor: 'pointer',
			borderBottomColor: defaultTheme.palette.grey['600'],
		},
	};
});
