import styled from 'styled-components';
import { Box } from '@mui/material';

export const ChatBoxWrapper = styled(Box)(() => {
	return {
		padding: 16,
		flexGrow: 1,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column-reverse',
	};
});
