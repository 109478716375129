import {
	Badge,
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	ListItemText,
	MenuItem,
	Skeleton,
	Stack,
	SwipeableDrawer,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import CompanyCard from '../CompanyCard';
import { Apartment, FilterAlt, Star, StarBorder } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import Select from '../inputs/Select';
import { NotFoundContainer, Puller } from './companyList.styles';
import { useAuthContext } from '../../context';

const valuationOptions = [
	{ valueFrom: 0, valueTo: 500000000, label: '< $500M' },
	{ valueFrom: 500000000, valueTo: 1000000000, label: '$500M - $1B' },
	{ valueFrom: 1000000000, valueTo: 5000000000, label: '$1B - $5B' },
	{ valueFrom: 5000000000, valueTo: 1000000000, label: '$5B - $10B' },
	{ valueFrom: 1000000000, valueTo: null, label: '> $10B' },
];

const CompanyList = (props) => {
	const { user } = useAuthContext();
	const {
		companies = [],
		favoriteCompanyIds = [],
		parent = 'modal',
		loading = false,
		onCompanyClick,
		onStarClick,
	} = props;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState(false);
	const [filter, setFilters] = useState({
		name: '',
		sector: [],
		valuation: [],
		favorite: false,
	});
	const isFiltered = useMemo(() => {
		return (
			filter.name !== '' ||
			Boolean(filter.sector.length) ||
			Boolean(filter.valuation.length) ||
			filter.favorite !== false
		);
	}, [filter]);

	const filteredCompanies = useMemo(() => {
		return companies.filter((company) => {
			const isName = filter.name ? company['Legal Name'].toLowerCase().includes(filter.name.toLowerCase()) : true;
			const isSector = filter.sector.length ? filter.sector.includes(company.Sectors) : true;

			let isValuation = true;
			if (filter.valuation.length) {
				isValuation = false;

				filter.valuation.forEach((valuation) => {
					if (isValuation) return;
					const { valueFrom, valueTo } = valuationOptions.find((val) => val.label === valuation);
					isValuation = valueFrom <= company['valuation'] && (valueTo === null || valueTo >= company['valuation']);
				});
			}

			const isFavorite = filter.favorite ? favoriteCompanyIds.includes(company['Record Id']) : true;

			return isName && isSector && isValuation && isFavorite;
		});
	}, [companies, favoriteCompanyIds, filter]);

	const handleChange = (evt) => {
		const name = evt.target.name;
		const value = evt.target.value;

		switch (name) {
			case 'name':
				setFilters((prev) => ({
					...prev,
					[name]: value,
				}));
				break;
			case 'favorite':
				setFilters((prev) => ({
					...prev,
					[name]: evt.target.checked,
				}));
				break;
			default:
				setFilters((prev) => ({
					...prev,
					[name]: value === 'string' ? value.split(',') : value,
				}));
				break;
		}
	};

	const getSelectOptions = (allCompanies, selectedCompanies) => {
		return [...new Set(allCompanies)].map((company) => (
			<MenuItem key={company} value={company}>
				<Checkbox checked={selectedCompanies.indexOf(company) > -1} />
				<ListItemText primary={company} />
			</MenuItem>
		));
	};

	const mainFilter = (
		<TextField
			fullWidth
			variant={'outlined'}
			name={'name'}
			value={filter.name}
			onChange={handleChange}
			label={'Company name'}
			size={isMobile ? 'medium' : 'small'}
		/>
	);

	const renderHideableFilters = () => {
		return (
			<>
				<Select
					multiple
					name={'sector'}
					label={'Sector'}
					value={filter.sector}
					onChange={handleChange}
					size={isMobile ? 'medium' : 'small'}
					fullWidth
					renderValue={(selected) => selected.join(', ')}
				>
					{getSelectOptions(
						companies.map((company) => company.Sectors),
						filter.sector
					)}
				</Select>
				<Select
					multiple
					fullWidth
					name={'valuation'}
					label={'Valuation'}
					value={filter.valuation}
					size={isMobile ? 'medium' : 'small'}
					onChange={handleChange}
					renderValue={(selected) => selected.join(', ')}
				>
					{getSelectOptions(
						valuationOptions.map((val) => val.label),
						filter.valuation
					)}
				</Select>
				{favoriteCompanyIds && favoriteCompanyIds.length > 0 && (
					<FormControlLabel
						control={
							<Checkbox
								size={isMobile ? 'large' : 'medium'}
								color={'warning'}
								name={'favorite'}
								onChange={handleChange}
								value={filter.favorite}
								icon={<StarBorder />}
								checkedIcon={<Star />}
							/>
						}
						label={isMobile ? 'Only from watchlist' : undefined}
					/>
				)}
			</>
		);
	};

	const renderContent = () => {
		if (loading) {
			return (
				<Stack spacing={1}>
					<Skeleton variant="text" sx={{ fontSize: '14px', bgcolor: 'grey.900' }} width={150} />
					<Box>
						<Grid container spacing={2} alignItems={'stretch'}>
							{Array.from(Array(8).keys()).map((_, index) => (
								<Grid key={index} item xs={isMobile ? 12 : 4} md={parent !== 'modal' && 3}>
									<Skeleton variant="rectangular" height={isMobile ? 140 : 329} sx={{ bgcolor: 'grey.900' }} />
								</Grid>
							))}
						</Grid>
					</Box>
				</Stack>
			);
		} else {
			if (filteredCompanies.length) {
				return (
					<Stack spacing={1}>
						<Typography variant={'body2'}>{`Found ${filteredCompanies.length} companies`}</Typography>
						<Box>
							<Grid container spacing={parent === 'modal' || isMobile ? 2 : 3} alignItems={'stretch'}>
								{filteredCompanies.map((company, index) => {
									let dynamicFields = { ...company };
									delete dynamicFields['Record Id'];
									delete dynamicFields['Legal Name'];
									delete dynamicFields['Location'];
									delete dynamicFields['Sectors'];
									delete dynamicFields['valuation'];

									if (!user || !user.instie) dynamicFields = {};

									return (
										<Grid key={index} item xs={isMobile ? 12 : 4} md={parent !== 'modal' && 3}>
											<CompanyCard
												key={company['Record Id']}
												id={company['Record Id']}
												companyName={company['Legal Name']}
												sectorIcon={Apartment}
												sectorName={company.Sectors}
												valuation={company['Post-Money Valuation']}
												elevation={parent === 'modal' ? 6 : 3}
												isStar={favoriteCompanyIds.includes(company['Record Id'])}
												link={parent === 'modal' ? undefined : `/company/${company['Record Id']}`}
												onClick={onCompanyClick}
												dynamicFields={parent !== 'modal' ? dynamicFields : undefined}
												onStarClick={onStarClick}
											/>
										</Grid>
									);
								})}
							</Grid>
						</Box>
					</Stack>
				);
			} else {
				return (
					<NotFoundContainer>
						<Stack spacing={3} alignItems={'center'}>
							<img src={'/illustrations/dark/notFound.svg'} style={{ height: isMobile ? 140 : 200 }} alt="Not found" />
							<Stack spacing={1} alignItems={'center'}>
								<Typography variant={'h5'}>No companies found</Typography>
								<Typography variant={'body2'} textAlign={'center'}>
									We couldn't find what you searched for. Change your search criteria.
								</Typography>
							</Stack>
						</Stack>
					</NotFoundContainer>
				);
			}
		}
	};

	return (
		<Stack spacing={isMobile ? 2 : 3} height={filteredCompanies.length ? undefined : '100%'}>
			<Stack spacing={1} direction={'row'}>
				{mainFilter}
				{!isMobile && renderHideableFilters()}
				{isMobile && parent !== 'modal' && (
					<IconButton aria-label="delete" size="large" onClick={() => setMobileFiltersOpen(true)}>
						<Badge color="primary" variant="dot" invisible={!isFiltered}>
							<FilterAlt fontSize="large" sx={{ color: 'text.secondary' }} />
						</Badge>
					</IconButton>
				)}
			</Stack>
			{renderContent()}
			{isMobile && (
				<SwipeableDrawer
					anchor="bottom"
					elevation={3}
					open={isMobileFiltersOpen}
					onClose={() => setMobileFiltersOpen(false)}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						style: {
							borderTopLeftRadius: 24,
							borderTopRightRadius: 24,
						},
					}}
				>
					<Puller />
					<Stack p={3} pb={'50px'} spacing={3}>
						<Typography variant={'h5'}>Filters</Typography>
						<Stack spacing={1}>
							{mainFilter}
							{renderHideableFilters()}
						</Stack>
					</Stack>
				</SwipeableDrawer>
			)}
		</Stack>
	);
};

export default CompanyList;
