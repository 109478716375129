import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ActivityFeedProvider, AuthProvider, LoaderProvider, ModalProvider } from './context';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme } from './styles/theme';
import Main from './layout/Main';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license';

const isProduction = window.location.href.includes('platform.sharewell.vc');

Sentry.init({
	dsn: 'https://6857da20f9464a0493a037c67b8d3b3b@o1307935.ingest.sentry.io/6552901',
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
	],
	enabled: isProduction,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: [/^https:\/\/backend.sharewell\.vc/],
	// replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

LicenseInfo.setLicenseKey(
	'b1c69e34a7b8f2475fb346a10c5d9043Tz05NDkzMSxFPTE3NTM1MzY1MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const app = document.getElementById('root');

const root = createRoot(app);

root.render(
	<StrictMode>
		<BrowserRouter>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover
			/>
			<ThemeProvider theme={darkTheme}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<LoaderProvider>
						<AuthProvider>
							<ModalProvider>
								<ActivityFeedProvider>
									<Main>
										<CssBaseline enableColorScheme />
										<AppRouter />
									</Main>
								</ActivityFeedProvider>
							</ModalProvider>
						</AuthProvider>
					</LoaderProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</BrowserRouter>
	</StrictMode>
);

reportWebVitals();
