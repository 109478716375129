import styled from 'styled-components';
import { Box, Typography, useTheme } from '@mui/material';
import InputField from '../FormComponents/InputField';

export const ValueTypography = styled(Typography).attrs((props) => ({
	variant: 'body2',
	color: props.color ?? 'text.primary',
}))(
	(props) => `
	line-height: ${props.lineHeight ?? '32px'};
	word-wrap: break-word;
`
);

export const StyledTextArea = styled(InputField).attrs(({ rows }) => ({
	rows: rows ?? 8,
	multiline: true,
	fullWidth: true,
	size: 'small',
	inputProps: {
		style: { fontSize: 14 },
	},
}))``;

export const StyledInput = styled(InputField).attrs({
	size: 'small',
	inputProps: {
		style: { fontSize: 14 },
	},
})``;

export const TableBox = styled(Box)(() => {
	const defaultTheme = useTheme();

	return {
		paddingLeft: '12px',
		paddingRight: '12px',
		paddingTop: '4px',
		paddingBottom: '4px',
		borderRadius: defaultTheme.shape.borderRadius,
		border: `1px ${defaultTheme.palette.divider} solid`,

		'& td': {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',

			'& p': {
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
			},
		},
	};
});
