import * as Yup from 'yup';

export const initialValues = {
	Buyer: [],
	Company: [],
	Name: '',
	'Valid To': '',
	'Gross Price': '',
	'Block Min': '',
	'Block Max': '',
	'Min Bid Size': '',
	'Max Bid Size': '',
	'Commission per Share': '',
	'Commission Pct': '',
	'Admin Fee': '',
	Notes: '',
	'Notes To Seller': '',
	Structure: [],
	'Market Visibility': '',
};

export const schema = Yup.object({
	Buyer: Yup.array().min(1, 'Required'),
	Company: Yup.array().min(1, 'Required'),
	Name: Yup.string().required('Required'),
	'Valid To': Yup.string().required('Required'),
	'Gross Price': Yup.string().required('Required'),
	'Block Min': Yup.string().required('Required'),
	'Block Max': Yup.string().required('Required'),
	'Min Bid Size': Yup.string().required('Required'),
	'Max Bid Size': Yup.string().required('Required'),
	'Commission per Share': Yup.string().required('Required'),
	'Commission Pct': Yup.string().required('Required'),
	'Admin Fee': Yup.string().required('Required'),
	Structure: Yup.array().min(1, 'Required'),
	'Market Visibility': Yup.string().required('Required'),
});
