import React from 'react';
import { Box, Grid } from '@mui/material';
import FundingRounds from './FundingRounds';

export function FundingRoundsTab({ fundingData }) {
	return (
		<Box>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FundingRounds fundingData={fundingData} />
				</Grid>
			</Grid>
		</Box>
	);
}
