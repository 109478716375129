import PageTitle from '../../components/PageTitle';
import { Checkbox, FormControlLabel, Grid, Skeleton, Stack, Typography } from '@mui/material';
import InfoBlock from '../../components/InfoBlock';
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';
import MasterBidPreview from './components/MasterBidPreview';
import { useEffect, useState } from 'react';
import { apiClient, endpoints, getDefaultErrorMessage } from '../../utils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import BidSellerItem from './components/BidSellerItem';
import ThankYouBlock from '../../components/ThankYouBlock';
import { strings } from '../../utils/strings';

const AdminInviteSellerToMasterBidView = () => {
	const { id } = useParams();
	const [masterBid, setMasterBid] = useState(null);
	const [buyer, setBuyer] = useState(null);

	const [isLoading, setLoading] = useState(true);
	const [isSaving, setSaving] = useState(false);
	const [isSuccess, setSuccess] = useState(false);

	const [users, setUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);

	useEffect(() => {
		const loadBid = async () => {
			try {
				setLoading(true);
				const res = await apiClient.get(endpoints.getMasterBidById.replace('{id}', id));

				if (res.data) {
					const userRes = await apiClient.get(
						endpoints.getUserDetails.replace('{userId}', res.data.fields['Buyer'][0])
					);

					if (userRes.data) {
						setBuyer(userRes.data);
						setMasterBid(res.data);
					}
				}
			} catch (err) {
				toast.error(strings.errorMessages.masterBid.load);
			}
		};

		if (id) {
			loadBid();
		} else {
			setMasterBid(null);
		}
	}, [id]);

	useEffect(() => {
		const loadUsers = async () => {
			try {
				const res = await apiClient.get(
					endpoints.getUsersForBid.replace('{companyId}', masterBid.fields['Company'][0])
				);
				return res.data ?? [];
			} catch (err) {
				toast.error(strings.errorMessages.user.loadMany);
				return [];
			} finally {
				setLoading(false);
			}
		};

		setSelectedUsers([]);

		if (masterBid && masterBid.fields['Company'][0]) {
			loadUsers().then((res) => {
				setUsers(res);
			});
		} else {
			setUsers([]);
		}
	}, [masterBid]);

	const onSelectAll = (event) => {
		setSelectedUsers(event.target.checked ? users : []);
	};

	const onSelectOne = (event, user) => {
		if (event.target.checked) {
			setSelectedUsers((prevState) => [...prevState, user]);
		} else {
			setSelectedUsers((prevState) => prevState.filter((item) => item.id !== user.id));
		}
	};

	const onConfirm = async () => {
		try {
			setSaving(true);
			const res = await apiClient.post(endpoints.inviteBids, {
				userIds: selectedUsers.map((i) => i.id),
				masterBidId: id,
			});

			if (res.data) {
				setSuccess(true);
			}
		} catch (e) {
			getDefaultErrorMessage(e);
		} finally {
			setSaving(false);
		}
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Admin',
						link: '/admin',
					},
					{
						title: 'Bids',
						link: '/admin/bids',
					},
					{
						title: 'Invite sellers',
					},
				]}
				title={'Invite sellers'}
			/>
			{isSuccess && (
				<Grid item xs={6} marginTop={'60px'} marginBottom={'60px'}>
					<ThankYouBlock
						title={'Invitation(s) sent successfully'}
						description={'Put a penny in the jar for every message sent!'}
						buttonText={'home'}
						buttonLink={'/'}
						iconName={'message'}
					/>
				</Grid>
			)}
			{!isSuccess && (
				<Stack spacing={4}>
					<InfoBlock title={'Master bid'}>
						<Grid item xs={6}>
							<MasterBidPreview masterbid={masterBid} buyer={buyer} />
						</Grid>
					</InfoBlock>
					<InfoBlock title={`Select users to invite`}>
						<Grid item xs={12}>
							{isLoading && (
								<Stack spacing={'12px'}>
									{Array.from(Array(3).keys()).map((_, index) => (
										<Skeleton variant="rectangular" height={98} key={index} />
									))}
								</Stack>
							)}
							{!isLoading && users.length === 0 && (
								<Typography variant={'body2'} color={'text.secondary'}>
									No window shoppers founds for this company.
								</Typography>
							)}
							{!isLoading && users.length > 0 && (
								<Stack spacing={'8px'}>
									<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'} pl={'28px'}>
										<FormControlLabel
											control={<Checkbox checked={selectedUsers.length === users.length} onChange={onSelectAll} />}
											label="Select all"
										/>
										<Typography>{`Selected ${selectedUsers.length} user${
											selectedUsers.length === 1 ? '' : 's'
										}`}</Typography>
									</Stack>
									<Stack spacing={'12px'}>
										{users.map((user) => (
											<BidSellerItem
												key={user.id}
												user={user}
												isSelected={Boolean(selectedUsers.find((item) => item.id === user.id))}
												handleCheck={(ev) => onSelectOne(ev, user)}
											/>
										))}
									</Stack>
								</Stack>
							)}
						</Grid>
					</InfoBlock>
					<Stack alignItems={'end'}>
						<LoadingButton
							variant={'contained'}
							disabled={selectedUsers.length === 0}
							onClick={onConfirm}
							loading={isSaving}
							endIcon={<Send />}
							loadingPosition={'end'}
						>
							Send invitations ({selectedUsers.length})
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</>
	);
};

export default AdminInviteSellerToMasterBidView;
