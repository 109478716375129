import { Divider, Link, Paper, Skeleton, Stack, Typography } from '@mui/material';
import * as d3 from 'd3-latest';
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Link as RouterLink } from 'react-router-dom';

const MasterBidPreview = ({ masterbid, buyer }) => {
	const getSellersBlockSumm = () => {
		let sum = 0;
		(masterbid?.fields?.Bids ?? []).forEach((obj) => {
			sum += obj.fields.Block;
		});
		return sum;
	};

	const getProgressPercentage = () => {
		return Math.round((getSellersBlockSumm() * 100) / masterbid?.fields['Block Min']);
	};

	if (masterbid && buyer) {
		return (
			<Paper elevation={2} sx={{ boxShadow: 'none' }}>
				<Stack direction={'column'} spacing={2} p={'20px'} pt={2}>
					<Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
						<Stack direction={'column'}>
							<Typography variant={'h5'}>{masterbid.fields.Name}</Typography>
							<Typography variant={'body2'} color={'text.secondary'}>
								{buyer.fields.Email}
							</Typography>
						</Stack>
						<Stack direction={'column'} alignItems={'center'} spacing={1} sx={{ flexShrink: 0 }} pt={1}>
							<LinearProgress
								color={getProgressPercentage() < 100 ? 'primary' : 'success'}
								variant={'determinate'}
								value={getProgressPercentage()}
								sx={{ width: '100%', minWidth: 120 }}
							/>
							<Typography variant={'body2'} sx={{ flexShrink: 0 }}>
								{`${getProgressPercentage()}% (${d3.format('$,.2f')(getSellersBlockSumm())} / ${d3.format('$,.2f')(
									masterbid.fields['Block Min']
								)})`}
							</Typography>
						</Stack>
					</Stack>
					<Divider orientation="horizontal" />
					<Stack spacing={'50px'} direction={'row'}>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Company
							</Typography>
							<Link
								variant={'body2'}
								underline={'hover'}
								component={RouterLink}
								to={'/company/' + masterbid.fields['Company'][0]}
								target={'_blank'}
							>
								{masterbid.fields['Company Name'][0]}
							</Link>
						</Stack>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Gross Price
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
								{d3.format('$,.2f')(masterbid.fields['Gross Price'])}
							</Typography>
						</Stack>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Block Min
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
								{d3.format('$,.2f')(masterbid.fields['Block Min'])}
							</Typography>
						</Stack>
						<Stack spacing={'4px'} direction={'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }}>
								Block Max
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }}>
								{d3.format('$,.2f')(masterbid.fields['Block Max'])}
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Paper>
		);
	} else {
		return <Skeleton variant="rectangular" height={165} />;
	}
};

export default MasterBidPreview;
