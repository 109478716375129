import { Box, Container, Stack, SwipeableDrawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useActivityFeedContext } from '../../context';
import BidActivityFeedType from './components/BidActivityFeedType';

const ActivityFeed = () => {
	const { feed, isOpen, setOpen } = useActivityFeedContext();
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	return (
		<SwipeableDrawer
			open={isOpen}
			elevation={3}
			anchor={'right'}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			sx={{ top: isMobile ? 54 : 64 }}
			slotProps={{
				backdrop: {
					invisible: true,
				},
			}}
			PaperProps={{
				style: {
					top: 'unset',
					boxShadow: defaultTheme.shadows[16],
					height: isMobile ? 'calc(100% - 54px)' : 'calc(100% - 64px)',
				},
			}}
		>
			<Container sx={{ width: isMobile ? window.innerWidth : 450, pt: '20px', pb: 3 }}>
				<Stack spacing={2} direction={'column'}>
					<Stack direction={'row'} alignItems={'center'} spacing={1}>
						<Typography variant={'h6'}>Activity Feed</Typography>
						{Boolean(feed.find((item) => !Boolean(item.fields.Answer))) && (
							<Box width={8} height={8} borderRadius="50%" bgcolor="error.main" />
						)}
					</Stack>
					<Stack spacing={'12px'} direction={'column'}>
						{feed.map((item) => (
							<BidActivityFeedType bid={item} key={item.id} />
						))}
					</Stack>
				</Stack>
			</Container>
		</SwipeableDrawer>
	);
};

export default ActivityFeed;
