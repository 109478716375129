import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Apartment, ArrowForward } from '@mui/icons-material';
import CompanyCard from '../CompanyCard';
import { useModalContext, usePublicAppContext } from '../../context';

const CompanySlide = ({ isCompact = false, cardAmount = 3 }) => {
	const { openModal } = useModalContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const defaultTheme = useTheme();
	const { companies } = usePublicAppContext();

	const renderCompanies = () => {
		if (isMobile) {
			return companies.slice(0, 2).map((company) => (
				<Box width={'70%'} flexShrink={0} key={company['Legal Name']}>
					<CompanyCard
						key={company['Record Id']}
						id={company['Record Id']}
						companyName={company['Legal Name']}
						sectorIcon={Apartment}
						sectorName={company.Sectors}
						valuation={company['Post-Money Valuation']}
						elevation={3}
					/>
				</Box>
			));
		}

		if (!isCompact) {
			return companies.slice(0, cardAmount).map((company) => (
				<Grid item xs={6} key={company['Legal Name']}>
					<CompanyCard
						key={company['Record Id']}
						id={company['Record Id']}
						companyName={company['Legal Name']}
						sectorIcon={Apartment}
						sectorName={company.Sectors}
						valuation={company['Post-Money Valuation']}
						elevation={3}
					/>
				</Grid>
			));
		}

		return <></>;
	};

	if (isMobile) {
		return (
			<Stack spacing={'40px'}>
				<Stack spacing={2}>
					<Typography variant="h5">Your trusted navigator in the secondary market</Typography>
					<Typography variant="body1">Access to 300+ companies:</Typography>
				</Stack>
				<Stack spacing={2} direction={'row'} width={'100%'} overflow={'hidden'} position={'relative'}>
					{renderCompanies()}
					<Box
						position={'absolute'}
						top={0}
						right={0}
						width={100}
						height={'100%'}
						sx={{
							background: `linear-gradient(270deg, ${defaultTheme.palette.background.default} 0%, rgba(18, 18, 18, 0) 100%)`,
						}}
					/>
				</Stack>
				<Button variant={'text'} size={'large'} onClick={() => openModal({ companies: companies, type: 'companies' })}>
					See All Companies
				</Button>
			</Stack>
		);
	}

	return (
		<Stack spacing={'60px'}>
			<Stack spacing={3}>
				<Typography variant="h4">Your trusted navigator in the secondary market</Typography>
				<Typography variant="body1">
					Private market trading has never been simpler. We combine a transparent, data-driven approach with a human
					touch to help guide you through the hoops of secondaries trading.
				</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant="body1">Access to 300+ companies:</Typography>
				<Box>
					<Grid container spacing={3} alignItems={'center'}>
						{renderCompanies()}
						<Grid item xs={isCompact ? 12 : 6}>
							<Button
								variant={'text'}
								endIcon={<ArrowForward />}
								onClick={() => openModal({ companies: companies, type: 'companies' })}
							>
								See All Companies
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Stack>
		</Stack>
	);
};

export default CompanySlide;
