import { Grid } from '@mui/material';
import { LabelTypography } from './common.styles';
import React from 'react';

export const labelColumns = 4;
export const valueColumns = 12 - labelColumns;

export const Label = ({ children, required }) => (
	<Grid item xs={labelColumns}>
		<LabelTypography $required={required}>{children}</LabelTypography>
	</Grid>
);
