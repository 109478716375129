import {
	Box,
	Card,
	CardContent,
	Grid,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React from 'react';
import { NotFoundContainer } from '../../../../components/CompanyList/companyList.styles';

const InvestorsTab = ({ investors }) => {
	if (investors && investors.length > 0) {
		return (
			<Box>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Table sx={{ width: '100%' }}>
									<TableHead>
										<TableRow>
											<TableCell sx={{ color: 'text.secondary' }}>Name</TableCell>
											<TableCell sx={{ color: 'text.secondary' }}>Type</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{investors.map((item) => (
											<TableRow key={item.id}>
												<TableCell>{item.name}</TableCell>
												<TableCell>{item.type}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		);
	} else {
		return (
			<NotFoundContainer>
				<Stack spacing={3} alignItems={'center'}>
					<img src={'/illustrations/dark/notFound.svg'} style={{ height: 160 }} alt="Not found" />
					<Typography variant={'body2'} textAlign={'center'}>
						No investors found
					</Typography>
				</Stack>
			</NotFoundContainer>
		);
	}
};

export default InvestorsTab;
