import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteJWT, getUserHomepage, setJWT } from '../utils';
import { useLoaderContext } from './LoaderContext';

const AuthContext = createContext({
	user: null,
	authenticated: false,
	isDataLoading: true,
	logout: () => {},
	inquiries: [],
	setInquiries: () => {},
	reloadUserData: () => {},
	bids: [],
	updateBid: () => {},
});

export const AuthProvider = ({ children }) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [authenticated, setAuthenticated] = useState(false);
	/** isDataLoading is needed to avoid router rendering before getting data */
	const [isDataLoading, setDataLoading] = useState(true);

	const [user, setUser] = useState(null);
	const [inquiries, setInquiries] = useState([]);
	const [bids, setBids] = useState([]);

	const { openLoader } = useLoaderContext();

	useEffect(() => {
		openLoader();
		handleInitialLoad();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getUserInfo = useCallback(async () => {
		const res = await getUserHomepage();

		if (res.data) {
			const userResponse = res.data.user;
			const instie = userResponse?.fields.UserStage !== 'Retail';

			setUser({
				...userResponse,
				isLevel2: instie && userResponse?.fields.UserStage === 'Inst2',
				isLevel3: instie && ['Inst3', 'Inst3Ask', 'Inst3Bid'].includes(userResponse?.fields.UserStage),
				instie: instie,
				isAdmin: userResponse?.fields['User Type'] === 'Admin',
				isAccredited: instie ? Boolean(userResponse?.fields.Accreditation) : true,
				shadow: Boolean(userResponse?.fields['Shadow']),
			});

			if (userResponse?.fields['Shadow']) {
				//set body background color to #6d2d83
				document.body.style.backgroundColor = '#6d2d83';
			}

			if (JSON.stringify(res.data.inquiries) !== JSON.stringify(inquiries)) {
				setInquiries(res.data.inquiries);
			}

			if (JSON.stringify(res.data.bids) !== JSON.stringify(bids)) {
				setBids(res.data.bids);
			}

			setAuthenticated(true);
		}
	}, [inquiries, bids]);

	const handleInitialLoad = async () => {
		try {
			const token = searchParams.get('token');

			if (token) {
				setJWT(token).catch((e) => e);
			}

			await getUserInfo();
		} catch (err) {
			setAuthenticated(false);
			deleteJWT().catch((e) => e);
		} finally {
			setDataLoading(false);
		}
	};

	const logout = () => {
		setAuthenticated(false);
		localStorage.removeItem('JWT');
		navigate('/');
	};

	const reloadUserData = async () => {
		await getUserInfo();
	};

	const updateBid = (bid) => {
		setBids((prevState) =>
			prevState.map((prevBid) => {
				if (prevBid.id === bid.id) {
					return bid;
				}

				return prevBid;
			})
		);
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				authenticated,
				logout,
				inquiries,
				setInquiries,
				reloadUserData,
				isDataLoading,
				bids,
				updateBid,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => useContext(AuthContext);
