import styled from 'styled-components';
import { alpha, Paper, Stack, useTheme } from '@mui/material';

export const ItemContainer = styled(Stack).attrs({
	direction: 'row',
	alignItems: 'flex-start',
	spacing: 3,
	p: 2,
	pr: 3,
})(() => ({}));

export const PaperContainer = styled(Paper).attrs({
	elevation: 1,
})((props) => {
	const defaultTheme = useTheme();

	return {
		'&.MuiPaper-root': {
			transition: 'all 0.2s ease',
			backgroundColor: props.$selected ? alpha(defaultTheme.palette.primary.main, 0.1) : undefined,
		},
	};
});
