import ActivityFeedCard from '../ActivityFeedCard';
import { Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Check, HistoryToggleOff } from '@mui/icons-material';
import { useActivityFeedContext } from '../../../../context';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as d3 from 'd3-latest';
import { apiClient, dateFormatter, endpoints, shortDateFormatter } from '../../../../utils';

const interestedLabel = 'Interested';
const appliedLabel = 'Applied';

const BidActivityFeedType = ({ bid, isSnackbar = false }) => {
	const navigate = useNavigate();
	const { setOpen: setActivityFeedOpen, updateFeed, clearLastNewFeed } = useActivityFeedContext();
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	const { id, fields } = bid;

	const isExpired = useMemo(() => new Date().getTime() > new Date(fields['Valid To']).getTime(), [fields]);
	const isApplied = useMemo(() => fields['Answer'] === appliedLabel, [fields]);
	const isAnswered = useMemo(() => Boolean(fields['Answer']), [fields]);

	const onBidReply = async (interested) => {
		if (!isAnswered) {
			const res = await apiClient.post(endpoints.respondBid, {
				id: id,
				fields: {
					Answer: interested ? interestedLabel : 'Not Interested',
				},
			});

			if (res.data) {
				updateFeed(res.data);
				clearLastNewFeed();
			}
		}

		if (interested) {
			navigate('/bid/' + id);
			setActivityFeedOpen(false);
		}
	};

	const getStructureString = (structure) => {
		let structureMap = {
			Direct: ' direct transfers',
			'Structured - Forwards': ' structured transactions',
			SPV: ' SPVs',
		};

		if (structure && structure.length > 0) {
			let structureString = ' for ';
			structure.forEach((structure, index) => {
				if (index > 0) {
					structureString += ', ';
				}
				structureString += structureMap[structure];
			});
			return structureString;
		}

		return '';
	};

	return (
		<ActivityFeedCard
			title={'New bid interest request'}
			date={shortDateFormatter(new Date(fields['Created At']))}
			isNew={!isSnackbar && !isAnswered && !isExpired}
		>
			<Typography variant={'body2'}>
				We have{' '}
				{fields['Bid Type']?.[0] === 'Indicative'
					? 'an indicative'
					: fields['Bid Type']?.[0] === 'Firm'
					? 'a firm'
					: 'a'}{' '}
				bid for your{' '}
				<Link component={RouterLink} to={'/company/' + fields['Company']?.[0]} underline={'hover'} target={'_blank'}>
					<b>{fields['Company Name']?.[0]}</b>
				</Link>{' '}
				shares at a Gross Price of <b>{d3.format('$,.2f')(fields['Gross Price'])}</b> per share
				{getStructureString(fields['Structure'])}. Please review the following details, marked as live until{' '}
				<b>{dateFormatter(new Date(fields['Valid To']))}</b>.
			</Typography>

			<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} pt={1}>
				<Button
					variant={'text'}
					color={'primary'}
					size={isMobile ? 'medium' : 'small'}
					onClick={() => {
						onBidReply(true);
					}}
				>
					open bid details
				</Button>

				{isApplied && (
					<Stack direction={'row'} alignItems={'center'} spacing={1}>
						<Check color={'success'} fontSize={'small'} />
						<Typography color={'success.main'} sx={{ fontSize: 13, fontWeight: 500, textTransform: 'uppercase' }}>
							You’ve applied
						</Typography>
					</Stack>
				)}
				{isExpired && !isApplied && (
					<Stack direction={'row'} alignItems={'center'} spacing={1}>
						<HistoryToggleOff color={'disabled'} fontSize={'small'} />
						<Typography color={'disabled.main'} sx={{ fontSize: 13, fontWeight: 500, textTransform: 'uppercase' }}>
							Expired
						</Typography>
					</Stack>
				)}
			</Stack>
		</ActivityFeedCard>
	);
};

export default BidActivityFeedType;
