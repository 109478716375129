import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import styled from 'styled-components';
import InputField from '../components/FormComponents/InputField';
import React from 'react';

export const MainMobileBox = styled(Box)`
	width: 100vw;
	flex: 1;
	overflow-x: hidden;
`;

export const MainMobileGrid = styled(Grid).attrs({
	container: true,
	columns: 4,
	columnSpacing: 2,
})``;

export const LabelTypography = styled(Typography).attrs((props) => ({
	variant: 'body2',
	color: props.color ?? 'text.secondary',
}))((props) => {
	const defaultTheme = useTheme();

	return `line-height: 32px;
	word-wrap: break-word;

	&::after {
		content: ${props['$required'] ? "' *'" : "''"};
		color: ${defaultTheme.palette.error.main};
	}
`;
});

export const ValueTypography = styled(Typography).attrs((props) => ({
	variant: 'body2',
	color: props.color ?? 'text.primary',
}))(
	(props) => `
	line-height: ${props.lineHeight ?? '32px'};
	word-wrap: break-word;
`
);

export const StyledInput = styled(InputField).attrs({
	size: 'small',
	inputProps: {
		style: { fontSize: 14 },
	},
})``;

export const TableLoader = () => (
	<div
		style={{
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgb(18 18 18 / 60%)',
			display: 'flex',
			paddingTop: 32,
			justifyContent: 'center',
			zIndex: 1,
		}}
	>
		<CircularProgress />
	</div>
);
