import {
	Avatar,
	Box,
	Drawer,
	IconButton,
	LinearProgress,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Tab,
	Typography,
	useTheme,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DeleteOutline, Key, LockOpen, MarkChatReadOutlined, MoreVert } from '@mui/icons-material';
import { apiClient, endpoints, getCorrectStringFieldFromObject, getDefaultErrorMessage } from '../../utils';
import { toast } from 'react-toastify';
import { ValueTypography } from './styles';
import DeleteDialog from '../../views/AdminUsersView/components/DeleteDialog';
import ImpersonateDialog from '../../views/AdminUsersView/components/ImpersonateDialog';
import { getUserAvatarURL } from '../../utils/getUserAvatar';
import { TabContext, TabList } from '@mui/lab';
import ChatTab from './components/ChatTab';
import InfoTab from './components/InfoTab';
import { strings } from '../../utils/strings';

const UserDrawer = ({ backlink = '/admin/users' }) => {
	const { id: userId } = useParams();
	const isNew = useMemo(() => userId === 'new', [userId]);
	const navigate = useNavigate();
	const defaultTheme = useTheme();
	const location = useLocation();

	const [user, setUser] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [tab, setTab] = useState('1');

	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [isDeleteOpen, setDeleteOpen] = useState(false);
	const [isImpersonateOpen, setImpersonateOpen] = useState(false);
	const [isDraft, setDraft] = useState(false);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const tabQueryParam = searchParams.get('tab');

		if (tabQueryParam) {
			setTab(tabQueryParam);
		}
	}, [location]);

	useEffect(() => {
		if (userId && !isNew) {
			setLoading(true);
			loadUser()
				.then((res) => {
					setUser(res);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setUser(null);
		}
	}, [userId]);

	const handleTabChange = (event, newTab) => {
		setTab(newTab);
	};

	const loadUser = async () => {
		try {
			const res = await apiClient.get(endpoints.getUserDetails.replace('{userId}', userId));
			return res.data;
		} catch (err) {
			toast.error(strings.errorMessages.user.load);
			onClose();
		}
	};

	const onClose = useCallback(() => {
		const state = { preventScrollReset: true };

		if (isDraft && userId) {
			state.updateObjectId = userId;
		}

		const params = new URLSearchParams(window.location.search);
		params.delete('tab');

		navigate(backlink + '?' + params.toString(), {
			state: state,
		});

		setUser(null);
		setLoading(true);
		setTab('1');
	}, [isDraft, userId]);

	const onDeleteSuccess = useCallback(() => {
		const params = new URLSearchParams(window.location.search);
		params.delete('tab');

		navigate(backlink + '?' + params.toString(), { state: { preventScrollReset: true, deleteId: userId } });
		setUser(null);
	}, [navigate, userId]);

	const resolveChat = async () => {
		const res = await apiClient.post(endpoints.markChatsResolved, {
			ids: [userId],
		});
		if (res) {
			setMenuAnchorEl(null);
			toast.success(strings.successMessages.chat.resolved);
			setDraft(true);
		}
	};

	return (
		<Drawer anchor={'right'} open={Boolean(userId) && !isNew} elevation={1} onClose={onClose}>
			<Box width={600} height={'100%'}>
				{isLoading && <LinearProgress />}
				{!isLoading && user && (
					<TabContext value={tab}>
						<Stack height={'100%'}>
							<Stack>
								<Stack direction={'row'} spacing={2} p={3} pb={2} alignItems={'center'}>
									<Avatar
										alt={`${getCorrectStringFieldFromObject(user, 'First Name')} ${getCorrectStringFieldFromObject(
											user,
											'Last Name'
										)}`}
										src={getUserAvatarURL(user.fields['Email'])}
										sx={{ width: 60, height: 60, fontSize: 24 }}
									>
										{`${getCorrectStringFieldFromObject(user, 'First Name', false)}${getCorrectStringFieldFromObject(
											user,
											'Last Name',
											false
										)}`}
									</Avatar>
									<Stack spacing={0} sx={{ flex: 1 }}>
										<Typography variant={'h5'} sx={{ wordBreak: 'break-word' }}>
											{`${getCorrectStringFieldFromObject(user, 'First Name')} ${getCorrectStringFieldFromObject(
												user,
												'Last Name'
											)}`}
										</Typography>
										<ValueTypography color={'text.secondary'} lineHeight={'unset'}>
											{user.fields['Email']}
										</ValueTypography>
									</Stack>

									<IconButton
										size="small"
										onClick={(e) => {
											setMenuAnchorEl(e.currentTarget);
										}}
									>
										<MoreVert sx={{ color: 'text.secondary' }} />
									</IconButton>
								</Stack>
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }} pl={3} pr={3}>
									<TabList onChange={handleTabChange}>
										<Tab label="Info" value="1" />
										{!user.fields['Shadow'] && <Tab label="Chat" value="2" />}
									</TabList>
								</Box>
							</Stack>
							<InfoTab user={user} setDraft={setDraft} />
							{!user.fields['Shadow'] && <ChatTab setDraft={setDraft} />}
						</Stack>
					</TabContext>
				)}
			</Box>

			<Menu
				anchorEl={menuAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={Boolean(menuAnchorEl)}
				onClose={() => {
					setMenuAnchorEl(null);
				}}
			>
				<MenuItem
					onClick={() => {
						setImpersonateOpen(true);
						setMenuAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<Key fontSize="small" />
					</ListItemIcon>
					<ListItemText>Impersonate</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={async () => {
						try {
							await apiClient.get(endpoints.grantAccess.replace('{userId}', userId));

							//setUser so that it reuses all previous user fields but change the Status to "Granted":
							setUser((prevUser) => ({
								...prevUser,
								fields: {
									...prevUser.fields,
									Status: 'Granted',
								},
							}));
							toast.success(strings.successMessages.user.granted);
						} catch (err) {
							getDefaultErrorMessage(err);
						} finally {
							setMenuAnchorEl(null);
						}
					}}
				>
					<ListItemIcon>
						<LockOpen fontSize="small" />
					</ListItemIcon>
					<ListItemText>Grant Inst Access (+ intro email)</ListItemText>
				</MenuItem>
				<MenuItem onClick={resolveChat}>
					<ListItemIcon>
						<MarkChatReadOutlined fontSize="small" />
					</ListItemIcon>
					<ListItemText>Mark chat as resolved</ListItemText>
				</MenuItem>
				{/* add separator line before the next MenuItem*/}
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>

				<MenuItem
					sx={{ color: defaultTheme.palette.error.main }}
					onClick={() => {
						setDeleteOpen(true);
						setMenuAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<DeleteOutline fontSize="small" color={'error'} />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>

			<ImpersonateDialog
				id={user?.id}
				open={isImpersonateOpen}
				handleClose={() => {
					setImpersonateOpen(false);
					setMenuAnchorEl(null);
				}}
			/>

			<DeleteDialog
				user={user}
				onSuccess={onDeleteSuccess}
				open={isDeleteOpen}
				handleClose={() => {
					setDeleteOpen(false);
					setMenuAnchorEl(null);
				}}
			/>
		</Drawer>
	);
};

export default UserDrawer;
