import PageTitle from '../../components/PageTitle';
import CompanyList from '../../components/CompanyList';
import { usePrivateAppContext, useAuthContext } from '../../context';
import { apiClient, endpoints } from '../../utils';
import { toast } from 'react-toastify';
import { strings } from '../../utils/strings';

const CompaniesPageView = () => {
	const { user, reloadUserData } = useAuthContext();
	const { companies } = usePrivateAppContext();

	const onStarClick = async (companyId) => {
		let companies = user.fields.Follows ? user.fields.Follows : [];

		if (companies.includes(companyId)) {
			companies = companies.filter((company) => company !== companyId);
		} else {
			companies.push(companyId);
		}

		try {
			const res = await apiClient.post(endpoints.followCompany, {
				companies,
				user: user.id,
			});

			if (res.data) {
				await reloadUserData();
			}
		} catch (err) {
			toast.error(strings.errorMessages.company.follow);
		}
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Companies',
					},
				]}
				title={'All companies'}
			/>
			<CompanyList
				companies={companies}
				favoriteCompanyIds={user?.fields.Follows}
				parent={'page'}
				loading={Boolean(companies.length === 0)}
				onStarClick={onStarClick}
			/>
		</>
	);
};

export default CompaniesPageView;
