import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { apiClient, endpoints, getSystemErrorMessage } from '../utils';
import { useMediaQuery, useTheme } from '@mui/material';

const ChatWidgetContext = createContext({
	isChatWidgetOpen: false,
	setChatWidgetOpen: () => {},
	newMessages: [],
	setNewMessages: () => {},
	messages: [],
	setMessages: () => {},
	selectedTab: '',
	setSelectedTab: () => {},
	selectedQuestion: '',
	setSelectedQuestion: () => {},
	tabsVisible: true,

	chatWidgetWidth: '',
	chatWidgetMinHeight: '',
	chatWidgetMaxHeight: '',
});

export const ChatWidgetProvider = ({ children }) => {
	const { inquiries } = useAuthContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [isChatWidgetOpen, setChatWidgetOpen] = useState(!Boolean(inquiries.length));
	const [selectedTab, setSelectedTab] = useState('chat');
	const [tabsVisible, setTabsVisible] = useState(true);

	const [chatWidgetWidth, setChatWidgetWidth] = useState(isMobile ? '100vw' : 400);
	const [chatWidgetMinHeight, setChatWidgetMinHeight] = useState(isMobile ? '100%' : 600);
	const [chatWidgetMaxHeight, setChatWidgetMaxHeight] = useState(isMobile ? '100%' : '80vh');

	// chat
	const [messages, setMessages] = useState([]);
	const [newMessages, setNewMessages] = useState([]);

	//help
	const [selectedQuestion, setSelectedQuestion] = useState(null);

	useEffect(() => {
		//first, get all messages
		fetchAllMessages();

		//then, set interval to fetch new messages every 30 seconds
		const intervalId = setInterval(fetchAllMessages, 30000);

		// Clean up the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		isChatWidgetOpen && fetchAllMessages();
	}, [isChatWidgetOpen]);

	useEffect(() => {
		setTabsVisible(!Boolean(selectedQuestion));

		if (!isMobile) {
			if (Boolean(selectedQuestion)) {
				increaseChatWidget();
			} else {
				decreaseChatWidget();
			}
		}
	}, [selectedQuestion, isMobile]);

	useEffect(() => {
		if (!isMobile) {
			if (selectedTab === 'help' && !selectedQuestion) {
				setChatWidgetMaxHeight(chatWidgetMinHeight);
			} else {
				setChatWidgetMaxHeight('80vh');
			}
		}
	}, [isMobile, selectedTab, selectedQuestion, chatWidgetMinHeight]);

	useEffect(() => {
		if (newMessages && newMessages.length > 0) {
			document.title = `(${newMessages.length}) ShareWell | SaxonWeber`;
		} else {
			document.title = 'ShareWell | SaxonWeber';
		}
	}, [newMessages]);

	const fetchAllMessages = async () => {
		try {
			const response = await apiClient.get(endpoints.getChatMessages);
			const responseData = response ? response.data : null;
			if (responseData && messages.length < responseData.length) {
				setMessages(responseData);
			}
		} catch (error) {
			getSystemErrorMessage(error);
		}
	};

	const increaseChatWidget = () => {
		setChatWidgetWidth(700);
		setChatWidgetMinHeight('82vh');
	};

	const decreaseChatWidget = () => {
		setChatWidgetWidth(400);
		setChatWidgetMinHeight(600);
	};

	return (
		<ChatWidgetContext.Provider
			value={{
				isChatWidgetOpen,
				setChatWidgetOpen,
				newMessages,
				setNewMessages,
				messages,
				setMessages,
				selectedTab,
				setSelectedTab,
				selectedQuestion,
				setSelectedQuestion,
				tabsVisible,
				chatWidgetWidth,
				chatWidgetMinHeight,
				chatWidgetMaxHeight,
			}}
		>
			{children}
		</ChatWidgetContext.Provider>
	);
};

export const useChatWidgetContext = () => useContext(ChatWidgetContext);
