import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { toast } from 'react-toastify';
import { getDefaultErrorMessage } from '../../../../utils';
import { apiClient, endpoints } from '../../../../utils';
import { strings } from '../../../../utils/strings';

const DeleteDialog = (props) => {
	const { user, onSuccess } = props;
	const { open, handleClose } = props;
	const onDelete = async () => {
		if (user) {
			try {
				await apiClient.get(endpoints.deleteUser.replace('{userId}', user.id));
				toast.success(strings.successMessages.user.deleted);
				onSuccess();
			} catch (err) {
				getDefaultErrorMessage(err);
			} finally {
				handleClose();
			}
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Confirm User Deletion</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to delete this user? This action is irreversible and will permanently remove the user's
					account and associated data from the system. The user will no longer be able to log in or access any
					information related to their account. Please proceed with caution as this action cannot be undone. Confirm if
					you wish to proceed with the user deletion.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color={'inherit'}>
					Cancel
				</Button>
				<Button onClick={onDelete} autoFocus color={'error'}>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;
