import { Checkbox, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { InquiryContainer, PaperContainer } from './styles';
import * as d3 from 'd3-latest';
import { isValidNumber } from '../../utils';

const InquiryRecordItem = (props) => {
	const { inquiry, isSelected, handleCheck, highlightPriorities = true, selectable = true } = props;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<PaperContainer
			$selected={isSelected || (inquiry.fields.Priority && highlightPriorities && inquiry.fields.Priority >= 3)}
		>
			<InquiryContainer>
				{selectable && <Checkbox checked={isSelected} onChange={handleCheck} />}
				<Stack
					direction={isMobile ? 'column' : 'row'}
					alignItems={isMobile ? undefined : 'flex-start'}
					spacing={isMobile ? '2px' : '40px'}
					pt={isMobile ? 1 : undefined}
					flex={1}
				>
					{inquiry.fields['User Email'] && (
						<Stack
							spacing={'4px'}
							direction={isMobile ? 'row' : 'column'}
							width={isMobile ? undefined : 120}
							flexShrink={0}
						>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								User
							</Typography>
							<Stack spacing={'4px'} direction={isMobile ? 'column' : 'row'} flex={isMobile ? 1 : undefined}>
								<Typography variant={'body2'} sx={{ color: 'primary.main' }}>
									{inquiry.fields['First Name']}
								</Typography>
								<Typography variant={'body2'} sx={{ color: 'primary.main' }}>
									{inquiry.fields['Last Name']}
								</Typography>
							</Stack>
						</Stack>
					)}

					<Stack
						spacing={'4px'}
						direction={isMobile ? 'row' : 'column'}
						width={isMobile ? undefined : 40}
						flexShrink={0}
						flex={1}
					>
						<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
							Bid/Ask
						</Typography>
						<Typography
							variant={'body2'}
							flex={isMobile ? 1 : undefined}
							sx={{ color: inquiry.fields['Action'] === 'sell' ? 'error.main' : 'success.main' }}
						>
							{inquiry.fields['Action'] === 'sell' ? 'Ask' : 'Bid'}
						</Typography>
					</Stack>

					<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} flex={1}>
						<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
							Price
						</Typography>
						<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
							{isValidNumber(inquiry.fields['Price']) ? d3.format('$,.2f')(inquiry.fields['Price']) : ''}
						</Typography>
					</Stack>

					<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} flex={1}>
						<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
							QTY
						</Typography>
						<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
							{isValidNumber(inquiry.fields['QTY']) ? d3.format(',.0f')(inquiry.fields['QTY']) : ''}
						</Typography>
					</Stack>

					<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} flex={1}>
						<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
							Block size
						</Typography>
						<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
							{isValidNumber(inquiry.fields['Block Size Ticket'])
								? d3.format('$,.2f')(inquiry.fields['Block Size Ticket'])
								: ''}
						</Typography>
					</Stack>

					<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} flex={1}>
						<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
							Status
						</Typography>
						<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
							{inquiry.fields['Public Status']}
						</Typography>
					</Stack>

					<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} flex={1}>
						<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
							Type
						</Typography>
						<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
							{getInqType(inquiry)}
						</Typography>
					</Stack>

					<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} flex={1}>
						<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
							Buyer Exercise
						</Typography>
						<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
							{inquiry.fields['Buyer Exercise']}
						</Typography>
					</Stack>
				</Stack>
			</InquiryContainer>
		</PaperContainer>
	);
};

const getInqType = (inquiry) => {
	//if it has the Type field, just use that, if it has the "Security Type" field concatenate it's values (it's an array)
	if (inquiry.fields['Type']) {
		return inquiry.fields['Type'];
	} else if (inquiry.fields['Security Type']) {
		return inquiry.fields['Security Type'].join(', ');
	} else {
		return '';
	}
};

export default InquiryRecordItem;
