import PageTitle from '../../components/PageTitle';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Stack,
	Typography,
} from '@mui/material';
import { EmptyContainer } from '../DealsPageView/dealsPageView.styles';
import { useAuthContext } from '../../context';
import { useMemo, useRef, useState } from 'react';
import InstitutionalFirm from './components/InstitutionalFirm';
import AccreditedInvestor from './components/AccreditedInvestor';
import { apiClient, endpoints } from '../../utils';
import { toast } from 'react-toastify';
import { strings } from '../../utils/strings';

const AccreditationQuestionnaireView = () => {
	const { user, reloadUserData } = useAuthContext();
	const formRef = useRef(null);
	const [isFailed, setFailed] = useState(false);

	const isInstitutionalFirm = useMemo(() => {
		return user?.fields?.Type === 'An Institutional Firm';
	}, [user]);

	const isAccreditedInvestor = useMemo(() => {
		return user?.fields?.Type === 'An Accredited Investor';
	}, [user]);

	const dialogTitle = useMemo(() => {
		if (isInstitutionalFirm) {
			return 'Accreditation Questionnaire';
		}

		if (isAccreditedInvestor) {
			return 'Accreditation Questionnaire';
		}

		return '';
	}, [isInstitutionalFirm, isAccreditedInvestor]);

	const handleSubmit = async (values) => {
		const payload = JSON.stringify({ accreditation: JSON.stringify(values) });
		let isValid = true;

		if (values.representations === true) {
			isValid = false;
		} else {
			let hasYes = false;

			for (const key in values) {
				if (key !== 'representations' && values[key] === 'Yes') {
					hasYes = true;
				}
			}

			if (!hasYes) {
				isValid = false;
			}
		}

		setFailed(!isValid);

		try {
			const res = await apiClient.post(endpoints.submitAccreditationForm, payload, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (res.status === 200 && isValid) {
				reloadUserData();
			}
		} catch (err) {
			toast.error(strings.errorMessages.inquiry.saveAnswers);
			console.log('handleSubmit.err', err);
		}
	};

	const onFailedDialog = () => {
		reloadUserData();
		setFailed(false);
	};

	const dialogContent = useMemo(() => {
		if (isInstitutionalFirm) {
			return <InstitutionalFirm formRef={formRef} handleSubmit={handleSubmit} />;
		}

		if (isAccreditedInvestor) {
			return <AccreditedInvestor formRef={formRef} handleSubmit={handleSubmit} />;
		}

		return <></>;
	}, [isInstitutionalFirm, isAccreditedInvestor]);

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Home',
					},
				]}
				title={'Dashboard'}
			/>

			<EmptyContainer>
				<Stack spacing={3} alignItems={'center'}>
					<img src={'/illustrations/dark/notFound.svg'} style={{ height: 252 }} alt="No trades" />
					<Stack spacing={1} alignItems={'center'}>
						<Typography variant={'h5'}>You have no activity.</Typography>
					</Stack>
				</Stack>
			</EmptyContainer>

			<Dialog
				open={!user?.isAccredited}
				fullWidth
				maxWidth={'md'}
				slotProps={{
					backdrop: {
						sx: {
							backdropFilter: 'blur(6px)',
							backgroundColor: 'rgba(0, 0, 0, 0.50)',
						},
					},
				}}
			>
				<DialogTitle>{dialogTitle}</DialogTitle>
				<DialogContent>{dialogContent}</DialogContent>
				<DialogActions
					sx={{
						borderTop: '1px solid rgba(255, 255, 255, 0.12)',
						padding: '1rem',
						marginTop: '0.3rem',
						justifyContent: 'center',
					}}
				>
					<Button
						autoFocus
						variant="contained"
						color="primary"
						size="large"
						onClick={() => {
							formRef && formRef.current.submitForm();
						}}
					>
						Complete Accreditation Questionnaire
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={isFailed} onClose={onFailedDialog}>
				<DialogTitle>Accreditation Check-In</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Hello!
						<br />
						<br />
						Thank you again and congratulations on creating an account on ShareWell! In choosing ‘No’ for your
						accreditation onboarding answers we are noting your non-accredited status and have provided you access
						accordingly. If you would like to revisit or feel that this is error please let us know and we will look to
						resubmit for updated responses.
						<br />
						<br />
						Sincerely SaxonWeber | ShareWell
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onFailedDialog}>OK</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AccreditationQuestionnaireView;
