import { Box, Divider, Grid, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';

const InquiryQuestionnaireSkeleton = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	if (isMobile) {
		return (
			<Stack spacing={2} paddingTop={2}>
				<Skeleton variant="rectangular" width={'50%'} height={24} />
				<Stack spacing={3}>
					<Stack spacing={'6px'}>
						<Skeleton variant="rectangular" width={'100%'} height={26} />
						<Skeleton variant="rectangular" width={'100%'} height={40} />
					</Stack>
					<Skeleton variant="rectangular" width={'100%'} height={200} />
					<Skeleton variant="rectangular" width={'100%'} height={200} />
				</Stack>
			</Stack>
		);
	}

	return (
		<Stack spacing={3} paddingTop={3}>
			<Skeleton variant="rectangular" width={250} height={24} />
			<Box>
				<Grid container spacing={3}>
					<Grid item xs={5}>
						<Skeleton variant="rectangular" width={'100%'} height={350} />
					</Grid>
					<Grid item xs={1}>
						<Divider orientation={'vertical'} sx={{ width: 'calc(100% / 2)' }} />
					</Grid>
					<Grid item xs={6}>
						<Stack spacing={'28px'}>
							<Skeleton variant="rectangular" width={'80%'} height={46} />
							<Skeleton variant="rectangular" width={'60%'} height={46} />
							<Skeleton variant="rectangular" width={'100%'} height={46} />
							<Skeleton variant="rectangular" width={'80%'} height={46} />
							<Skeleton variant="rectangular" width={'60%'} height={46} />
						</Stack>
					</Grid>
				</Grid>
			</Box>
		</Stack>
	);
};

export default InquiryQuestionnaireSkeleton;
