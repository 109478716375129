import { useState, useEffect } from 'react';
import * as d3 from 'd3-latest';
import { airtableDateParse, endpoints } from './utils';

export const useData = ({ companyId }) => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const token = localStorage.getItem('JWT');

	const headers = {
		headers: new Headers({
			Authorization: `Bearer ${token}`,
		}),
	};

	useEffect(() => {
		if (companyId) {
			const promises = [
				d3.json(endpoints.companyDetails.overviewUrl.replace('{companyId}', companyId), headers),
				d3.json(endpoints.companyDetails.pricesUrl.replace('{companyId}', companyId), headers),
				// d3.json(endpoints.companyDetails.bubbleUrl.replace('{companyId}', companyId), headers),
				d3.json(endpoints.companyDetails.fundingUrl.replace('{companyId}', companyId), headers),
				d3.json(endpoints.companyDetails.investorsUrl.replace('{companyId}', companyId), headers),
			];

			Promise.all(promises)
				.then((data) => {
					const priceData = [];

					const sortedRaw = data[1]
						.filter((item) => item.fields.Date)
						.sort((a, b) => airtableDateParse(a.fields.Date).getTime() - airtableDateParse(b.fields.Date).getTime());

					sortedRaw.forEach((item) => {
						let current = {
							par: null,
							bid: null,
							ask: null,
							market: null,
							institutional: null,
							retail: null,
							high: null,
							low: null,
							trading: null,
						};

						if (+item.fields.Bid) {
							current.bid = +item.fields.Bid;
							current.institutional = current.bid * +item.fields.Institutional;
						}
						if (+item.fields.Ask) {
							current.ask = +item.fields.Ask;
							current.retail = current.ask * +item.fields.Retail;
						}
						if (+item.fields.Par) current.par = +item.fields.Par;
						if (+item.fields.Bid || +item.fields.Ask) current.market = (current.bid + current.ask) / 2;
						if (+item.fields.Bid) current.bid = +item.fields.Bid;

						priceData.push({
							date: airtableDateParse(item.fields.Date),
							bid: current.bid,
							ask: current.ask,
							par: current.par,
							market: current.market,
							institutional: current.institutional,
							retail: current.retail,
							high: +item.fields['High'],
							low: +item.fields['Low'],
							trading: +item.fields['Trading'],
							tradingActivitySell: +item.fields['TradingActivitySell'],
							sharesIssued: +item.fields['Shares Issued Calc'],
							postMoneyValuation: +item.fields['Post Money Valuation'],
						});
					});

					const lph = !priceData || priceData.length === 0 ? null : priceData[priceData.length - 1];

					const companyData = {};
					const companyRaw = data[0];
					if (data[0]) {
						companyData.id = companyRaw.id;
						companyData.name = companyRaw.fields['Legal Name'];
						companyData.location = companyRaw.fields['Location'];
						companyData.sectors = companyRaw.fields['Sectors'];
						companyData.website = companyRaw.fields['Website'];
						companyData.rofr = companyRaw.fields['ROFR'];
						companyData.asignee = companyRaw.fields['Asignee'];
						companyData.activity = companyRaw.fields['Activity'];

						companyData.institutionalCoeff = lph?.['Institutional'];
						companyData.institutional = lph?.['Institutional'];
						companyData.formattedFields = companyRaw.fields['formattedFields'];
						if (!companyData.institutionalCoeff) companyData.institutionalCoeff = 0.8;

						companyData.retailCoeff = lph?.['Retail'];
						if (!companyData.retailCoeff) companyData.retailCoeff = 1.05;

						companyData.sharesIssued = lph?.['Shares Issued Calc'];
						companyData.postMoneyValuation = lph?.['Post Money Valuation'];
						companyData.par = lph?.['Par'];
						companyData.similar = companyRaw.fields['Similar'];
						companyData.tradingActivitySell = lph?.tradingActivitySell;
						companyData.latestPriceDate = lph?.date;
					}

					// const bubbleData = [];

					// data[2].forEach((item) => {
					// 	bubbleData.push({
					// 		date: airtableDateParse(item.fields.Date),
					// 		price: +item.fields.Price,
					// 		quantity: +item.fields.Quantity,
					// 	});
					// });

					const fundingData = [];
					data[2].forEach((item) => {
						fundingData.push({
							date: airtableDateParse(item.fields.Date),
							security: item.fields.Security,
							multiple: +item.fields.Multiple,
							postMoneyValuation: +item.fields['Post Money Valuation'],
							sharesAuthorized: +item.fields.SharesAuthorized,
							priceShare: +item.fields.PriceShare,
							notes: item.fields.Notes,
						});
					});

					setData({
						priceData: priceData,
						// bubbleData: bubbleData,
						overviewData: companyData,
						fundingData: fundingData.filter((d) => d.date)?.sort((a, b) => b.date.getTime() - a.date.getTime()),
						investors: data?.[3] ?? [],
					});
				})
				.catch((e) => console.log('useData error: ', e))
				.finally(() => setLoading(false));
		} else {
			setLoading(false);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return {
		data,
		loading,
	};
};
