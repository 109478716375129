import PageTitle from '../../components/PageTitle';
import ThankYouBlock from '../../components/ThankYouBlock';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useData } from '../../useData';

const CompanyPageSuccessView = () => {
	const { companyId } = useParams();
	const { data } = useData({ companyId });

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Companies',
						link: '/companies',
					},
					{
						title: data.overviewData?.name,
						link: `/company/${companyId}`,
					},
				]}
			/>
			<Grid item xs={6} marginTop={'60px'} marginBottom={'60px'}>
				<ThankYouBlock
					title={'Thank you for your interest!'}
					description={'We will be in touch shortly.'}
					buttonText={'home'}
					buttonLink={'/'}
				/>
			</Grid>
		</>
	);
};

export default CompanyPageSuccessView;
