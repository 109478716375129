import * as Yup from 'yup';

export const initialValues = {
	'First Name': '',
	'Last Name': '',
	Type: '',
	Status: '',
	UserStage: '',
	Company: '',
	Location: '',
	Notes: '',
	'Compliance Required': '',
	'Compliance Approved': false,
	Follows: [],
	Portfolio: [],
	Tier: '',
	Triad: [],
	VIP: false,
	'Structured Deals': false,
};

export const schema = Yup.object({
	'First Name': Yup.string().required('Required'),
	'Last Name': Yup.string().required('Required'),
	UserStage: Yup.string().required('Required'),
	Tier: Yup.string().when('Type', {
		is: (type) => type !== 'An Individual Shareholder',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	'Compliance Required': Yup.string().when('Type', {
		is: (type) => type !== 'An Individual Shareholder',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
});
