import styled from 'styled-components';

export const SelectedToolbar = styled.div(() => {
	return `
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 28px;
	padding-right: 16px;
	padding-top: 16px;
	padding-bottom: 16px;
	border-bottom: 1px rgba(81, 81, 81, 1) solid;
`;
});
