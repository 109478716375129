import canISellMyPrivateCompanyShares from './CanISellMyPrivateCompanyShares';
import whatIsTheProcessForSellingPrivateCompanyShares from './WhatIsTheProcessForSellingPrivateCompanyShares';
import doesMyCompanyAllowsMetoSellMyShares from './DoesMyCompanyAllowsMetoSellMyShares';
import howDoIValueMyPrivateCompanyShares from './HowDoIValueMyPrivateCompanyShares';
import whatAreTheFeesToSellMyShares from './WhatAreTheFeesToSellMyShares';
import differenceBetweenSharesStockOptionsAndRSUs from './DifferenceBetweenSharesStockOptionsAndRSUs';
import whatHappensToSharesIfLeaveCompany from './WhatHappensToSharesIfLeaveCompany';
import companyApprovalOfTransaction from './CompanyApprovalOfTransaction';
import whyRightOfFirstRefusalMatters from './WhyRightOfFirstRefusalMatters';
import whyProvidingYourSSNIsNecessary from './WhyProvidingYourSSNIsNecessary';
import whatIsPreIPOInvesting from './WhatIsPreIPOInvesting';
import howToBuySharesInAPrivateCompany from './HowToBuySharesInAPrivateCompany';
import differenceBetweenDirectOwnershipAndInvestingInAFund from './DifferenceBetweenDirectOwnershipAndInvestingInAFund';
import navigatingSecondaryTransaction from './NavigatingSecondaryTransaction';
import whoCanBuyPrivateMarketStock from './WhoCanBuyPrivateMarketStock';
import feesForBuyingPrivateCompanyShares from './FeesForBuyingPrivateCompanyShares';
import howToValuePrivateCompanyShares from './HowToValuePrivateCompanyShares';
import directVsFundInvestment from './DirectVsFundInvestment';
import directShareAcquisition from './DirectShareAcquisition';
import accreditedInvestorsDefinition from './AccreditedInvestorsDefinition';
import accreditedInvestorsEntitiesDefinition from './AccreditedInvestorsEntitiesDefinition';
import risksAndConsiderationsDirectShareAcquisition from './RisksAndConsiderationsDirectShareAcquisition';
import additionalCostsSale from './AdditionalCostsSale';

import './style.css';

export default [
	{
		questionId: 'canISellMyPrivateCompanyShares',
		questionTitle: 'Can I Sell My Private Company Shares?',
		questionContent: canISellMyPrivateCompanyShares,
		userType: 'Shareholder',
	},
	{
		questionId: 'whatIsTheProcessForSellingPrivateCompanyShares',
		questionTitle: 'What is the Process for Selling Private Company Shares through ShareWell?',
		questionContent: whatIsTheProcessForSellingPrivateCompanyShares,
		userType: 'Shareholder',
	},
	{
		questionId: 'doesMyCompanyAllowsMetoSellMyShares',
		questionTitle: 'How Do I Find Out if My Company Allows Me to Sell My Shares?',
		questionContent: doesMyCompanyAllowsMetoSellMyShares,
		userType: 'Shareholder',
	},
	{
		questionId: 'howDoIValueMyPrivateCompanyShares',
		questionTitle: 'How Do I Value My Private Company Shares?',
		questionContent: howDoIValueMyPrivateCompanyShares,
		userType: 'Shareholder',
	},
	{
		questionId: 'whatAreTheFeesToSellMyShares',
		questionTitle: 'What Are the Fees to Sell My Shares?',
		questionContent: whatAreTheFeesToSellMyShares,
		userType: 'Shareholder',
	},
	{
		questionId: 'differenceBetweenSharesStockOptionsAndRSUs',
		questionTitle: 'What is the Difference Between Shares, Stock Options, and RSUs?',
		questionContent: differenceBetweenSharesStockOptionsAndRSUs,
		userType: 'Shareholder',
	},
	{
		questionId: 'whatHappensToSharesIfLeaveCompany',
		questionTitle: 'What Happens to My Shares or Stock Options if I Leave the Company?',
		questionContent: whatHappensToSharesIfLeaveCompany,
		userType: 'Shareholder',
	},
	{
		questionId: 'companyApprovalOfTransaction',
		questionTitle: 'Company Approval of a Transaction:',
		questionContent: companyApprovalOfTransaction,
		userType: 'Shareholder',
	},
	{
		questionId: 'whyRightOfFirstRefusalMatters',
		questionTitle: 'Why Right of First Refusal Matters:',
		questionContent: whyRightOfFirstRefusalMatters,
		userType: 'Shareholder',
	},
	{
		questionId: 'whyProvidingYourSSNIsNecessary',
		questionTitle: 'Why Providing Your SSN is Necessary:',
		questionContent: whyProvidingYourSSNIsNecessary,
		userType: 'Shareholder',
	},
	{
		questionId: 'whatIsPreIPOInvesting',
		questionTitle: 'What is Pre-IPO Investing?',
		questionContent: whatIsPreIPOInvesting,
		userType: 'Investor',
	},
	{
		questionId: 'howToBuySharesInAPrivateCompany',
		questionTitle: 'How to Buy Shares in a Private Company:',
		questionContent: howToBuySharesInAPrivateCompany,
		userType: 'Investor',
	},
	{
		questionId: 'differenceBetweenDirectOwnershipAndInvestingInAFund',
		questionTitle: 'What is the difference between Direct Ownership and investing in a fund?',
		questionContent: differenceBetweenDirectOwnershipAndInvestingInAFund,
		userType: 'Investor',
	},
	{
		questionId: 'navigatingSecondaryTransaction',
		questionTitle: 'Navigating a Secondary Transaction: Execution, Clearing, and Settlement',
		questionContent: navigatingSecondaryTransaction,
		userType: 'Investor',
	},
	{
		questionId: 'whoCanBuyPrivateMarketStock',
		questionTitle: 'Who Can Buy Private Market Stock?',
		questionContent: whoCanBuyPrivateMarketStock,
		userType: 'Investor',
	},
	{
		questionId: 'feesForBuyingPrivateCompanyShares',
		questionTitle: 'Fees for Buying Private Company Shares through ShareWell',
		questionContent: feesForBuyingPrivateCompanyShares,
		userType: 'Investor',
	},
	{
		questionId: 'howToValuePrivateCompanyShares',
		questionTitle: 'How to Value Private Company Shares?',
		questionContent: howToValuePrivateCompanyShares,
		userType: 'Investor',
	},
	{
		questionId: 'directVsFundInvestment',
		questionTitle: 'Direct Investment vs. Fund Investment: Understanding the Distinctions',
		questionContent: directVsFundInvestment,
		userType: 'Investor',
	},
	{
		questionId: 'directShareAcquisition',
		questionTitle: 'Direct Share Acquisition with ShareWell',
		questionContent: directShareAcquisition,
		userType: 'Investor',
	},
	{
		questionId: 'accreditedInvestorsDefinition',
		questionTitle: 'Accredited Investors: Definition for Individuals',
		questionContent: accreditedInvestorsDefinition,
		userType: 'Investor',
	},
	{
		questionId: 'accreditedInvestorsEntitiesDefinition',
		questionTitle: 'Accredited Investors: Definition for Entities',
		questionContent: accreditedInvestorsEntitiesDefinition,
		userType: 'Investor',
	},
	{
		questionId: 'risksAndConsiderationsDirectShareAcquisition',
		questionTitle: 'Risks and Special Considerations for Direct Share Acquisition',
		questionContent: risksAndConsiderationsDirectShareAcquisition,
		userType: 'Investor',
	},
	{
		questionId: 'additionalCostsSale',
		questionTitle: 'Additional Costs Associated with Completing a Sale',
		questionContent: additionalCostsSale,
		userType: 'Investor',
	},
];
