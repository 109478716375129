import { Button, Grid, Stack, useTheme } from '@mui/material';
import Logo from '../Logo';
import Carousel from 'react-material-ui-carousel';
import LoginForm from '../LoginForm';
import { useLocation, useNavigate } from 'react-router-dom';
import RegisterForm from '../RegisterForm';
import { MainMobileGrid } from '../../styles/common.styles';
import { CenterWrapper } from '../LandingPage/loginPage.styles';
import { getSlides } from '../../views/LandingPageView/slides';

const MobileLandingPage = () => {
	const defaultTheme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const isOnboarding = location.pathname === '/';
	const isSignup = location.pathname.toLowerCase().includes('signup');
	const isSignin = location.pathname.toLowerCase().includes('signin');

	const renderHeader = () => {
		return (
			<Stack
				justifyContent={isSignup ? 'space-between' : 'center'}
				width={'100%'}
				direction={'row'}
				alignItems={'center'}
			>
				<Logo color={defaultTheme.palette.text.primary} isAbsolute={false} />
				{isSignup ? (
					<Button size={'large'} variant={'text'} onClick={() => navigate('/signin')}>
						sign in
					</Button>
				) : (
					<></>
				)}
			</Stack>
		);
	};

	if (isOnboarding) {
		return (
			//68px - container vertical padding
			<MainMobileGrid height={window.innerHeight - 68}>
				<Grid item height={'100%'} width={'100%'} xs={4}>
					<Stack height={'100%'} width={'100%'} justifyContent={'space-between'}>
						{renderHeader()}
						<Carousel
							animation={'slide'}
							sx={{ height: '75%' }}
							indicatorContainerProps={{ style: { position: 'absolute', bottom: 0 } }}
							indicatorIconButtonProps={{ style: { color: defaultTheme.palette.grey['800'] } }}
							activeIndicatorIconButtonProps={{ style: { color: defaultTheme.palette.grey['400'] } }}
							height={'100%'}
						>
							{getSlides(false, false, false).map((slide, index) => (
								<CenterWrapper container key={index}>
									<Grid item xs={12}>
										{slide}
									</Grid>
								</CenterWrapper>
							))}
						</Carousel>
						<Stack spacing={'12px'} width={'100%'}>
							<Button variant={'contained'} onClick={() => navigate('/signup')}>
								Create account
							</Button>
							<Button variant={'outlined'} onClick={() => navigate('/signin')}>
								sign in
							</Button>
						</Stack>
					</Stack>
				</Grid>
			</MainMobileGrid>
		);
	}

	if (isSignin) {
		return (
			<MainMobileGrid height={window.innerHeight - 68}>
				<Grid item height={'100%'} width={'100%'} xs={4}>
					{renderHeader()}
					<CenterWrapper container>
						<Grid item xs={12}>
							<LoginForm />
						</Grid>
					</CenterWrapper>
				</Grid>
			</MainMobileGrid>
		);
	}

	return (
		<MainMobileGrid rowSpacing={4}>
			<Grid item height={'100%'} width={'100%'} xs={4}>
				{renderHeader()}
			</Grid>
			<Grid item height={'100%'} width={'100%'} xs={4}>
				<RegisterForm />
			</Grid>
		</MainMobileGrid>
	);
};

export default MobileLandingPage;
