import {
	Autocomplete,
	Button,
	Card,
	Checkbox,
	Grid,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React, { useEffect, useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { apiClient, endpoints } from '../../../../utils';
import { toast } from 'react-toastify';
import { NotFoundContainer } from '../../../../components/CompanyList/companyList.styles';
import TeamMemberDrawer from './components/TeamMemberDrawer';
import DeleteDialog from './components/DeleteDialog';
import { strings } from '../../../../utils/strings';

const TeamMembersTab = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultTheme = useTheme();

	const [isReadyToLoad, setReadyToLoad] = useState(false);
	const [isFirstLoading, setFirstLoading] = useState(true);

	const [teamMembers, setTeamMembers] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const [roleFilters, setRoleFilters] = useState([]);
	const [selectedRoleFilters, setSelectedRoleFilters] = useState([]);

	const [selectedObj, setSelectedObj] = useState(null);
	const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

	const [isDeleteOpen, setDeleteOpen] = useState(false);

	useEffect(() => {
		// firstly data
		loadData().then((res) => {
			setFilteredData(res);
			const uniqueRoles = new Set();

			res.forEach((item) => {
				if (item.fields && item.fields.Role) {
					uniqueRoles.add(item.fields.Role);
				}
			});
			setRoleFilters([...uniqueRoles]);
			setTeamMembers(res);
			isFirstLoading && setFirstLoading(false);
		});
	}, []);

	useEffect(() => {
		// secondly load parameters
		if (roleFilters.length) {
			const roleParam = searchParams.get('role');
			if (roleParam && roleParam.trim()) {
				const roles = roleParam.trim().split(',');

				const found = roles.reduce((accumulator, currentValue) => {
					const found = roleFilters.find((item) => item === currentValue);
					if (found) {
						return [...accumulator, found];
					}

					return accumulator;
				}, []);

				setSelectedRoleFilters(found);
			} else {
				setSelectedRoleFilters([]);
			}
		}

		!isReadyToLoad &&
			setTimeout(() => {
				setReadyToLoad(true);
			}, 300);
	}, [searchParams, roleFilters]);

	useEffect(() => {
		//thirdly filter data
		if (isReadyToLoad) {
			if (selectedRoleFilters.length) {
				setFilteredData(
					teamMembers.filter((item) => {
						if (item.fields && item.fields.Role) {
							return selectedRoleFilters.includes(item.fields.Role);
						}
						return false;
					})
				);
			} else {
				setFilteredData(teamMembers);
			}
		}
	}, [setFilteredData, isReadyToLoad, teamMembers, selectedRoleFilters]);

	useEffect(() => {
		//deletion
		if (location?.state?.deleteId) {
			setTeamMembers((prevState) => prevState.filter((item) => item.id !== location?.state?.deleteId));
			setFilteredData((prevState) => prevState.filter((item) => item.id !== location?.state?.deleteId));
		}
	}, [location.pathname, location?.state?.deleteId]);

	useEffect(() => {
		//update
		const updateObjectId = location?.state?.updateObjectId;
		if (
			updateObjectId &&
			(updateObjectId === 'new' || filteredData.find((obj) => obj.id === location?.state?.updateObjectId))
		) {
			loadData().then((res) => {
				const found = res.find((resItem) => resItem.id === location?.state?.updateObjectId);
				if (found) {
					setTeamMembers((prevState) =>
						prevState.map((item) => {
							if (item.id === location?.state?.updateObjectId) {
								return found ?? item;
							} else {
								return item;
							}
						})
					);

					setFilteredData((prevState) =>
						prevState.map((item) => {
							if (item.id === location?.state?.updateObjectId) {
								return found ?? item;
							} else {
								return item;
							}
						})
					);
				} else {
					setTeamMembers(res);
					setFilteredData(res);
				}

				const uniqueRoles = new Set();

				res.forEach((item) => {
					if (item.fields && item.fields.Role) {
						uniqueRoles.add(item.fields.Role);
					}
				});
				setRoleFilters([...uniqueRoles]);
			});
		}
	}, [location.pathname, location?.state?.updateObjectId]);

	const loadData = async () => {
		try {
			const res = await apiClient.get(endpoints.associates);
			return res.data ?? [];
		} catch (err) {
			toast.error(strings.errorMessages.teamMember.loadMany);
			return [];
		}
	};

	const handleActionsClick = (event, obj) => {
		setSelectedObj(obj);
		setMenuAnchorEl(event.currentTarget);
	};

	const handleViewDetails = () => {
		navigate('/settings/team/' + selectedObj.id + window.location.search, {
			state: { preventScrollReset: true },
		});
		setMenuAnchorEl(null);
	};

	const addNew = () => {
		navigate('/settings/team/new' + window.location.search, {
			state: { preventScrollReset: true },
		});
	};

	return (
		<Grid container rowSpacing={2} columnSpacing={3}>
			{isFirstLoading && (
				<Grid item xs={12}>
					<Skeleton variant="rectangular" height={300} />
				</Grid>
			)}
			{!isFirstLoading && !teamMembers.length && (
				<Grid item xs={12}>
					<NotFoundContainer>
						<Stack spacing={3} alignItems={'center'}>
							<img src={'/illustrations/dark/userPlus.svg'} style={{ height: 200 }} alt="Not found" />
							<Stack spacing={3} alignItems={'center'}>
								<Typography variant={'h5'}>No team members found</Typography>
								<Button variant="contained" size={'medium'} onClick={addNew}>
									Add new team member
								</Button>
							</Stack>
						</Stack>
					</NotFoundContainer>
				</Grid>
			)}
			{!isFirstLoading && teamMembers.length > 0 && (
				<>
					<Grid item xs={6}>
						<Autocomplete
							multiple
							size={'medium'}
							options={roleFilters}
							value={selectedRoleFilters}
							onChange={(_, value) => {
								const newSearchParams = {};
								if (value.length) {
									newSearchParams.role = value.join(',');
								}
								setSearchParams(newSearchParams);
							}}
							renderOption={(props, option, { selected }) => (
								<li {...props}>
									<Checkbox
										icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
										checkedIcon={<CheckBoxIcon fontSize="small" />}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option}
								</li>
							)}
							renderInput={(params) => <TextField {...params} label="Role" placeholder="Select roles" />}
						/>
					</Grid>
					<Grid item xs={6}>
						<Stack alignItems={'flex-end'} justifyContent={'center'} height={'100%'}>
							<Button variant="contained" size={'medium'} onClick={addNew}>
								Add new team member
							</Button>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<TableContainer component={Card}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell sx={{ color: 'text.secondary' }}>Full Name</TableCell>
										<TableCell sx={{ color: 'text.secondary' }}>Role</TableCell>
										<TableCell sx={{ color: 'text.secondary' }}>Email address</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredData.map(({ id, fields }) => {
										return (
											<TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell>
													<Stack spacing={0} direction={'column'}>
														<Link
															underline={'hover'}
															variant={'body1'}
															component={RouterLink}
															to={'/settings/team/' + id + window.location.search}
															state={{ preventScrollReset: true }}
														>
															<Typography variant={'body2'}>{fields['Full Name']}</Typography>
														</Link>
													</Stack>
												</TableCell>

												<TableCell>{fields.Role}</TableCell>
												<TableCell>{fields.Email}</TableCell>
												<TableCell style={{ width: 70 }}>
													<IconButton onClick={(e) => handleActionsClick(e, { id, fields })}>
														<MoreVert sx={{ color: 'text.secondary' }} />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Menu
							anchorEl={menuAnchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							open={Boolean(menuAnchorEl)}
							onClose={() => {
								setMenuAnchorEl(null);
								selectedObj(null);
							}}
						>
							<MenuItem onClick={handleViewDetails}>View details</MenuItem>
							<MenuItem
								sx={{ color: defaultTheme.palette.error.main }}
								onClick={() => {
									setDeleteOpen(true);
									setMenuAnchorEl(null);
								}}
							>
								Delete
							</MenuItem>
						</Menu>
						<DeleteDialog
							object={selectedObj}
							onSuccess={() => {
								setFilteredData((prevState) => prevState.filter((item) => item.id !== selectedObj.id));
								setTeamMembers((prevState) => prevState.filter((item) => item.id !== selectedObj.id));
								setSelectedObj(null);
							}}
							open={isDeleteOpen}
							handleClose={() => {
								setDeleteOpen(false);
								setMenuAnchorEl(null);
								setSelectedObj(null);
							}}
						/>
					</Grid>
				</>
			)}
			<TeamMemberDrawer teamMembers={teamMembers} />
		</Grid>
	);
};

export default TeamMembersTab;
