import { useActivityFeedContext } from '../../../../context';
import { useEffect, useState } from 'react';
import { Box, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import BidActivityFeedType from '../BidActivityFeedType';
import { useLocation } from 'react-router-dom';

const BidSnackbar = () => {
	const { lastNewFeed, isOpen: isActivityFeedOpen } = useActivityFeedContext();
	const location = useLocation();
	const [isOpen, setOpen] = useState(false);
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	useEffect(() => {
		setOpen(Boolean(lastNewFeed) && !isActivityFeedOpen && !location.pathname.includes('bid'));
	}, [lastNewFeed]);

	const onClose = () => {
		setOpen(false);
	};

	return (
		<Snackbar open={isOpen} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
			<Box width={isMobile ? window.innerWidth - 8 : 410} maxWidth={410}>
				{lastNewFeed && <BidActivityFeedType bid={lastNewFeed} key={lastNewFeed.id} isSnackbar={true} />}
			</Box>
		</Snackbar>
	);
};

export default BidSnackbar;
