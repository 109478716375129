import {
	CardActionArea,
	CardContent,
	Divider,
	IconButton,
	Stack,
	SvgIcon,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { MonetizationOn, Star, StarBorder } from '@mui/icons-material';
import { CompanyCardContainer, CompanyCardHeader } from './companyCard.styles';
import { grey, yellow } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

const CompanyCard = (props) => {
	const {
		id,
		companyName,
		sectorName,
		sectorIcon,
		valuation,
		elevation = 3,
		isStar = false,
		onStarClick,
		link,
		onClick,
		dynamicFields,
	} = props;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const onActionClick = (e) => {
		if (onStarClick) {
			e.stopPropagation();
			e.preventDefault();
			onStarClick(id);
		}
	};

	return (
		<CardActionArea
			onClick={onClick ? () => onClick(id) : undefined}
			disabled={!Boolean(onClick || link)}
			component={onClick ? undefined : RouterLink}
			to={link ?? '/'}
			sx={{ height: '100%' }}
		>
			<CompanyCardContainer variant={'elevation'} elevation={elevation}>
				<CompanyCardHeader
					title={companyName}
					action={
						<IconButton disabled={!Boolean(onStarClick)} onClick={onActionClick}>
							{isStar && <Star sx={{ color: yellow[800], fontSize: 24 }} />}
							{!isStar && onStarClick && <StarBorder sx={{ color: grey[500], fontSize: 24 }} />}
						</IconButton>
					}
					sx={{ paddingBottom: 0 }}
					data-cy={'company-card-header'}
				/>
				<CardContent sx={{ paddingTop: isMobile ? 1 : undefined }}>
					<Stack spacing={isMobile ? '12px' : 2}>
						<Stack spacing={isMobile ? '4px' : 1}>
							<Stack spacing={1} direction={'row'} alignItems={'center'}>
								<SvgIcon component={sectorIcon} sx={{ fontSize: 16, color: 'text.secondary' }} />
								<Typography variant="body2" color={'text.secondary'}>
									{sectorName}
								</Typography>
							</Stack>
							{(!dynamicFields || Object.keys(dynamicFields).length === 0) && (
								<Stack spacing={1} direction={'row'} alignItems={'center'}>
									<MonetizationOn sx={{ fontSize: 16, color: 'primary.main' }} />
									<Typography variant="body2" color={'primary.main'}>
										{valuation}
									</Typography>
								</Stack>
							)}
						</Stack>
						{dynamicFields && Object.keys(dynamicFields).length > 0 && (
							<>
								<Divider />
								<Stack spacing={isMobile ? '6px' : 1}>
									{Object.keys(dynamicFields).map((key) => {
										if (key === 'Weekly Change') {
											const value = dynamicFields[key];

											return (
												<Stack key={key} justifyContent={'space-between'} direction={'row'}>
													<Typography variant={'body2'} color={'text.secondary'}>
														{key}
													</Typography>
													<Typography
														variant={'body2'}
														color={
															value !== '-'
																? _.startsWith(value, '+')
																	? 'success.main'
																	: 'error.main'
																: 'text.secondary'
														}
													>
														{value}
													</Typography>
												</Stack>
											);
										}
										return (
											<Stack key={key} justifyContent={'space-between'} direction={'row'}>
												<Typography variant={'body2'} color={'text.secondary'}>
													{key}
												</Typography>
												<Typography variant={'body2'}>{dynamicFields[key]}</Typography>
											</Stack>
										);
									})}
								</Stack>
							</>
						)}
					</Stack>
				</CardContent>
			</CompanyCardContainer>
		</CardActionArea>
	);
};

export default CompanyCard;
