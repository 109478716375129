import React, { useEffect, useMemo, useState } from 'react';
import { DropdownLink } from '../../styles';
import {
	Avatar,
	Box,
	Checkbox,
	Divider,
	FormHelperText,
	InputAdornment,
	ListItem,
	Menu,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { AddOutlined, KeyboardArrowDownOutlined, Search } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { apiClient, endpoints, getCorrectStringFieldFromObject, getNestedFormValue } from '../../../../utils';
import { getUserAvatarURL } from '../../../../utils/getUserAvatar';
import { ValueTypography } from '../../../UserDrawer/styles';
import { useDebouncedCallback } from 'use-debounce';

const CustomizedUsersDropdown = (props) => {
	const { fieldName, isMultiple, error } = props;
	const { values, setFieldValue } = useFormikContext();
	const showedNumber = 5;

	const value = useMemo(() => {
		if (fieldName.includes('.')) {
			return getNestedFormValue(values, fieldName);
		} else {
			return values[fieldName];
		}
	}, [values, fieldName]);

	const [dropdownItems, setDropdownItems] = useState([]);
	const selectedUsers = useMemo(() => {
		if (dropdownItems && dropdownItems.length > 0 && value) {
			return value.map((id) => dropdownItems.find((obj) => obj.id === id));
		} else {
			return [];
		}
	}, [value, dropdownItems]);

	const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
	const dropdownMenuItems = useMemo(() => {
		const selectedItems = dropdownItems.filter((filteredUser) =>
			selectedUsers.some((selected) => {
				if (!selected || !filteredUser) return false;
				return selected.id === filteredUser.id;
			})
		);

		const nonSelected = dropdownItems.filter(
			(filteredUser) =>
				!selectedUsers.some((selected) => {
					if (!selected || !filteredUser) return false;
					return selected.id === filteredUser.id;
				})
		);

		return selectedItems.concat(nonSelected);
	}, [selectedUsers, dropdownItems]);

	useEffect(() => {
		apiClient.get(endpoints.getUsers,
			{
				shadows: true,
			}).then((res) => {
				setDropdownItems(res.data);
			});
	}, []);

	const onDropdownClick = (e) => {
		setDropdownAnchorEl(e.currentTarget);
	};

	const renderDropdownContent = () => {
		let nonNullSelected = selectedUsers.filter((item) => item !== null && item !== undefined);

		const value = nonNullSelected?.length > 0 ? nonNullSelected.map((item) => item.fields['Email']).join(', ') : null;

		if (value) {
			return (
				<Stack alignItems={'center'} direction={'row'} spacing={'2px'}>
					{value}
					<KeyboardArrowDownOutlined sx={{ fontSize: 16 }} />
				</Stack>
			);
		} else {
			return (
				<Stack direction={'row'} spacing={'4px'}>
					<AddOutlined sx={{ fontSize: 20 }} color={Boolean(error) ? 'error' : 'primary'} />
					{Boolean(error) && (
						<Box>
							<FormHelperText error={Boolean(error)}>{error}</FormHelperText>
						</Box>
					)}
				</Stack>
			);
		}
	};

	const onClose = () => {
		setDropdownAnchorEl(null);
	};

	const searchObjects = useDebouncedCallback((searchText) => {
		apiClient
			.post(endpoints.getUsers, {
				userName: searchText,
				shadows: true,
			})
			.then((res) => {
				setDropdownItems(res.data);
			});
	}, 1000);

	return (
		<>
			<DropdownLink onClick={(e) => onDropdownClick(e)}>{renderDropdownContent()}</DropdownLink>
			<Menu
				anchorEl={dropdownAnchorEl}
				MenuListProps={{
					dense: isMultiple,
				}}
				open={Boolean(dropdownAnchorEl)}
				onClose={onClose}
			>
				<Stack spacing={1} sx={{ width: 332 }}>
					<ListItem>
						<TextField
							fullWidth
							variant={'outlined'}
							onKeyDown={(e) => e.stopPropagation()}
							onChange={(event) => searchObjects(event.target.value)}
							label={'Name or email'}
							size={'small'}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Search color="disabled" fontSize="small" />
									</InputAdornment>
								),
							}}
						/>
					</ListItem>
					<Divider variant={'fullWidth'} />
					<Stack>
						{dropdownMenuItems.slice(0, showedNumber).map((item) => {
							const selected = Boolean(value?.find((val) => val === item.id));
							return (
								<MenuItem
									key={item.id}
									onClick={() => {
										if (isMultiple) {
											const oldValue = value ?? [];
											const newValue = oldValue.find((val) => val === item.id)
												? oldValue.filter((val) => val !== item.id)
												: [...oldValue, item.id];
											setFieldValue(fieldName, newValue);
										} else {
											setFieldValue(fieldName, [item.id]);
											onClose();
										}
									}}
									selected={selected}
								>
									<Stack spacing={'12px'} direction={'row'} alignItems={'center'} sx={{ width: '100%' }}>
										{isMultiple && (
											<Checkbox checked={selected} size={'small'} sx={{ marginLeft: '-12px', marginRight: '2px' }} />
										)}
										<Avatar
											alt={`${getCorrectStringFieldFromObject(item, 'First Name')} ${getCorrectStringFieldFromObject(
												item,
												'Last Name'
											)}`}
											src={getUserAvatarURL(item.fields['Email'])}
											sx={{ width: 28, height: 28, fontSize: 14 }}
										>
											{`${getCorrectStringFieldFromObject(item, 'First Name', false)}${getCorrectStringFieldFromObject(
												item,
												'Last Name',
												false
											)}`}
										</Avatar>
										<Stack spacing={0} sx={{ overflow: 'hidden' }}>
											<Typography variant={'body2'} sx={{ wordBreak: 'break-word' }}>
												{`${getCorrectStringFieldFromObject(item, 'First Name')} ${getCorrectStringFieldFromObject(
													item,
													'Last Name'
												)}`}
											</Typography>
											<ValueTypography color={'text.secondary'} lineHeight={'unset'} noWrap>
												{item.fields['Email']}
											</ValueTypography>
										</Stack>
									</Stack>
								</MenuItem>
							);
						})}
					</Stack>
					{dropdownItems.length > showedNumber && (
						<ListItem>
							<Typography variant={'caption'} color={'text.secondary'}>
								To get more users, start searching by name or email
							</Typography>
						</ListItem>
					)}
				</Stack>
			</Menu>
		</>
	);
};

export default CustomizedUsersDropdown;
